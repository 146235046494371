import React, { Component, useContext } from 'react';
import {SlideDown} from 'react-slidedown'
import { UserContext } from "../providers/UserProvider";
import firebase from 'firebase';
import 'react-slidedown/lib/slidedown.css'
import useGlobal from "../store";

const UseConfig = (props) => {
  const user = useContext(UserContext);

  const [userPrefs, setUserPrefs] = useGlobal(
    state => state.preferences,
    actions => actions.addToPreferences
  );

  let userPrefRef = firebase.database().ref('/users/' + user.uid + '/userPrefs');
  userPrefRef.once('value').then(function(snapshot) {
    if(snapshot.val() && userPrefs) {
      setUserPrefs(snapshot.val());
      userPrefRef.off('value');
    }
  });

  return('');
}

const UserSettingsPanel = (props) => {

  const [preferenceMapping] = useGlobal(
    state => state.preferenceMapping
  );
  const user = useContext(UserContext);

  const [userPrefs, setUserPrefs] = useGlobal(
    state => state.preferences,
    actions => actions.addToPreferences
  );

  function dataService(newData) {
    let coreRef = firebase.database().ref('/users/' + user.uid + '/userPrefs');
    let updates = JSON.parse( JSON.stringify(newData ) );
    coreRef.update(updates);
    coreRef.off();
  }

  function counter() {
    let count = 0;
    for (var key in userPrefs)
    {
      if (userPrefs[key] === true) {
        count ++;
      }
    }
    return count;
  }


  function updateStyles(counter) {
    for (var key in userPrefs)
    {
      if (userPrefs[key] === true && document.getElementById(`b_${key}`)) {
        document.getElementById(`b_${key}`).classList.remove(...['bg-white','text-gray-800','inactive']);
        document.getElementById(`b_${key}`).classList.add(...['bg-pgGreen-500','text-pgNavy-500']);
      } else if(userPrefs[key] === false && document.getElementById(`b_${key}`)) {
        document.getElementById(`b_${key}`).classList.add(...['bg-white','text-gray-800','inactive']);
        document.getElementById(`b_${key}`).classList.remove(...['bg-pgGreen-500','text-pgNavy-500']);
        if(counter >5) {
          document.getElementById(`b_${key}`).classList.add(...['opacity-50']);
        } else {
          document.getElementById(`b_${key}`).classList.remove(...['opacity-50']);
        }
      }
    }
  }
  
  function setUserPref(key, value) {

    let count = counter();

    // check if we are adding or removing an item
    if(value) {
      if(count > 5) {
        return false;
      }
      // if we are removing go straight ahead
      // update DB
      let newPrefs = userPrefs;
      newPrefs[key] = value;
      setUserPrefs({...userPrefs, ...newPrefs});
      dataService(userPrefs);
      updateStyles(count+1);
      return false;
    }
    else {
      // if we are removing go straight ahead
      // update DB
      let newPrefs = userPrefs;
      newPrefs[key] = value;
      setUserPrefs({...userPrefs, ...newPrefs});
      dataService(userPrefs);
      updateStyles(count-1);
    }

    console.log(userPrefs, count);

  }

  /* 
  --- TEMPLATE ---------------------------
  Option Button 
  */
    function OptionButton(item) {

      if(!item.option) {
        return false;
      }

      return (

        <label key={`${item.key}`} className="flex w-1/2 md:w-1/3 lg:w-1/4 m-0 cursor-pointer" onClick={() => setUserPref(item.key,!userPrefs[item.key])}>
          
          <span id={`b_${item.key}`} className={`mb-2 pl-4 w-11/12 bg-gray-200 text-pgNavy-500 text-xs font-bold py-1 px-2 rounded rounded-full block text-left ${userPrefs[item.key] ? 'bg-pgGreen-500 text-pgNavy-500' : 'bg-white text-gray-800'}`}>
            <span>{item.label}</span>
          </span>

        </label>

      );
    }
  /* 
  END Option Button 
  --- TEMPLATE ---------------------------
  */

  return (
    <div className={`settings-panel bg-pgNavy-500 text-white`}>
      <div className="container m-auto p-6 md:p-10">

        <h2 className="mb-2 text-xl">Please select 6 KPIs</h2>

        <div className="flex flex-wrap">

          {preferenceMapping.map((child, index) => (
             OptionButton(child)
          ))}

        </div>

      </div>
    </div>
  )
}

class UserSettings extends Component {

  render() {

    return(
      <div>
        <UseConfig />
        <SlideDown>
          { this.props.show ? <UserSettingsPanel props="this.state"  /> : '' }
        </SlideDown>
      </div>
    )
  }
}

export default UserSettings; // Don’t forget to use export default!