import React, { useState } from "react";
import { auth } from "../firebase";
import { Link } from 'react-router-dom';
import SvgHand from '../src/hand.svg';

const PasswordReset = () => {

  //////////////////////////////////////////
  // Variables
  //////////////////////////////////////////
  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);


  //////////////////////////////////////////
  // On Change Handler
  //////////////////////////////////////////
  const onChangeHandler = event => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    }
  };


  //////////////////////////////////////////
  // Method to reset email address
  //////////////////////////////////////////
  const sendResetEmail = event => {
    event.preventDefault();
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
          setEmailHasBeenSent(true);
        setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
      })
      .catch(() => {
        setError("Error resetting password");
      });
  };

  
  return (
    <div className="w-full md:w-2/3 lg:w-1/2 xl:w-5/12 p-10 lg:p-20">

      <h1 className="text-4xl font-bold mb-2 text-white">Reset your password</h1>

      <div>
        <form action="">
          {emailHasBeenSent && (
            <div className="py-3 bg-green-400 w-full text-white text-center mb-3">
              An email has been sent to you!
            </div>
          )}
          {error !== null && (
            <div className="py-3 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}

          <input
            type="email"
            name="userEmail"
            id="userEmail"
            value={email}
            placeholder="Email Address"
            onChange={onChangeHandler}
            className="text-pgBlue-500 my-1 mb-4 p-3 pl-5 pr-5 w-full text-lg"
          />

          <button
            className="bg-pgGreen-500 hover:text-white hover:bg-pgNavy-500 rounded rounded-full pl-5 pr-5 pt-2 md:text-lg pb-2 text-pgNavy-500"
            onClick={event => {
              sendResetEmail(event);
            }}
          >
            Send me a reset link
          </button>
        </form>

        <Link
          to="/"
          className="my-2 text-white hover:underline block mt-8"
        >
          &larr; back to sign in page
        </Link>
      </div>

      <img src={SvgHand} className="hidden md:block fixed bottom-0 right-0 max-w-screen-lg max-h-2/3 w-1/2" alt="Keys" />
    </div>
  );
};

export default PasswordReset;
