import React, {useState, useContext} from "react";
import { UserContext } from "../providers/UserProvider";
import { useHistory } from 'react-router-dom';
import firebase from 'firebase';
import useGlobal from "../store";
import ReactTooltip from 'react-tooltip';
import moduleUpload from "./modules/module-upload";
import moduleFormatCurrency from "./modules/module-format-currency";
import moduleFormatPercentage from "./modules/module-format-percentage";
import moduleClenseData from "./modules/module-clense-data";
import cheerio from "cheerio";
import 'react-slidedown/lib/slidedown.css'
import Loader from '../src/loading.svg';

import modulePostcodePriceLookup from "./modules/module-postcode-price-lookup";

const FlipCreateAppraisal = () => {

  //////////////////////////////////////////
  // Variables
  //////////////////////////////////////////
  const history                                         = useHistory();
  const user                                            = useContext(UserContext);
  const [toolTips]                                      = useGlobal( state => state.toolTips );
  const [scaffold] = useGlobal(
    state => state.appraisalFlipScaffold
  );
  let [appraisalData, setAppraisalData]                 = useState(scaffold);
  let [thumbnail, setThumbnail]                         = useState('');
  let [formErrors, setFormErrors]                       = useState({});
  let [loading, setLoading]                             = useState(false);
  let errors                                            = {};

  //////////////////////////////////////////
  // On URL Handler
  //////////////////////////////////////////
  const onURLHandler = () => {

    setLoading(true);
    // INPUT  into CORS url
    const updates = {};

    fetch("https://pacific-lake-82221.herokuapp.com/" + appraisalData.url)
    .then(response => response.text())
    .then(data => {
      const $ = cheerio.load(data);
      setFormErrors({});
      errors = {};

      if(appraisalData.url.indexOf('rightmove') !== -1) {
        let pagemodel = $("script:contains(window.PAGE_MODEL)").html().replace('window.PAGE_MODEL = ','').toString().trim();
        let jsonmodel = JSON.parse(pagemodel);

        if(jsonmodel) {
          updates['thumbnail'] = jsonmodel.propertyData.images[0].url;
          updates['name'] = jsonmodel.propertyData.address.displayAddress;
          updates['address'] = jsonmodel.propertyData.address.displayAddress;
          updates['price'] = jsonmodel.propertyData.prices.primaryPrice;
          updates['askingPrice'] = jsonmodel.propertyData.prices.primaryPrice;
          updates['refurb'] = moduleFormatCurrency(moduleClenseData(jsonmodel.propertyData.prices.primaryPrice) * 0.15);
          updates['postcode'] = jsonmodel.propertyData.address.outcode + ' ' + jsonmodel.propertyData.address.incode;

          setAppraisalData({...appraisalData, ...updates});

          setLoading(false);
          moduleUpload(user, jsonmodel.propertyData.images[0].url, setThumbnail);
        }

      } else if(appraisalData.url.indexOf('zoopla') !== -1) {
        updates['thumbnail'] = $('[data-testid="gallery-image-slide-wrapper"]').find('img').attr('src');
        updates['name'] = $('#listing-summary-details-heading [data-testid="address-label"]').text();
        updates['address'] = $('#listing-summary-details-heading [data-testid="address-label"]').text();
        updates['price'] = $('#main-content [data-testid="price"]').text();
        updates['askingPrice'] = $('#main-content [data-testid="price"]').text();
        updates['refurb'] = moduleFormatCurrency(moduleClenseData($('#main-content [data-testid="price"]').text()) * 0.15);
        updates['postcode'] = $('#main-content a[href^="/house-prices/"]').attr('href').split("/")[2];


        setAppraisalData({...appraisalData, ...updates});

        setLoading(false);
        moduleUpload(user, $('[data-testid="gallery-image-slide-wrapper"]').find('img').attr('src'), setThumbnail);
      } else {
          setLoading(false);
      }

    })
    .catch(error => {
      setLoading(false);
    });

  };


  //////////////////////////////////////////
  // On Change Handler
  //////////////////////////////////////////
  const onChangeHandler = (event) => {

    const {name, value} = event.currentTarget;
    const updates = {};

    appraisalData[name] = value;
    
    if(name === 'postcode') {
      modulePostcodePriceLookup(appraisalData, setAppraisalData);
    } 
    if(name === 'address') {
      updates['name'] = value;
    }

    updates[name] = value;

    setAppraisalData({...appraisalData, ...updates});

  };


  //////////////////////////////////////////
  // On Blur Handler
  // Used to re-format the input to human friendly
  //////////////////////////////////////////
  const onBlurHandler = (event, type) => {
    const {name, value} = event.currentTarget;

    if(type === 'currency') {
      const cleanValue = moduleClenseData(value);
      appraisalData[name] = moduleFormatCurrency(cleanValue);
    }
    if(type === 'percentage') {
      const cleanValue = moduleClenseData(value);
      appraisalData[name] = moduleFormatPercentage(cleanValue);
    }

    setAppraisalData({...appraisalData, ...appraisalData});
  };


  //////////////////////////////////////////
  // Redirect Method (used on Save)
  //////////////////////////////////////////
  const redirect = (key) => history.push('/flips/appraisal/' + key);
  const triggerUpgrade = () => history.push('/limit-reached');


  //////////////////////////////////////////
  // Create New Appraisal Method (Saves appraisal into Db)
  //////////////////////////////////////////
  const createNewAppraisal = (event) => {

    modulePostcodePriceLookup(appraisalData, setAppraisalData);

    appraisalData.thumbnail = thumbnail;

    if(appraisalData.address) {
      appraisalData.name = appraisalData.address;
    }

    if(!appraisalData.address || appraisalData.address === '') {
      errors = {...errors, ...{ 'error' : 1, 'address' : 1 }}
    }
    if(!appraisalData.askingPrice || appraisalData.askingPrice === '' || moduleClenseData(appraisalData.askingPrice) === 0) {
      errors = {...errors, ...{ 'error' : 1, 'askingPrice' : 1 }}
    }
    if(!appraisalData.price || appraisalData.price === '' || moduleClenseData(appraisalData.price) === 0) {
      errors = {...errors, ...{ 'error' : 1, 'price' : 1 }}
    }
    if(!appraisalData.salePrice || appraisalData.salePrice === '' || moduleClenseData(appraisalData.salePrice) === 0) {
      errors = {...errors, ...{ 'error' : 1, 'salePrice' : 1 }}
    }

    if(errors.error) {
      setFormErrors(errors);
      return false;
    }

    // BLOCK ACTION REQUIRED ON LIMIT MET
    if(!user.limit || (user.limit && user.credits >= 1)) {
      user.useCredit(user.credits)
      user.incrementAppraisalCount();
      const appraisals = firebase.database().ref('appraisals/' + user.uid + '/flips');
      appraisals.push(appraisalData).then((snapshot) => {
        const key = snapshot.key 
        redirect(key);
      })
    } else {
      triggerUpgrade();
    }
    
  };

   
  return (

  <div className="bg-white leading-normal mt-3 tracking-normal pb-20">

    <ReactTooltip effect="solid" clickable={true} backgroundColor="#e7ff80" textColor="#00325b" multiline={true} />
    
    <div className="container w-full mx-auto">

      {formErrors.error > 0 && 
        <div className="bg-pgRed-500 text-white p-5 block text-center">Oops! Please enter the required information</div>
      }

     
      <div className = "py-4 lg:py-8 px-4 lg:px-10 border border-1 rounded mx-5 my-10">

        {!loading &&
          <>
          <p>
              <label htmlFor="url" className="block text-sm mb-2">
               Rightmove / Zoopla URL:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.websiteUrl}></em>
              </label>
              <input
              type="url"
              className="shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="url"
              placeholder="https://www.rightmove.co.uk/property-for-sale/property-93902066.html"
              id="url"
              value = {appraisalData.url}
              onChange = {(event) => onChangeHandler(event)}
              />
          </p>

          <div className="flex">
            <span className="mt-5 text-sm mr-5 px-10 block cursor-pointer bg-gradient-to-r text-center from-pgAqua-500 to-pgGreen-500 rounded rounded-full p-1 text-pgBlue-500 max-w-sm" onClick = {(event) => {onURLHandler()}}>Load Property</span>
          </div>
          </>
        }

        {loading &&

        <img src={Loader} className="block w-1/5 m-auto" alt="Arrows" />

        }

      </div>

      
      <div className = "py-4 lg:py-8 px-4 lg:px-10 border border-1 rounded mx-5 mb-10">

        <p className="mt-5">
          <label htmlFor="address" className={`block text-sm mb-2 ${formErrors.address?'text-pgRed-500':''}`}>
            Address *:
          </label>
          <input
            type="text"
            className={`shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.address?'border-pgRed-500':''}`}
            name="address"
            placeholder="10 Smith Street, Norwich, NR7 9HT"
            id="address"
            value = {appraisalData.address}
            onChange = {(event) => onChangeHandler(event)}
            />
        </p>

        <p className="mt-5">
          <label htmlFor="askingPrice" className={`block text-sm mb-2 ${formErrors.askingPrice?'text-pgRed-500':''}`}>
            Asking Price *:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.askingPrice}></em>
          </label>
          <input
            type="text"
            className={`shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.askingPrice?'border-pgRed-500':''}`}
            name="askingPrice"
            placeholder="£100000"
            id="askingPrice"
            value = {appraisalData.askingPrice}
            onChange = {(event) => onChangeHandler(event)}
            onBlur= {(event) => onBlurHandler(event,'currency')}
            />
        </p>

        <p className="mt-5">
          <label htmlFor="price" className={`block text-sm mb-2 ${formErrors.price?'text-pgRed-500':''}`}>
            Purchase Price *:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.price}></em>
          </label>
          <input
            type="text"
            className={`shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${formErrors.price?'border-pgRed-500':''}`}
            name="price"
            placeholder="£100000"
            id="price"
            value = {appraisalData.price}
            onChange = {(event) => onChangeHandler(event)}
            onBlur= {(event) => onBlurHandler(event,'currency')}
            />
        </p>

        <p className="mt-5">
          <label htmlFor="refurb" className="block text-sm mb-2">
            Refurb Estimation:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refurb}></em>
          </label>
          <input
            type="text"
            className="shadow appearance-none border text-sm rounded hover:border-pgRed-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="refurb"
            placeholder="£20000"
            id="refurb"
            value = {appraisalData.refurb}
            onChange = {(event) => onChangeHandler(event)}
            onBlur= {(event) => onBlurHandler(event,'currency')}
            />
        </p>

        <p className="mt-5">
          <label htmlFor="salePrice" className={`block text-sm mb-2 ${formErrors.salePrice?'text-pgRed-500':''}`}>
            Resale Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.salePrice}></em>
          </label>
          <input
            type="text"
            className="shadow appearance-none border text-sm rounded hover:border-pgRed-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="salePrice"
            placeholder="£150000"
            id="salePrice"
            value = {appraisalData.salePrice}
            onChange = {(event) => onChangeHandler(event)}
            onBlur= {(event) => onBlurHandler(event,'currency')}
            />
        </p>

        <p className="mt-5">
          <label htmlFor="postcode" className="block text-sm mb-2">
            Postcode:
          </label>
          <input
            type="text"
            className="shadow appearance-none border text-sm rounded hover:border-pgRed-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="postcode"
            placeholder=""
            id="postcode"
            value = {appraisalData.postcode}
            onChange = {(event) => onChangeHandler(event)}
            />
        </p>
        
        <p className="mt-5">
            <button className="mt-5 mr-5 px-10 block cursor-pointer bg-gradient-to-r text-center from-pgAqua-500 to-pgGreen-500 rounded rounded-full p-1 text-pgBlue-500 max-w-sm" onClick = {(event) => {createNewAppraisal()}}>
              Save and Continue
            </button>
        </p>
      </div>

     

      </div>
    </div>
  ) 
};

export default FlipCreateAppraisal;

