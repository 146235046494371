import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import ModuleBuyCreditsButtonContent from '../modules/module-buy-credits-button-content';

class TemplateBuyCreditsButton extends Component {
    render() {
        const {
            stripePublicKey,
            apiName,
            buttonText,
            apiEndpoint,
            className,

            price,
            successUrl,
            cancelUrl,
            customerId,
        } = this.props;
        return (
            <StripeProvider apiKey={stripePublicKey}>
                <Elements>
                    <ModuleBuyCreditsButtonContent
                        apiName={apiName}
                        apiEndpoint={apiEndpoint}
                        price={price}
                        successUrl={successUrl}
                        cancelUrl={cancelUrl}
                        buttonText={buttonText}
                        className={className}
                        customerId={customerId}
                        stripePublicKey={stripePublicKey}
                    />
                </Elements>
            </StripeProvider>
        );
    }
}

TemplateBuyCreditsButton.propTypes = {
    stripePublicKey: PropTypes.string.isRequired,
    apiName: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    apiEndpoint: PropTypes.string.isRequired,

    price: PropTypes.string.isRequired,
    successUrl: PropTypes.string.isRequired,
    cancelUrl: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
};

export default TemplateBuyCreditsButton;