/* 
FSC FOR modulePostcodeHMORentLookup
Method used to perform a lookup on the given postcode to return an price per sqft value
*/

import moduleClenseData from "../modules/module-clense-data";
import lookups from '../../src/lookup-data.js';

const modulePostcodeHMORentLookup = (appraisalData, setAppraisalData) => {

    const postcodeDoubleLookup = lookups[7];
  	const postcodeEnSuiteLookup = lookups[8];
    let avg = 450;

    appraisalData.postcode = appraisalData.postcode.replace(' ','').toUpperCase();

    let outcodeTests = [appraisalData.postcode.substring(0,2), appraisalData.postcode.substring(0,3), appraisalData.postcode.substring(0,4)];

    outcodeTests.forEach(function(element) {
      if(postcodeDoubleLookup[element] && postcodeEnSuiteLookup[element]) {
        avg = (postcodeEnSuiteLookup[element] + postcodeDoubleLookup[element]) / 2;
      } else if (postcodeDoubleLookup[element]) {
        avg = postcodeDoubleLookup[element];
      } else if (postcodeEnSuiteLookup[element]) {
        avg = postcodeEnSuiteLookup[element];
      }

      // set default value if we don't have any data
      const updates = {};
      appraisalData.avgRoomRent = '£' + avg;
      appraisalData.grossRent = '£' + moduleClenseData(450 * moduleClenseData(appraisalData.rooms));
      updates['avgRoomRent'] = '£' + avg;
      updates['grossRent'] = '£' + moduleClenseData(450 * moduleClenseData(appraisalData.rooms));

      setAppraisalData({...appraisalData, ...updates});
      return;

    });

 };

 export default modulePostcodeHMORentLookup;