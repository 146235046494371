import React, { useState, useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import useGlobal from "../store";
import ReactTooltip from 'react-tooltip';
import { useToasts } from 'react-toast-notifications';

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import moduleScorecardCalculation from "./modules/module-scorecard-calculation";
import modulePostcodePriceLookup from "./modules/module-postcode-price-lookup";
import moduleFormatCurrency from "./modules/module-format-currency";
import moduleFormatPercentage from "./modules/module-format-percentage";
import moduleFormatBool from "./modules/module-format-bool";
import moduleClenseData from "./modules/module-clense-data";
import moduleFormatFriendlyData from "./modules/module-format-friendly-data";
import moduleCalculateTotal from "./modules/module-calculate-total";
import moduleCalculateSDLT from "./modules/module-stamp-duty";
import moduleKeyPress from "./modules/module-key-press";
import moduleShare from "./modules/module-share";

import TemplateAppraisalScoreCard from "./templates/template-appraisal-scorecard";
import TemplateAppraisalKPIBlock from "./templates/template-appraisal-kpi-block";
import TemplateAppraisalThumbnailBlock from "./templates/template-appraisal-thumbnail-block";
import TemplateAppraisalSuggestedFlipOffer from "./templates/template-appraisal-suggested-flip-offer";
import TemplateUpload from "./templates/template-upload";
import TemplateExportPDFFlips from "./templates/template-export-pdf-flips";

import settingsCurrencyFields from "./settings-currency-fields";
import settingsPercentageFields from "./settings-percentage-fields";

const FlipAppraisal = props => {


  //////////////////////////////////////////
  // Effects
  //////////////////////////////////////////
  React.useEffect(
    () => {
      if(document.getElementsByClassName('js-top-anchor').length) {
        window.scrollTo(0, document.getElementsByClassName('js-top-anchor')[0].offsetTop);
      }
    },
    []
  );

  //////////////////////////////////////////
  // Variables
  //////////////////////////////////////////
  const user = useContext(UserContext);
  const [uploads, setUploads]                           = useGlobal( state => state.uploads, actions => actions.addToUploads );
  const [preferenceMapping]                             = useGlobal( state => state.preferenceMapping );   
  const [toolTips]                                      = useGlobal( state => state.toolTips );
  const [flipKPIs]                                      = useState( { price: true, refurb: true, saleUplift: true, salePrice:  true, profit: true, ROI: true } );
  const [scaffold]                                      = useGlobal( state => state.appraisalFlipScaffold );
  let [appraisalData, setAppraisalData]                 = useState(scaffold);
  const windowWidth                                     = window.innerWidth;
  const mobile                                          = (windowWidth <= 768);

  let [total, setTotal]                                 = useState(false);
  let [showSuggestedOffer, setShowSuggestedOffer]       = useState(false);
  let [calcInitialLending, showCalcInitialLending]      = useState(false);
  let [shareLink, setShareLink]                         = useState(false);

  let [showInitialCostBlock, setShowInitialCostBlock]   = useState(!mobile);
  let [showReturnsBlock, setShowReturnsBlock]           = useState(!mobile);
  let [showDataBlock, setShowDataBlock]                 = useState(!mobile);
  let [showStatusBlock, setShowStatusBlock]             = useState(!mobile);

  const { addToast }                                    = useToasts();

  //////////////////////////////////////////
  // Effects
  //////////////////////////////////////////
  React.useEffect(
    () => {
      setup();
    },
    []
  );

  const setToggleSuggestedOffer = () => setShowSuggestedOffer(showSuggestedOffer => !showSuggestedOffer);

  let coreAppraisalUpdates = {};

  //////////////////////////////////////////
  // Initial Setup
  //////////////////////////////////////////
  function setup() {
    let coreRef = firebase.database().ref('/appraisals/' + user.uid + '/flips/' + props.appraisalId);
    coreRef.once('value').then(function(snapshot) {

      const processed = moduleFormatFriendlyData(snapshot.val());
      appraisalData = moduleFormatFriendlyData(appraisalData);
      appraisalData = Object.assign(appraisalData, processed);
      setAppraisalData(appraisalData);
      setTotal(moduleCalculateTotal(appraisalData));
      calculateInitialGearing();
      calculateDefaultSalePrice();
      calculateIncomeStats();
      calculateResale();
      calculatePropertyMetrics();
      calculateScorecard();
      dataService(coreAppraisalUpdates, true);

      coreRef.off('value');
      let el = document.querySelector( 'input' );
      if( el ) el.blur();
    });
  }


  //////////////////////////////////////////
  // Toggle Archive State of this appraisal item
  //////////////////////////////////////////
  function archive(remove) {
    let value = '1';
    if(!remove) {
      value = '0';
    }
    const updates = {
      '/archive/' : value
    };
    appraisalData.archive = value;
    coreAppraisalUpdates = {...updates, ...coreAppraisalUpdates};
    dataService(coreAppraisalUpdates, true);
  }


  //////////////////////////////////////////
  // Update the Image Path
  //////////////////////////////////////////
  function updateImage() {
    const url = document.getElementById('thumbnail').value;
    if(url) {
      const updates = {
        '/thumbnail/' : url
      };
      appraisalData.thumbnail = url;
      coreAppraisalUpdates = {...updates, ...coreAppraisalUpdates};
      dataService(coreAppraisalUpdates, true);
      if(window.innerWidth < 1024) {
        window.scrollTo(500, document.getElementsByClassName('js-top-anchor')[0].offsetTop);
      }
      setUploads({location:''});
      addToast('Image Saved', { appearance: 'success' });
    }
  }

  //////////////////////////////////////////
  // used to create a share instance
  //////////////////////////////////////////
  function share() {
    const key = moduleShare('flips', appraisalData, setShareLink);
  }

  //////////////////////////////////////////
  // Core Data Service Setup
  //////////////////////////////////////////
  function dataService(newData, saveToDB) {
    let updates = {};
    let appraisalUpdates = {};
    for (let key in newData) {
        if (newData.hasOwnProperty(key)) {
            let dataValue = newData[key];
            if(settingsCurrencyFields.includes(key) || settingsPercentageFields.includes(key)) {
              dataValue = moduleClenseData(newData[key]);
            }
            updates[key] = dataValue;
            let appraisalKey = key.replace(/\//g, '');
            appraisalUpdates[appraisalKey] = dataValue;
        }
    }
    setAppraisalData({...appraisalUpdates,...appraisalData});
    if(saveToDB) {
      updates = JSON.parse( JSON.stringify(updates ) );
      let coreRef = firebase.database().ref('/appraisals/' + user.uid + '/flips/' + props.appraisalId);
      coreRef.update(updates);
      coreRef.off();
      coreAppraisalUpdates = {};
    }
  }


  //////////////////////////////////////////
  // Calulcate Property Metrics
  // Sqft, Sqm, Price per Sqft etc...
  //////////////////////////////////////////
  function calculatePropertyMetrics(name) {

    if(name === 'sqm') {
      appraisalData.sqft = Number(moduleClenseData(appraisalData.sqm) * 10.7639).toFixed(1);
    } else {
      appraisalData.sqm = Number(moduleClenseData(appraisalData.sqft) * 0.09290304).toFixed(1);
    }

    appraisalData.areaPrice = moduleClenseData(appraisalData.sqft) * moduleClenseData(appraisalData.avgPricePerSqft);
    appraisalData.pricePerSqft = moduleClenseData(appraisalData.price) / moduleClenseData(appraisalData.sqft);
    appraisalData.sqftDifference = moduleClenseData(appraisalData.avgPricePerSqft) - moduleClenseData(appraisalData.pricePerSqft);
    appraisalData.priceDifference = moduleClenseData(appraisalData.areaPrice) - moduleClenseData(appraisalData.price);
    
    // if locked, when price changes unlock and reset to standard
    if(name === 'price') {
      appraisalData.stampDutyLock = false;
    }
    if(name !== 'stampDuty' && !appraisalData.stampDutyLock) {
      appraisalData.stampDuty = moduleCalculateSDLT(appraisalData.price);
      appraisalData.stampDutyLock = true;
    }

    const updates = {
      '/sqft/' : appraisalData.sqft,
      '/sqm/' : appraisalData.sqm,
      '/pricePerSqft/' : appraisalData.pricePerSqft,
      '/areaPrice/' : appraisalData.areaPrice,
      '/sqftDifference/' : appraisalData.sqftDifference,
      '/priceDifference/' : appraisalData.priceDifference,
      '/stampDuty/' : appraisalData.stampDuty,
      '/stampDutyLock/' : appraisalData.stampDutyLock
    };

    coreAppraisalUpdates = {...updates, ...coreAppraisalUpdates};

  }


  //////////////////////////////////////////
  // Calculate Income Stats
  //////////////////////////////////////////
  function calculateIncomeStats() {

    // Not required

  }

  //////////////////////////////////////////
  // Calculate Initial Gearing
  //////////////////////////////////////////
  function calculateInitialGearing() {
    appraisalData.initialLoanValue = moduleClenseData(appraisalData.price) / 100 * parseInt(appraisalData.initialLTV);
    appraisalData.initialDeposit = moduleClenseData(appraisalData.price) - appraisalData.initialLoanValue;

    if('annually' === appraisalData.initialInterestPeriod) {
      appraisalData.initialAnnualInterest = (moduleClenseData(appraisalData.initialLoanValue) / 100 * moduleClenseData(appraisalData.initialMortgageRate) / 12) * moduleClenseData(appraisalData.initialTerm);
    } else {
      appraisalData.initialAnnualInterest = (moduleClenseData(appraisalData.initialLoanValue) / 100 * moduleClenseData(appraisalData.initialMortgageRate)) * moduleClenseData(appraisalData.initialTerm);     
    }

    appraisalData.initialCapitalInvested = moduleClenseData(appraisalData.initialDeposit) + moduleClenseData(appraisalData.legalsAndSurveys) + moduleClenseData(appraisalData.stampDuty) + moduleClenseData(appraisalData.refurb) + moduleClenseData(appraisalData.otherFees) + moduleClenseData(appraisalData.initialAnnualInterest);
    
    const updates = {
      '/initialLoanValue/' : appraisalData.initialLoanValue,
      '/initialDeposit/' : appraisalData.initialDeposit,
      '/initialAnnualInterest/' : appraisalData.initialAnnualInterest,
      '/initialCapitalInvested/' : appraisalData.initialCapitalInvested
    };
    coreAppraisalUpdates = {...updates, ...coreAppraisalUpdates};
  }


  //////////////////////////////////////////
  // Calculate refinance Gearing
  //////////////////////////////////////////
  function calculateResale() {

    const saleUplift = moduleClenseData(appraisalData.salePrice) - moduleClenseData(appraisalData.price);
    const percentageSaleUplift = (100 * saleUplift) / moduleClenseData(appraisalData.price);
    const eaSellingCosts = moduleClenseData(appraisalData.salePrice) * (moduleClenseData(appraisalData.eaSellingFee)/100);
    const profit = 
      moduleClenseData(appraisalData.salePrice) 
      - moduleClenseData(appraisalData.price) 
      - moduleClenseData(eaSellingCosts) 
      - moduleClenseData(appraisalData.saleERC) 
      - moduleClenseData(appraisalData.initialAnnualInterest) 
      - moduleClenseData(appraisalData.otherFees) 
      - moduleClenseData(appraisalData.stampDuty)
      - moduleClenseData(appraisalData.legalsAndSurveys)
      - moduleClenseData(appraisalData.refurb);
    const ROI = moduleClenseData(profit) / moduleClenseData(appraisalData.initialCapitalInvested) * 100;

    const updates = {
      '/percentageSaleUplift/' : percentageSaleUplift,
      '/saleUplift/' : saleUplift,
      '/profit/' : profit,
      '/ROI/' : ROI,
      '/eaSellingCosts/' : eaSellingCosts
    };

    appraisalData.eaSellingCosts = moduleFormatCurrency(eaSellingCosts);
    appraisalData.profit = moduleFormatCurrency(profit);
    appraisalData.ROI = moduleFormatPercentage(ROI);
    appraisalData.saleUplift = moduleFormatPercentage(saleUplift);
    appraisalData.percentageSaleUplift = moduleFormatPercentage(percentageSaleUplift);

    coreAppraisalUpdates = {...updates, ...coreAppraisalUpdates};
  }


  //////////////////////////////////////////
  // Calculate Sale Price if Blank
  //////////////////////////////////////////
  function calculateDefaultSalePrice() {

    if(!moduleClenseData(appraisalData.salePrice)) {
      appraisalData.salePrice = (moduleClenseData(appraisalData.price) * 1.2) + moduleClenseData(appraisalData.refurb);

      const updates = {
        '/salePrice/' : appraisalData.salePrice,
      };

      appraisalData.salePrice = moduleFormatCurrency(appraisalData.salePrice);

      coreAppraisalUpdates = {...updates, ...coreAppraisalUpdates};
    }
  }
  
  //////////////////////////////////////////
  // Calulcate Scorecard
  //////////////////////////////////////////
  function calculateScorecard() {

    let scorecard = moduleScorecardCalculation('flip', appraisalData, 'ROI', 'ROI', 'Uplift', 'percentageSaleUplift');

    appraisalData.overallScore = scorecard.overallScore;

    const updates = {
      '/overallScore/' : appraisalData.overallScore
    };

    coreAppraisalUpdates = {...updates, ...coreAppraisalUpdates};
  }


  //////////////////////////////////////////
  // On Change Handler
  //////////////////////////////////////////
  const onChangeHandler = (event) => {
    const {name, value, type, checked} = event.currentTarget;

    appraisalData[name] = value;

    if(name === 'postcode') {
      modulePostcodePriceLookup(appraisalData, setAppraisalData);
    }

    const updates = {};
    updates['/' + name + '/'] = value;
    if(type === 'checkbox') {
      updates['/' + name + '/'] = parseInt(checked * 1);
      appraisalData[name] = parseInt(checked * 1);
    }

    coreAppraisalUpdates = {...updates, ...coreAppraisalUpdates};

    // re-evaluate all data points based on new input
    setTotal(moduleCalculateTotal(appraisalData));
    calculatePropertyMetrics(name);
    calculateInitialGearing();
    calculateIncomeStats();

    // save to the DB once done.
    calculateResale();
    calculateScorecard();
    dataService(coreAppraisalUpdates, false);

  };

  //////////////////////////////////////////
  // Change Status Handler
  //////////////////////////////////////////
  const changeStatus = (value) => {

    appraisalData.offerStatus = value;

    const updates = {};
    updates['/offerStatus/'] = value;
    coreAppraisalUpdates = {...updates, ...coreAppraisalUpdates};

    // save to the DB once done.
    dataService(coreAppraisalUpdates, true);

  };



  //////////////////////////////////////////
  // On Blur Handler
  // Used to re-format the input to human friendly
  //////////////////////////////////////////
  const onBlurHandler = (event, type) => {
    const {name, value} = event.currentTarget;

    if(type === 'currency') {
      const cleanValue = moduleClenseData(value);
      appraisalData[name] = moduleFormatCurrency(cleanValue);
    }
    if(type === 'percentage') {
      const cleanValue = moduleClenseData(value);
      appraisalData[name] = moduleFormatPercentage(cleanValue);
    }

    addToast('Changes Saved', { appearance: 'success' });
    setAppraisalData({...appraisalData, ...appraisalData});
    dataService(appraisalData, true);
  };


  return (
    
  <div className="leading-normal tracking-normal bg-pgGrey-500 js-top-anchor pb-10">

    <ReactTooltip effect="solid" clickable={true} backgroundColor="#e7ff80" textColor="#00325b" multiline={true} />
    
    <nav id="scorecard" className="bg-white w-full z-10">

        <div className="w-full container mx-auto flex items-center mt-0 p-5 justify-around">

          <TemplateAppraisalScoreCard type='flip' appraisalData={appraisalData} label1='ROI' dataPoint1='ROI' label2='Uplift' dataPoint2='percentageSaleUplift' />

        </div>

    </nav>

    <nav id="header" className="bg-white md:sticky top-0 w-full z-10 border-b border-grey-800 sticky-bar">

        <div className="w-full container mx-auto flex flex-wrap items-center mt-0 p-5 justify-around">

            {preferenceMapping.map((item, index) => (
                 TemplateAppraisalKPIBlock(appraisalData, item, false, flipKPIs)
            ))}

        </div>

    </nav>

    <div className={`bg-white md:mt-8 md:mb-8 md:mr-8 md:ml-8 p-5 border-pgGray-500 border rounded lg:p-10 archive-${appraisalData.archive}`}>

      <div className="flex flex-wrap pt-4">

        <div className="w-full md:w-100 xl:w-100 md:flex justify-between">

          <div className="block w-full md:w-3/12 md:pr-10 pb-5 md:pb-0 align-middle">

            <TemplateAppraisalThumbnailBlock appraisalData={appraisalData}></TemplateAppraisalThumbnailBlock>

            <p className="flex">
              <label className="items-center mb-1 block w-full cursor-pointer">
                <input className="appraisal-filter" type="checkbox" checked={moduleFormatBool(appraisalData.flagHot)} value={appraisalData.flagHot} name="flagHot" id="flagHot" onChange = {(event) => onChangeHandler(event)} />
                <span className="bg-gray-200 w-full hover:text-pgNavy-500 hover:bg-pgRed-300 text-xs text-pgNavy-500 font-bold py-1 px-2 rounded rounded-full block text-left">
                <em className="icon-fire mr-2"></em><span>Hot Property</span>
                </span>
              </label>
            </p>
            <p className="flex">
              <label className="items-center mb-1 block w-full cursor-pointer">
                <input className="appraisal-filter" type="checkbox" checked={moduleFormatBool(appraisalData.flagNoChain)} value={appraisalData.flagNoChain} name="flagNoChain" id="flagNoChain" onChange = {(event) => onChangeHandler(event)} />
                <span className="bg-gray-200 w-full hover:text-pgNavy-500 hover:bg-pgRed-300 text-xs text-pgNavy-500 font-bold py-1 px-2 rounded rounded-full block text-left">
                <em className="icon-link mr-2"></em><span>No Chain</span>
                </span>
              </label>
            </p>
            <p className="flex">
              <label className="items-center mb-1 block w-full cursor-pointer">
                <input className="appraisal-filter" type="checkbox" checked={moduleFormatBool(appraisalData.flagBMV)} value={appraisalData.flagBMV} name="flagBMV" id="flagBMV" onChange = {(event) => onChangeHandler(event)} />
                <span className="bg-gray-200 w-full hover:text-pgNavy-500 hover:bg-pgRed-300 text-xs text-pgNavy-500 font-bold py-1 px-2 rounded rounded-full block text-left">
                <em className="icon-down mr-2"></em><span>BMV</span>
                </span>
              </label>
            </p>

          </div>

          <div className="block w-full md:w-9/12 p-5 border rounded">

              <input  
                type="hidden"
                className="shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-4/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="reference"
                placeholder=""
                id="reference"
                value = {appraisalData.reference}
                onChange = {(event) => onChangeHandler(event)}
                />

            <label className="items-center block md:flex">
              <p className="text-sm md:w-1/3 pb-1 md:pb-0 block text-pgNavy-500">Property Name:</p>
              <input  
                type="text"
                className="shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-full w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="name"
                placeholder="12 Smith Street"
                id="name"
                value = {appraisalData.name}
                onChange = {(event) => onChangeHandler(event)}
                onBlur = {(event) => onBlurHandler(event,'')}
                onKeyPress = {(event) => moduleKeyPress(event)}
                />
            </label>

            <label className="items-center block pt-3 md:flex">
              <p className="text-sm md:w-1/3 pb-1 md:pb-0 block text-pgNavy-500">Property Address:</p>
              <input  
                type="text"
                className="shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-full w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="address"
                placeholder="10 Smith Street, Norwich, NR5 9NH"
                id="address"
                value = {appraisalData.address}
                onChange = {(event) => onChangeHandler(event)}
                onBlur = {(event) => onBlurHandler(event,'')}
                onKeyPress = {(event) => moduleKeyPress(event)}
                />
            </label>

            <label className="items-center block pt-3 md:flex">
              <p className="text-sm md:w-1/3 pb-1 md:pb-0 block text-pgNavy-500">Existing Property Type:</p>
              <input
              type="text"
              className="shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-full w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="type"
              placeholder="E.g. 3 Bedroom Semi-Detached"
              id="type"
              value = {appraisalData.type}
              onChange = {(event) => onChangeHandler(event)}
              onKeyPress = {(event) => moduleKeyPress(event)}
              onBlur = {(event) => onBlurHandler(event,'')}
              />
            </label>

            <label className="items-center block pt-3 md:flex">
              <p className="text-sm md:w-1/3 pb-1 md:pb-0 block text-pgNavy-500">URL:</p>
              <input
              type="text"
              className="shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-full w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="url"
              placeholder=""
              id="url"
              value = {appraisalData.url}
              onChange = {(event) => onChangeHandler(event)}
              onKeyPress = {(event) => moduleKeyPress(event)}
              onBlur = {(event) => onBlurHandler(event,'')}
              />
            </label>

            <label className="items-center pt-3 block md:flex">
              <p className="text-sm md:w-1/3 pb-1 md:pb-0 block text-pgNavy-500">Asking / Sale Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.askingPrice}></em></p>
              <input
              type="text"
              className="shadow appearance-none text-sm border rounded w-full w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
              name="askingPrice"
              placeholder="£220,000"
              id="askingPrice"
              value = {appraisalData.askingPrice}
              onChange = {(event) => onChangeHandler(event)}
              onBlur = {(event) => onBlurHandler(event,'currency')}
              onKeyPress = {(event) => moduleKeyPress(event)}
              />
            </label>

            <label className="items-center block pt-3 md:flex">
              <p className="text-sm md:w-1/3 pb-1 md:pb-0 block text-pgNavy-500">Notes:</p>
              <textarea
              className="shadow appearance-none text-sm border rounded hover:border-pgRed-500 w-full w-2/3 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="notes"
              placeholder=""
              id="notes"
              value = {appraisalData.notes}
              onChange = {(event) => onChangeHandler(event)}
              onKeyPress = {(event) => moduleKeyPress(event)}
              onBlur = {(event) => onBlurHandler(event,'')}
              />
            </label>

            <span className="items-center block pt-3 md:flex">
              <p className="text-sm md:w-1/3 block text-pgNavy-500">Image:</p>
              <div className="md:flex md:justify-between w-full md:pl-8">
                <TemplateUpload />
                <span className={`block mt-5 md:mt-2 w-full md:w-2/5 pl-4 ml-auto pr-4 text-center bg-pgRed-500 text-pgNavy-500 rounded rounded-full p-1 ` + (!uploads.location?'opacity-50 cursor-default':'cursor-pointer')}
                onClick = {(event) => updateImage()}>{uploads.loading ? 'Processing' : 'Upload'}</span>
              </div>
              <input
                type="hidden"
                className="shadow appearance-none text-sm border mr-2 rounded hover:border-pgRed-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="thumbnail"
                placeholder=""
                id="thumbnail"
                value = {uploads.location}
                />
            </span>


          </div>

        </div>
    </div>


    <>
    <h1
      className={(mobile ? 'cursor-pointer' : '') + " md:hidden mt-10 text-xl p-3 text-pgNavy-500 flex justify-between"}
      onClick={mobile ? (event) => setShowStatusBlock(!showStatusBlock) : undefined}
    >
      <span>Status</span>
      <em className={(showStatusBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
    </h1>

    <SlideDown transitionOnAppear={false}>
      { showStatusBlock && 
        <>
        <span className="relative pgTab block md:mt-10">
          <span className="z-1 hidden md:inline-block bg-pgGrey-500 border-l border-t border-r rounded-t py-2 px-4 text-pgNavy-500">Status</span>
        </span>
        <div className="flex flex-wrap">
          <div className={`w-full md:w-3/12 bg-pgGrey-500 border-r md:border-r-0 border-l border-t md:border-b p-5 pl-0 pr-10 status-links--${appraisalData.offerStatus}`}>
            <span onClick = {(event) => changeStatus('appraisal')} className="hover:bg-pgAqua-500 cursor-pointer text-pgNavy-500 p-2 pl-10 block status-link status-link--appraisal">Appraisal</span>
            <span onClick = {(event) => changeStatus('offered')} className="hover:bg-pgAqua-500 cursor-pointer text-pgNavy-500 p-2 pl-10 block status-link status-link--offered">Offering</span>
            <span onClick = {(event) => changeStatus('accepted')} className="hover:bg-pgAqua-500 cursor-pointer text-pgNavy-500 p-2 pl-10 block status-link status-link--accepted">Offer Accepted</span>
            <span onClick = {(event) => changeStatus('lost')} className="hover:bg-pgAqua-500 cursor-pointer text-pgNavy-500 p-2 pl-10 block status-link status-link--lost">Lost</span>
          </div>
          <div className={`w-full md:w-9/12 p-5 flex xl:space-x-4 border block-offer-status block-offer-status--${appraisalData.offerStatus}`}>

              <div className="items-center block text-pgNavy-500 flex block-offer-status__appraisal">
                <p>Take your time! its important to get the numbers right!</p>
                <p className="font-bold mt-4">Have you checked:</p>
                <ul>
                  <li className="icon-ok-circled mt-2">Property value based on size vs asking price?</li>
                  <li className="icon-ok-circled mt-2">Resale demand for this area?</li>
                  <li className="icon-ok-circled mt-2">Sold comparables</li>
                </ul>
              </div>

              <div className="items-center block flex block-offer-status__offers">
                <p className="text-2xl text-pgNavy-500 mb-2 block">Offers:</p>
                <div className="flex flex-row">
                  <div className="block pr-4">
                    <label className="text-pgNavy-500 block">Offer 1</label>
                    <input
                    type="text"
                    className="text-xl w-11/12 md:w-full shadow appearance-none text-sm border mb-2 md:mb-0 md:mr-2 rounded hover:border-pgRed-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="offer1"
                    placeholder=""
                    id="offer1"
                    value = {appraisalData.offer1}
                    onChange = {(event) => onChangeHandler(event)}
                    onKeyPress = {(event) => moduleKeyPress(event)}
                    onBlur = {(event) => onBlurHandler(event,'currency')}
                    />
                  </div>
                  <div className="block pr-4">
                    <label className="text-pgNavy-500 block">Offer 2</label>
                    <input
                    type="text"
                    className="text-xl w-11/12 md:w-full shadow appearance-none text-sm border mb-2 md:mb-0 rounded hover:border-pgRed-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="offer2"
                    placeholder=""
                    id="offer2"
                    value = {appraisalData.offer2}
                    onChange = {(event) => onChangeHandler(event)}
                    onKeyPress = {(event) => moduleKeyPress(event)}
                    onBlur = {(event) => onBlurHandler(event,'currency')}
                    />
                  </div>
                  <div className="block">
                    <label className="text-pgNavy-500 block">Offer 3</label>
                    <input
                    type="text"
                    className="text-xl w-11/12 md:w-full shadow appearance-none text-sm border mb-2 md:mb-0 rounded hover:border-pgRed-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="offer3"
                    placeholder=""
                    id="offer3"
                    value = {appraisalData.offer3}
                    onChange = {(event) => onChangeHandler(event)}
                    onKeyPress = {(event) => moduleKeyPress(event)}
                    onBlur = {(event) => onBlurHandler(event,'currency')}
                    />
                  </div>
                </div>
              </div>

              <label className="items-center block flex block-offer-status__accepted">
                <p className="text-2xl text-pgNavy-500 mb-2">Offer Accepted:</p>
                <input
                type="text"
                className="text-2xl w-11/12 md:w-full shadow appearance-none text-sm border mr-2 rounded hover:border-pgRed-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="offerAccepted"
                placeholder=""
                id="offerAccepted"
                value = {appraisalData.offerAccepted}
                onChange = {(event) => onChangeHandler(event)}
                onKeyPress = {(event) => moduleKeyPress(event)}
                onBlur = {(event) => onBlurHandler(event,'currency')}
                />
              </label>

              <label className="items-center block flex block-offer-status__lost-sold-price">
                <p className="text-2xl text-pgNavy-500 mb-2">Price Vendor Accepted:</p>
                <input
                type="text"
                className="text-2xl w-11/12 md:w-full shadow appearance-none text-sm border mr-2 rounded hover:border-pgRed-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="offerLostSoldPrice"
                placeholder=""
                id="offerLostSoldPrice"
                value = {appraisalData.offerLostSoldPrice}
                onChange = {(event) => onChangeHandler(event)}
                onKeyPress = {(event) => moduleKeyPress(event)}
                onBlur = {(event) => onBlurHandler(event,'currency')}
                />
              </label>

          </div>
        </div>
      </>
    }
    </SlideDown>
    </>



      <div className="grid grid-cols-1 gap-0 md:grid-cols-2 md:gap-4 lg:grid-cols-3 lg:gap-6 md:mt-4">

        <div>
          
          <h1
            className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
            onClick={mobile ? (event) => setShowInitialCostBlock(!showInitialCostBlock) : undefined}
          >
            <span>Initial Costs</span>
            <em className={(showInitialCostBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
          </h1>

          <SlideDown transitionOnAppear={false}>
            { showInitialCostBlock && 

            <div className="border">

              <div className="p-5">

                <label className="items-center block md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Purchase Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.price}></em><em className="icon-calculator cursor-pointer text-black-900 text-sm ml-1" onClick={setToggleSuggestedOffer}></em></p>
                  <input
                  type="text"
                  className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                  name="price"
                  placeholder="£220,000"
                  id="price"
                  value = {appraisalData.price}
                  onChange = {(event) => onChangeHandler(event)}
                  onKeyPress = {(event) => moduleKeyPress(event)}
                  onBlur = {(event) => onBlurHandler(event,'currency')}
                  />
                </label>

                <TemplateAppraisalSuggestedFlipOffer showSuggestedOffer={showSuggestedOffer} appraisalData={appraisalData} toolTips={toolTips} onChangeHandler={onChangeHandler} />

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Stamp Duty:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.stampDuty}></em></p>
                  <input
                  type="text"
                  className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                  name="stampDuty"
                  placeholder="£5,500"
                  id="stampDuty"
                  value = {appraisalData.stampDuty}
                  onChange = {(event) => onChangeHandler(event)}
                  onKeyPress = {(event) => moduleKeyPress(event)}
                  onBlur = {(event) => onBlurHandler(event,'currency')}
                  />
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Legals and Surveys:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.legalsAndSurveys}></em></p>
                  <input
                  type="text"
                  className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                  name="legalsAndSurveys"
                  placeholder="£1,900"
                  id="legalsAndSurveys"
                  value = {appraisalData.legalsAndSurveys}
                  onChange = {(event) => onChangeHandler(event)}
                  onKeyPress = {(event) => moduleKeyPress(event)}
                  onBlur = {(event) => onBlurHandler(event,'currency')}
                  />
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Refurb Costs:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refurb}></em></p>
                  <input
                  type="text"
                  className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                  name="refurb"
                  placeholder="£20,000"
                  id="refurb"
                  value = {appraisalData.refurb}
                  onChange = {(event) => onChangeHandler(event)}
                  onKeyPress = {(event) => moduleKeyPress(event)}
                  onBlur = {(event) => onBlurHandler(event,'currency')}
                  />
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Other Fees:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.otherFees}></em></p>
                  <input
                  type="text"
                  className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                  name="otherFees"
                  placeholder="£2,600"
                  id="otherFees"
                  value = {appraisalData.otherFees}
                  onChange = {(event) => onChangeHandler(event)}
                  onKeyPress = {(event) => moduleKeyPress(event)}
                  onBlur = {(event) => onBlurHandler(event,'currency')}
                  />
                </label>

                <span className="block mt-2 lg:w-full block pl-4 pr-4 text-center bg-pgNavy-500 hover:bg-pgNavy-900 text-white rounded rounded-full p-1 cursor-pointer text-sm" onClick={(event) => showCalcInitialLending(!calcInitialLending)}>Calculate Initial Lending</span>

          <SlideDown>
          { calcInitialLending && 

              <>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Initial Mortgage Rate:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialMortgageRate}></em></p>
                  <input
                  type="text"
                  className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                  name="initialMortgageRate"
                  placeholder="4.99%"
                  id="initialMortgageRate"
                  value = {appraisalData.initialMortgageRate}
                  onChange = {(event) => onChangeHandler(event)}
                  onKeyPress = {(event) => moduleKeyPress(event)}
                  onBlur = {(event) => onBlurHandler(event,'percentage')}
                  />
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Initial LTV:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialLTV}></em></p>
                  <select
                  className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                  name="initialLTV"
                  placeholder="75"
                  id="initialLTV"
                  value = {appraisalData.initialLTV}
                  onChange = {(event) => onChangeHandler(event)}
                  onKeyPress = {(event) => moduleKeyPress(event)}
                  >
                    <option value="0">0%</option>
                    <option value="5">5%</option>
                    <option value="10">10%</option>
                    <option value="15">15%</option>
                    <option value="20">20%</option>
                    <option value="25">25%</option>
                    <option value="30">30%</option>
                    <option value="35">35%</option>
                    <option value="40">40%</option>
                    <option value="45">45%</option>
                    <option value="50">50%</option>
                    <option value="55">55%</option>
                    <option value="60">60%</option>
                    <option value="65">65%</option>
                    <option value="70">70%</option>
                    <option value="75">75%</option>
                    <option value="80">80%</option>
                    <option value="85">85%</option>
                    <option value="90">90%</option>
                    <option value="95">95%</option>
                    <option value="100">100%</option>
                  </select>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Initial Type:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialInterestPeriod}></em></p>
                  <select
                  className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                  name="initialInterestPeriod"
                  placeholder="75"
                  id="initialInterestPeriod"
                  value = {appraisalData.initialInterestPeriod}
                  onChange = {(event) => onChangeHandler(event)}
                  onKeyPress = {(event) => moduleKeyPress(event)}
                  >
                    <option value="annually">Annual Interest</option>
                    <option value="monthly">Monthly Interest</option>
                  </select>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Initial Term (Months):<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialTerm}></em></p>
                  <select
                  className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                  name="initialTerm"
                  placeholder="75"
                  id="initialTerm"
                  value = {appraisalData.initialTerm}
                  onChange = {(event) => onChangeHandler(event)}
                  onKeyPress = {(event) => moduleKeyPress(event)}
                  >
                    <option value="0">0 Months</option>
                    <option value="1">1 Month</option>
                    <option value="2">2 Months</option>
                    <option value="3">3 Months</option>
                    <option value="4">4 Months</option>
                    <option value="5">5 Months</option>
                    <option value="6">6 Months</option>
                    <option value="7">7 Months</option>
                    <option value="8">8 Months</option>
                    <option value="9">9 Months</option>
                    <option value="10">10 Months</option>
                    <option value="11">11 Months</option>
                    <option value="12">12 Months</option>
                    <option value="13">13 Months</option>
                    <option value="14">14 Months</option>
                    <option value="15">15 Months</option>
                    <option value="16">16 Months</option>
                    <option value="17">17 Months</option>
                    <option value="18">18 Months</option>
                    <option value="19">19 Months</option>
                    <option value="20">20 Months</option>
                    <option value="21">21 Months</option>
                    <option value="22">22 Months</option>
                    <option value="23">23 Months</option>
                    <option value="24">24 Months</option>
                  </select>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Initial Loan Value:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.loanValue}></em></p>
                  <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.initialLoanValue)}</span>
                </label>

              </>
             }
          </SlideDown>

            <label className="items-center block pt-3 md:flex">
              <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Initial Deposit:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialDeposit}></em></p>
              <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.initialDeposit)}</span>
            </label>

            <label className="items-center block pt-3 md:flex">
              <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Initial Interest:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialAnnualInterest}></em></p>
              <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.initialAnnualInterest)}</span>
            </label>

            <label className="items-center block md:flex pt-3">
              <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Initial Capital Required:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialCapitalInvested}></em></p>
              <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.initialCapitalInvested)}</span>
            </label>
          </div>
        </div>
        }

      </SlideDown>
      </div>



      <div>

        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowReturnsBlock(!showReturnsBlock) : undefined}
        >
          <span>Returns</span>
          <em className={(showReturnsBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
          { showReturnsBlock && 
          <div className="border">

            <div className="p-5">
              <label className="items-center block md:flex">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Resale Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.salePrice}></em></p>
                <input
                type="text"
                className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                name="salePrice"
                placeholder="£280,000"
                id="salePrice"
                value = {appraisalData.salePrice}
                onChange = {(event) => onChangeHandler(event)}
                onKeyPress = {(event) => moduleKeyPress(event)}
                onBlur = {(event) => onBlurHandler(event,'currency')}
                />
              </label>

              <label className="items-center block pt-3 md:flex">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Uplift:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.saleUplift}></em></p>
                <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.saleUplift)}</span>
              </label>     


              <label className="items-center block pt-3 md:flex">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">% Uplift:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.percentageSaleUplift}></em></p>
                <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatPercentage(appraisalData.percentageSaleUplift)}</span>
              </label>         
              

              <label className="items-center block pt-3 md:flex">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Estate Agency Fee:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.eaSellingFee}></em></p>
                <input
                type="text"
                className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                name="eaSellingFee"
                placeholder="2.00%"
                id="eaSellingFee"
                value = {appraisalData.eaSellingFee}
                onChange = {(event) => onChangeHandler(event)}
                onKeyPress = {(event) => moduleKeyPress(event)}
                onBlur = {(event) => onBlurHandler(event,'percentage')}
                />
              </label>

              <label className="items-center block pt-3 md:flex">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Estate Agency Cost:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.eaSellingCosts}></em></p>
                <input
                type="text"
                className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                name="eaSellingCosts"
                placeholder="£3,000"
                id="eaSellingCosts"
                value = {appraisalData.eaSellingCosts}
                onChange = {(event) => onChangeHandler(event)}
                onKeyPress = {(event) => moduleKeyPress(event)}
                onBlur = {(event) => onBlurHandler(event,'currency')}
                />
              </label>

              <label className="items-center block pt-3 md:flex">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">ERC:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.saleERC}></em></p>
                <input type="text"
                className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
                name="saleERC"
                placeholder="£4,500"
                id="saleERC"
                value = {appraisalData.saleERC}
                onChange = {(event) => onChangeHandler(event)}
                onKeyPress = {(event) => moduleKeyPress(event)}
                onBlur = {(event) => onBlurHandler(event,'currency')}
                />
              </label>

              
              
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Profit:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.profit}></em></p>
                <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.profit)}</span>
              </label>     
              
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Return on Investment:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.ROI}></em></p>
                <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatPercentage(appraisalData.ROI)}</span>
              </label>

            </div>
          </div>
        }
      </SlideDown>
      </div>




      <div>

        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowDataBlock(!showDataBlock) : undefined}
        >
          <span>Property Data</span>
          <em className={(showDataBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
          { showDataBlock && 
        <div className="border">

          <div className="p-5">

            <label className="items-center block pt-3 md:flex">
              <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Postcode:</p>
              <input
              type="text"
              className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
              name="postcode"
              placeholder="NR59NH"
              id="postcode"
              value = {appraisalData.postcode}
              onChange = {(event) => onChangeHandler(event)}
              onKeyPress = {(event) => moduleKeyPress(event)}
              onBlur = {(event) => onBlurHandler(event,'')}
              />
            </label>

            <label className="items-center block pt-3 md:flex">
              <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Property SQM:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.sqm}></em></p>
              <input
              type="text"
              className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
              name="sqm"
              placeholder="100"
              id="sqm"
              value = {appraisalData.sqm}
              onChange = {(event) => onChangeHandler(event)}
              onKeyPress = {(event) => moduleKeyPress(event)}
              onBlur = {(event) => onBlurHandler(event,'')}
              />
            </label>

            <label className="items-center block pt-3 md:flex">
              <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Property SqFt:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.sqft}></em></p>
              <input
              type="text"
              className="shadow appearance-none text-sm border rounded w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
              name="sqft"
              placeholder="1045"
              id="sqft"
              value = {appraisalData.sqft}
              onChange = {(event) => onChangeHandler(event)}
              onKeyPress = {(event) => moduleKeyPress(event)}
              onBlur = {(event) => onBlurHandler(event,'')}
              />
            </label>

            <label className="items-center block flex pt-3 mb-4">
              <a className="block mt-2 lg:w-full block pl-4 pr-4 text-center bg-pgNavy-500 hover:bg-pgNavy-900 text-white rounded rounded-full p-1 cursor-pointer text-sm" rel="noopener noreferrer" href={`https://find-energy-certificate.service.gov.uk/find-a-certificate/search-by-postcode?postcode=${appraisalData.postcode.replace(' ','')}`} target="_blank">Lookup Size on EPC</a>
            </label>


            <label className="items-center md:flex pt-3">
              <p className="w-full block text-sm text-pgNavy-500 font-bold pt-5 md:pt-0">Property Values (per SqFt)</p>
            </label>

            <label className="items-center flex pt-3">
              <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Area Average:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.avgPricePerSqft}></em></p>
              <input
              type="text"
              className="shadow appearance-none text-sm border rounded w-6/12 md:w-3/12 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-pgRed-500"
              name="avgPricePerSqft"
              placeholder="£109"
              id="avgPricePerSqft"
              value = {appraisalData.avgPricePerSqft}
              onChange = {(event) => onChangeHandler(event)}
              onKeyPress = {(event) => moduleKeyPress(event)}
              onBlur = {(event) => onBlurHandler(event,'')}
              />
              <p className="hidden md:block ml-1 text-sm">(SqFt)</p>
            </label>

            <label className="items-center flex pt-3">
              <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Property Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.pricePerSqft}></em></p>
              <span className="border rounded font-bold overflow-hidden  w-6/12 md:w-3/12 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.pricePerSqft)}</span>
              <p className="hidden md:block text-sm ml-1">(SqFt)</p>
            </label>

            <label className="items-center flex pt-3">
              <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Difference:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.sqftDifference}></em></p>
              <span className="border rounded font-bold overflow-hidden  w-6/12 md:w-3/12 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.sqftDifference)}</span>
              <p className="hidden md:block text-sm ml-1">(SqFt)</p>
            </label>

            {(appraisalData.sqft > 0 || appraisalData.sqm > 0) && 

            <div>
              <label className="items-center md:flex pt-3 mt-3">
                <p className="w-full block text-sm text-pgNavy-500 font-bold pt-5 md:pt-0">Estimated Property Value</p>
              </label>

              <label className="items-center block md:flex pt-3">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Purchase Price:</p>
                <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.price)}</span>
              </label>

              <label className="items-center block md:flex pt-3">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Estimated Property Value:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.areaPrice}></em></p>
                <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.areaPrice)}</span>
              </label>

              <label className="items-center block md:flex pt-3">
                <p className="text-sm w-full block md:w-3/5 text-pgNavy-500">Difference:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.priceDifference}></em></p>
                <span className="border rounded font-bold overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100 text-sm">{moduleFormatCurrency(appraisalData.priceDifference)}</span>
              </label>
            </div>

            }


          </div>
        </div>
        }
      </SlideDown>
      </div>

      </div>

    </div>

      <div className="mx-8 flex mt-6 justify-end">
        {appraisalData.archive !== '1' &&
        <span onClick={() => archive(true)} className="cursor-pointer text-1xl text-center mb-2 md:mb-0 text-pgNavy-500 rounded rounded-full p-2 sm:p-1 sm:pl-4 block ml-4 bg-pgRed-500 hover:bg-pgRed-300">
          <span className="hidden sm:inline-block">Archive</span> <em className="icon-trash sm:mr-2"></em>
        </span>
        }
        {appraisalData.archive === '1' &&
        <span onClick={() => archive(false)} className="cursor-pointer text-1xl text-center mb-2 md:mb-0 text-white rounded rounded-full p-2 sm:p-1 sm:pl-4 block ml-4 bg-pgNavy-500 hover:bg-pgNavy-800">
          <span className="hidden sm:inline-block">Restore</span> <em className="icon-floppy sm:mr-2"></em>
        </span>
        }
        <Link to="/flips/createAppraisal" className="hidden text-1xl text-center text-pgNavy-500 rounded rounded-full p-2 sm:p-1 sm:pl-4 block ml-4 block bg-pgAqua-500 hover:bg-pgNavy-500 hover:text-white">
          <span className="hidden sm:inline-block">Create New Appraisal</span> <em className="icon-plus sm:mr-2"></em>
        </Link>
        {!shareLink &&
        <span onClick={() => share()} className="cursor-pointer text-1xl text-center mb-2 md:mb-0 text-pgNavy-500 rounded rounded-full p-2 sm:p-1 sm:pl-4 block ml-4 bg-pgGreen-500 hover:bg-pgGreen-700">
          <span className="hidden sm:inline-block">Share</span> <em className="icon-share sm:mr-2"></em>
        </span>
        }
        {shareLink &&
          <>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=https://app.propertygambit.co.uk/#${shareLink}`} target="_blank" rel="noopener noreferrer" className="cursor-pointer text-1xl text-center mb-2 md:mb-0 text-pgNavy-500 rounded rounded-full p-2 sm:p-1 sm:pl-4 block ml-4 bg-pgGreen-500 hover:bg-pgGreen-700">
            <span className="hidden sm:inline-block">Facebook</span> <em className="icon-facebook-squared sm:mr-2"></em>
          </a>
          <a href={`https://www.linkedin.com/shareArticle?mini=true&url=https://app.propertygambit.co.uk/%23${shareLink}&title=Check%20out%20this%20investment%20opportunity`} target="_blank" rel="noopener noreferrer" className="cursor-pointer text-1xl text-center mb-2 md:mb-0 text-pgNavy-500 rounded rounded-full p-2 sm:p-1 sm:pl-4 block ml-4 bg-pgGreen-500 hover:bg-pgGreen-700">
            <span className="hidden sm:inline-block">LinkedIn</span> <em className="icon-linkedin-squared sm:mr-2"></em>
          </a>
          <a href={`mailto:?subject=Check%20out%20this%20investment%20opportunity&body=https://app.propertygambit.co.uk/#${shareLink}`} target="_blank" rel="noopener noreferrer" className="cursor-pointer text-1xl text-center mb-2 md:mb-0 text-pgNavy-500 rounded rounded-full p-2 sm:p-1 sm:pl-4 block ml-4 bg-pgGreen-500 hover:bg-pgGreen-700">
            <span className="hidden sm:inline-block">Email</span> <em className="icon-mail-squared sm:mr-2"></em>
          </a>
          <a href={`https://app.propertygambit.co.uk/#${shareLink}`} target="_blank" rel="noopener noreferrer" className="cursor-pointer text-1xl text-center mb-2 md:mb-0 text-pgNavy-500 rounded rounded-full p-2 sm:p-1 sm:pl-4 block ml-4 bg-pgGreen-500 hover:bg-pgGreen-700">
            <span className="hidden sm:inline-block">Link</span> <em className="icon-link sm:mr-2"></em>
          </a>
          </>
        }
        <TemplateExportPDFFlips subscriber={user.subscriptionType} appraisalData={appraisalData}/>
      </div>


    </div>
  ) 
};

export default FlipAppraisal;

