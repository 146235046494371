/* 
Partial for Appraisal Thumbnail Blocl
Used to Generate the KPI's on the Appraisal View
*/

import React from "react";
import moduleFormatCurrency from "../modules/module-format-currency";
import moduleFormatPercentage from "../modules/module-format-percentage";

const TemplateAppraisalsKPIBlock = (child, item, blank, userPrefs) => {

  let value = '---';
  if(!blank) {
    // if this field is one the user has selected as a KPI
    if(userPrefs[item.key]) {
      value = child.val()[item.key]; // need to determine format e.g. moduleFormatCurrency()
      if(item.format === 'currency') {
        value = moduleFormatCurrency(value);
      }
      if(item.format === 'percentage') {
        value = moduleFormatPercentage(value);
      }
    } else {
      return ('');
    }
  }


  if(userPrefs[item.key]) {
    return (
      <p key={`p_${item.key}${child.key}}`} className={`block flex text-sm border-t pt-1 pb-1 px-4 ${blank ? "text-gray-500":"text-pgNavy-500"}`}>
        <b className="block w-6/12 text-sm">{item.label}</b>
        <em className="block w-2/12"></em>
        <span className="block w-4/12 font-bold text-sm">{value}</span>
      </p>
    )
  } else {
    return ('');
  }

}

export default TemplateAppraisalsKPIBlock;
