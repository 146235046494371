/* 
FSC FOR moduleFormatBool
Method used to format value into boolean we require for this app
*/

const moduleFormatBool = (input) => {

	if(input) {
      const cleanInput = input.toString();
      return parseInt(cleanInput);
    }
    return 0;

 };

export default moduleFormatBool;
