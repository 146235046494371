import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Amplify,  { API } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { injectStripe } from 'react-stripe-elements';
import {loadStripe} from '@stripe/stripe-js';

Amplify.configure(awsconfig);


class ModuleManageBillingButtonContent extends Component {

    state = {
        loading: false,
    }

    constructor() {
        super();
        this.manageBilling = this.manageBilling.bind(this)
    }

    async manageBilling() {
        
        this.setState({ loading: true })
        const body = {
            returnUrl: this.props.returnUrl,
            customerId: this.props.customerId,
        }
        const response = await API.post(this.props.apiName, this.props.apiEndpoint, { body });
        //console.log(response);
        window.location.href = response.session.url;
        this.setState({ loading: false })

    }

    render() {
        const stripePromise = loadStripe(this.props.stripePublicKey);
        const { loading } = this.state;
        return (
            <div stripe={stripePromise}>
                <button onClick={this.manageBilling} className="block text-left nav-item-active w-full sub-nav text-white pl-12 bg-pgNavy-500 p-3 block hover:bg-pgNavy-900 hover:text-white border-b border-pgNavy-400 text-sm">{
                    loading ?
                        <span><em className="icon-user text-pgNavy-300 absolute left-0 ml-5 text-sm"></em> loading...</span>
                        :
                        <span><em className="icon-user text-pgNavy-300 absolute left-0 ml-5 text-sm"></em> Manage Billing</span>
                }
                </button>
            </div>
        );
    }
}

ModuleManageBillingButtonContent.propTypes = {
    stripePublicKey: PropTypes.string.isRequired,
    apiName: PropTypes.string.isRequired,
    apiEndpoint: PropTypes.string.isRequired,
    returnUrl: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
};

export default injectStripe(ModuleManageBillingButtonContent);