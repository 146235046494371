/* 
FSC FOR Calculate Stamp Duty Payable
Method used to calculate SDLT
*/
import moduleFormatCurrency from "./module-format-currency";
import moduleClenseData from "./module-clense-data";

const moduleCalculateSDLT = (price) => {

    let sdltCalc = 0;
    let taxableAmount = 0;
    let stampDutyPayable = 0;

    if (moduleClenseData(price) <= 40000) {
      // Below £40,000.01, there's no Stamp Duty to pay on second residential properties in the UK 
      stampDutyPayable = 0;   
    }

    if (moduleClenseData(price) >= 0 && moduleClenseData(price) <= 125000) {
      // Between £0 & £125,000 - 3% is payable on the amount
      taxableAmount = moduleClenseData(price);
      sdltCalc = taxableAmount * 0.03;
      stampDutyPayable = moduleFormatCurrency(moduleClenseData(Math.floor(sdltCalc)));
    }

    if (moduleClenseData(price) >= 125001 && moduleClenseData(price) <= 250000) {
      // Between £125,000 and £250,000, 5% is payable on the amount
      sdltCalc = 125000 * 0.03; // 3% on the first portion
      taxableAmount = moduleClenseData(price) - 125000; //difference between 500k and 925k
      sdltCalc += taxableAmount * 0.05;
      stampDutyPayable = moduleFormatCurrency(moduleClenseData(Math.floor(sdltCalc)));
    }

    if (moduleClenseData(price) >= 250001 && moduleClenseData(price) <= 925000) {
      // Between £250,001 and £925,000, 8% is payable on the amount
      sdltCalc = 125000 * 0.03; // 3% on the first portion
      sdltCalc += 125000 * 0.05; // 8% on the second portion totaling 925k
      taxableAmount = moduleClenseData(price) - 250000; //difference between 925k and 1.5m
      sdltCalc += taxableAmount * 0.08;
      stampDutyPayable = moduleFormatCurrency(moduleClenseData(Math.floor(sdltCalc)));
    }

    if (moduleClenseData(price) >= 925001 && moduleClenseData(price) <= 1500000) {
      // Between £925,001 and £1,500,000, 13% is payable on the amount
      sdltCalc = 125000 * 0.03; // 3% on the first portion
      sdltCalc += 125000 * 0.05; // 5% on the second portion totaling 250k
      sdltCalc += 675000 * 0.08; // 8% on the second portion totaling 925k
      taxableAmount = moduleClenseData(price) - 925000; //difference between 925k and 1.5m
      sdltCalc += taxableAmount * 0.13;
      stampDutyPayable = moduleFormatCurrency(moduleClenseData(Math.floor(sdltCalc)));
    }
    
    if (moduleClenseData(price) > 1500000) {
      // Over £1.5m, 15% is payable for that portion
      sdltCalc = 125000 * 0.03; // 3% on the first portion
      sdltCalc += 125000 * 0.05; // 3% on the first portion
      sdltCalc += 675000 * 0.08; // 8% on the second portion totaling 925k
      sdltCalc += 575000 * 0.13; // 13% on the thirs portion totaling 1.5m
      taxableAmount = moduleClenseData(price) - 1500000; //anything over 1.5m
      sdltCalc += taxableAmount * 0.15;
      stampDutyPayable = moduleFormatCurrency(moduleClenseData(Math.floor(sdltCalc)));
    }

    return stampDutyPayable;

 };

 export default moduleCalculateSDLT;