/* 
Partial for Onboarding Block
*/

import React, { useState } from "react";
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import promo from '../../src/upgrade.svg';

const TemplateUpgradePrompt = ({user}) => {

  // logic here
  const [showModal, setShowModal] = useState(user && user.appraisalCount && !user.upgradePrompt && (user.subscriptionType === 'free' || user.subscriptionType === 'trial'));

  const closeModal = (uid) => {
    const userRef = firebase.database().ref('users/' + uid);
    let updates = {upgradePrompt:1};
    userRef.update(updates);
    console.log(uid);
    userRef.off();
    user.upgradePrompt = 1;
    setShowModal(false);
  }

  return (
    <div className={`${showModal?'flex':'hidden'} flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800 z-50 bg-opacity-50`}>
      <div className="bg-pgNavy-500 rounded m-10 block max-w-5xl">
        <div className="flex flex-col items-start p-4">
          <div className="flex items-center w-full">
            <svg onClick = {() => {closeModal(user.uid)}} className="ml-auto fill-current text-white w-10 h-10 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/>
            </svg>
          </div>

          <Link to="/upgrade">
            <img src={promo} className="max-w-lg w-full" alt="Upgrade Now" />
          </Link>

        </div>
      </div>
    </div>
  )

}

export default TemplateUpgradePrompt;
