/* 
Partial for Appraisal ScoreCards
Used to Generate the Scorecard on the Appraisal View
*/

import React from "react";

import moduleScorecardCalculation from "../modules/module-scorecard-calculation";

import score08 from '../../src/score-0.8.svg';
import score16 from '../../src/score-1.6.svg';
import score24 from '../../src/score-2.4.svg';
import score32 from '../../src/score-3.2.svg';
import score40 from '../../src/score-4.0.svg';
import score48 from '../../src/score-4.8.svg';
import score56 from '../../src/score-5.6.svg';
import score64 from '../../src/score-6.4.svg';
import score72 from '../../src/score-7.2.svg';
import score80 from '../../src/score-8.0.svg';
import score88 from '../../src/score-9.1.svg';
import score94 from '../../src/score-10.svg';

import bar08 from '../../src/bar-0.8.svg';
import bar16 from '../../src/bar-1.6.svg';
import bar24 from '../../src/bar-2.4.svg';
import bar32 from '../../src/bar-3.2.svg';
import bar40 from '../../src/bar-4.0.svg';
import bar48 from '../../src/bar-4.8.svg';
import bar56 from '../../src/bar-5.6.svg';
import bar64 from '../../src/bar-6.4.svg';
import bar72 from '../../src/bar-7.2.svg';
import bar80 from '../../src/bar-8.0.svg';
import bar88 from '../../src/bar-9.1.svg';
import bar94 from '../../src/bar-10.svg';

let icons = {
  scores : {
    '0.8' : score08,
    '1.6' : score16,
    '2.4' : score24,
    '3.2' : score32,
    '4.0' : score40,
    '4.8' : score48,
    '5.6' : score56,
    '6.4' : score64,
    '7.2' : score72,
    '8.0' : score80,
    '8.8' : score88,
    '9.4' : score94,
  },
  bars : {
    '0.8': bar08,
    '1.6': bar16,
    '2.4': bar24,
    '3.2': bar32,
    '4.0': bar40,
    '4.8': bar48,
    '5.6': bar56,
    '6.4': bar64,
    '7.2': bar72,
    '8.0': bar80,
    '8.8': bar88,
    '9.4': bar94,
  }
}

function processScore (score, type) {
  if(type === 'chart') {
    let icon = icons.scores[score];
    return (<img src={icon} className="pr-5 w-3/12 md:w-1/6" alt="" />);
  } else {
    let icon = icons.bars[score];
    return (<img src={icon} className="md:px-5 md:w-4/6" alt="" />);
  }
}

const TemplateAppraisalScoreCard = ({type, appraisalData, label1, dataPoint1, label2, dataPoint2, label3, dataPoint3, label4, dataPoint4}) => {

    if(!appraisalData[dataPoint1]) {
      return false;
    }

    let results = moduleScorecardCalculation(type, appraisalData, label1, dataPoint1, label2, dataPoint2, label3, dataPoint3, label4, dataPoint4);
    //console.log(results);

    return (
      <>
        
        {processScore(results.overallScore, 'chart')}

        {type !== 'flip' &&
          <>
            <div className="w-1/3">
              <p className="md:flex my-5 items-center"><span className="text-pgNavy-500 font-bold w-2/6">{label1}</span>{processScore(results.bar1, 'bar')}</p>
              <p className="md:flex items-center"><span className="text-pgNavy-500 font-bold w-2/6">{label2}</span> {processScore(results.bar2, 'bar')}</p>
            </div>

            <div className="w-1/3">
              <p className="md:flex my-5 items-center"><span className="text-pgNavy-500 font-bold w-2/6">{label3}</span> {processScore(results.bar3, 'bar')}</p>
              <p className="md:flex items-center"><span className="text-pgNavy-500 font-bold w-2/6">{label4}</span> {processScore(results.bar4, 'bar')}</p>
            </div>
          </>
        ||
          <>
            <div className="w-1/3">
              <p className="md:flex items-center"><span className="text-pgNavy-500 font-bold w-2/6">{label1}</span>{processScore(results.bar1, 'bar')}</p>
            </div>

            <div className="w-1/3">
              <p className="md:flex items-center"><span className="text-pgNavy-500 font-bold w-2/6">{label2}</span> {processScore(results.bar2, 'bar')}</p>
            </div>
          </>
        }
      </>
    )

}

export default TemplateAppraisalScoreCard;
