/* 
FSC FOR Calculate Total
Method used to calculate total costs based on pre-defined inputs
*/
import moduleFormatCurrency from "./module-format-currency";
import moduleClenseData from "./module-clense-data";

const moduleCalculateTotal = (appraisalData) => {

    let totalVal = moduleClenseData(appraisalData.price) + moduleClenseData(appraisalData.refurb) + moduleClenseData(appraisalData.legalsAndSurveys) + moduleClenseData(appraisalData.stampDuty) + moduleClenseData(appraisalData.otherFees);

    if(appraisalData.hmoLicence) {
    	totalVal += moduleClenseData(appraisalData.hmoLicence);
    }
    
    return moduleFormatCurrency(totalVal);

 };

 export default moduleCalculateTotal;