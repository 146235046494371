/* 
Partial for Appraisal mini ScoreCards
Used to Generate the Scorecard on the Appraisal View
*/

import React from "react";

import score08 from '../../src/score-0.8.svg';
import score16 from '../../src/score-1.6.svg';
import score24 from '../../src/score-2.4.svg';
import score32 from '../../src/score-3.2.svg';
import score40 from '../../src/score-4.0.svg';
import score48 from '../../src/score-4.8.svg';
import score56 from '../../src/score-5.6.svg';
import score64 from '../../src/score-6.4.svg';
import score72 from '../../src/score-7.2.svg';
import score80 from '../../src/score-8.0.svg';
import score88 from '../../src/score-9.1.svg';
import score94 from '../../src/score-10.svg';

let icons = {
  scores : {
    '0.8' : score08,
    '1.6' : score16,
    '2.4' : score24,
    '3.2' : score32,
    '4.0' : score40,
    '4.8' : score48,
    '5.6' : score56,
    '6.4' : score64,
    '7.2' : score72,
    '8.0' : score80,
    '8.8' : score88,
    '9.4' : score94,
  }
}

function processScore (score) {
  let icon = icons.scores[score];
  return (<img src={icon} className="w-2/6 -mt-2" alt="" />);
}

const TemplateAppraisalMiniScoreCard = ({overallScore}) => {

    return (
      <>
        
        {processScore(overallScore)}

      </>
    )

}

export default TemplateAppraisalMiniScoreCard;
