import React from "react";
import useGlobalHook from "use-global-hook";
import * as actions from "../actions";

const initialState = {
  apiSettings: {
      prod: {
          redirect:               'https://app.propertygambit.co.uk',
          apiName:                'stripeapi',
          stripePublicKey:        'pk_live_51HzfbjE87ZV7kg7yN1jAL6wcJvwjhgM6I7goMQRllvQBXf6mbBnqqgdi0bcKqnK9howFCp2qAAgBupDUTateQRdF00zRfwmJ9F'
      },
      dev: {
          redirect:               'http://localhost:3000',
          apiName:                'stripeapidev',
          stripePublicKey:		    'pk_test_51HzfbjE87ZV7kg7y5lqSnLoZqEfGzARO87tb2iJHPCzhQbMSz7ydFPSXHxwhcKKVzV9d4xINMvRhvPzlalwvYLzh001vsVBnec'
      }
  }
};

const apiGlobal = useGlobalHook(React, initialState, actions);

export default apiGlobal;
