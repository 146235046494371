import React from "react";
import SvgReactLogo from '../src/logo-sm.svg';

const LayoutLanding = () => {

  return (
    <div className="w-full flex h-screen w-screen bg-pgNavy-500 fixed content-center align-center justify-center">
      <div className="flex w-1/6 content-center align-center justify-center block"><img src={SvgReactLogo} className="w-full" alt="Property Gambit - Loading" /></div>
    </div>
  ) 
};

export default LayoutLanding;

