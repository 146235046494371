/* 
FSC FOR ModuleSetUserCreditLimit
Method used to define limit of credits and handle reset
userID: User ID
value: Credits to Credit 
purchase: Bool flag if its a purchase
*/
import firebase from "firebase";

const ModuleSetUserCreditLimit = (userID, value, purchase) => {

    //console.log('ModuleSetUserCreditLimit');

	// get existing user usage
	let userInfo = firebase.database().ref('/users/' + userID);
    userInfo.once('value').then(function(snapshot) {
    	let userDetails = snapshot.val();

        // if we are not setting a limit (pro user) do nothing
        if(!value) {
            let updates = {
                limit: false,
                credits: false
            };
            let typeRef = firebase.database().ref('/users/' + userID + '/usage');
            typeRef.set(updates);
            typeRef.off();
            return false;
        }

        let updates = {};

        // if no reset date present, set one for 30 days time
        // also set the token count
        if(!userDetails.usage || !userDetails.usage.resetDate) {
            var futureA = new Date();
            futureA.setMinutes(futureA.getMinutes() + 1);
            //futureA.setDate(futureA.getDate() + 30);
            updates.resetDate = futureA.getTime();
            updates.limit = true;
            updates.credits = value;
        }

        // if there is a purchase flag
        // OR if the value given is a miss match for the credits in the DB
        if(userDetails.usage) {
            var futureC = new Date();
            futureC.setMinutes(futureC.getMinutes() + 1);
            //futureC.setDate(futureC.getDate() + 30);
            updates.resetDate = futureC.getTime();
            updates.limit = true;
            updates.credits = value;
        }

        let typeRef = firebase.database().ref('/users/' + userID + '/usage');
        typeRef.update(updates);
        //console.log('ModuleSetUserCreditLimit updates', updates);
        typeRef.off();

    });

 };

export default ModuleSetUserCreditLimit;
