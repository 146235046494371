/* 
Partial for Appraisal Thumbnail Blocl
Used to Generate the KPI's on the Appraisal View
*/

import React from "react";

const TemplateAppraisalThumbnailBlock = ({appraisalData}) => {

  if(appraisalData.url && appraisalData.thumbnail) {
    return(
      <div target="blank" className="mb-5" dangerouslySetInnerHTML={{__html: `<a href="${appraisalData.url}" target="_blank"><img src="${appraisalData.thumbnail}"></a>` }}></div>
    )
  }

  if(!appraisalData.url && appraisalData.thumbnail) {
    return(
      <div target="blank" className="mb-5" dangerouslySetInnerHTML={{__html: `<img src="${appraisalData.thumbnail}">` }}></div>
    )
  }

  return (
    <div className="bg-white appraisal-block-image items-center justify-center align-middle flex mb-5 w-100 text-center h-40"></div>
  )

}

export default TemplateAppraisalThumbnailBlock;
