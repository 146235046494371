/* 
Partial for Appraisal Thumbnail Blocl
Used to Generate the KPI's on the Appraisal View
*/

import React from "react";
import moduleFormatCurrency from "../modules/module-format-currency";

const TemplateOfferTags = (child) => {

  if(child.val().offerStatus === 'offered') {

    if(child.val().offer1 && child.val().offer2 && child.val().offer3) {
      return(<span className="inline-block absolute flex justify-between pl-4 pr-4 absolute bottom-0 right-0 bg-pgNavy-500 text-sm text-white p-1 w-full"><em className="not-italic flex">Offer Submitted</em> <em className="not-italic flex">{moduleFormatCurrency(child.val().offer3)}</em></span>);
    }

    if(child.val().offer1 && child.val().offer2) {
      return(<span className="inline-block absolute flex justify-between pl-4 pr-4 bottom-0 right-0 bg-pgNavy-500 text-sm text-white p-1 w-full"><em className="not-italic flex">Offer Submitted</em> <em className="not-italic flex">{moduleFormatCurrency(child.val().offer2)}</em></span>);
    }

    if(child.val().offer1) {
      return(<span className="inline-block absolute flex justify-between pl-4 pr-4 bottom-0 right-0 bg-pgNavy-500 text-sm text-white p-1 w-full"><em className="not-italic flex">Offer Submitted</em> <em className="not-italic flex">{moduleFormatCurrency(child.val().offer1)}</em></span>);
    }

  }

  if(child.val().offerStatus === 'accepted') {
    return(<span className="inline-block absolute flex justify-between pl-4 pr-4 bottom-0 right-0 bg-pgGreen-500 text-sm text-pgNavy-500 p-1 w-full"><em className="not-italic flex">Offer Accepted</em> <em className="not-italic flex">{moduleFormatCurrency(child.val().offerAccepted)}</em></span>);
  }

  if(child.val().offerStatus === 'lost') {
    return(<span className="inline-block absolute flex justify-between pl-4 pr-4 bottom-0 right-0 bg-pgRed-500 text-sm text-white p-1 w-full"><em className="not-italic flex">Property Lost</em> <em className="not-italic flex">{moduleFormatCurrency(child.val().offerLostSoldPrice)}</em></span>);
  }
  
  return('');
  
}

export default TemplateOfferTags;
