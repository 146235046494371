/* 
Partial for Appraisal Suggested Offer Block
*/

import React, { useState, useEffect } from "react";
import moduleFormatCurrency from "../modules/module-format-currency";
import moduleFormatPercentage from "../modules/module-format-percentage";
import moduleClenseData from "../modules/module-clense-data";
import moduleCalculateSDLT from "../modules/module-stamp-duty";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ReactTooltip from 'react-tooltip';


const TemplateAppraisalSuggestedOffer = ({showSuggestedOffer, appraisalData, toolTips, onChangeHandler}) => {

  let [suggestedOffer, setSuggestedOffer]     = useState('£0');
  let [moneyOut, setMoneyOut]     = useState('50%');

  let sampleData = {};

  useEffect(() => {
      ReactTooltip.rebuild();
  });

  //////////////////////////////////////////
  // Utility Method to calc percentage difference
  //////////////////////////////////////////
  function getPercentageChange(oldNumber, newNumber){
    var decreaseValue = oldNumber - newNumber;
    return (decreaseValue / oldNumber) * 100;
  }

  //////////////////////////////////////////
  // Calc Suggested
  //////////////////////////////////////////
  const calcSuggested = (event) => {
    const {value} = event.currentTarget;

    // reduce price by 1k until it stacks
    for(let suggestedPrice = moduleClenseData(appraisalData.price); suggestedPrice > 0; suggestedPrice -= 1000) {

      let tempInitialLoan = moduleClenseData(suggestedPrice) / 100 * parseInt(appraisalData.initialLTV);
      sampleData.initialDeposit = suggestedPrice - tempInitialLoan;

      if('annually' === appraisalData.initialInterestPeriod) {
        sampleData.initialAnnualInterest = (moduleClenseData(tempInitialLoan) / 100 * moduleClenseData(sampleData.initialMortgageRate) / 12) * moduleClenseData(sampleData.initialTerm);
      } else {
        sampleData.initialAnnualInterest = (moduleClenseData(tempInitialLoan) / 100 * moduleClenseData(sampleData.initialMortgageRate)) * moduleClenseData(sampleData.initialTerm);     
      }

      sampleData.stampDuty = moduleCalculateSDLT(suggestedPrice);

      sampleData.initialCapitalInvested = moduleClenseData(sampleData.initialDeposit) + moduleClenseData(appraisalData.legalsAndSurveys) + moduleClenseData(sampleData.stampDuty) + moduleClenseData(appraisalData.refurb) + moduleClenseData(appraisalData.otherFees) + moduleClenseData(appraisalData.initialAnnualInterest);

      if(appraisalData.hmoLicence) {
        sampleData.initialCapitalInvested += moduleClenseData(appraisalData.hmoLicence);
      }

      sampleData.refinanceLoanValue = moduleClenseData(appraisalData.refinancePrice) / 100 * parseInt(appraisalData.refinanceLTV);
      sampleData.refinanceDeposit = moduleClenseData(appraisalData.refinancePrice) - sampleData.refinanceLoanValue;
      sampleData.refinanceAnnualInterest = moduleClenseData(sampleData.refinanceLoanValue) / 100 * moduleClenseData(appraisalData.refinanceMortgageRate);

      sampleData.refinanceEquityReleased = moduleClenseData(sampleData.refinanceLoanValue) - moduleClenseData(tempInitialLoan) - moduleClenseData(appraisalData.refinanceERC);

      sampleData.refinanceCapitalInvested = moduleClenseData(sampleData.initialCapitalInvested) - moduleClenseData(sampleData.refinanceEquityReleased);

      var percDiff = 100 - getPercentageChange(sampleData.initialCapitalInvested, sampleData.refinanceEquityReleased);

      if(percDiff >= value) {
        setSuggestedOffer(moduleFormatCurrency(suggestedPrice));
        setMoneyOut(moduleFormatPercentage(value));
        break;
      }

    }

    
  };

    return(

    <SlideDown>

      { showSuggestedOffer ? 
      <div>
      <label className="items-center block pt-3 flex">
        <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">% of Money Out:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.moneyOut}></em></p>
        <input
        type="text"
        className="shadow appearance-none border rounded w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-orange-500"
        name="moneyOut"
        placeholder={moneyOut}
        id="moneyOut"
        value = {appraisalData.moneyOut}
        onChange= {(event) => calcSuggested(event)}
        />
      </label>


      <label className="items-center block pt-3 flex">
        <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Suggested Offer:</p>
        <span className="border rounded font-bold w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{suggestedOffer}</span>
      </label>

      <ReactTooltip effect="solid" clickable={true} backgroundColor="#f65312" textColor="#fff" multiline={true} />
      </div>
      : '' }

    </SlideDown>
    
  )


}

export default TemplateAppraisalSuggestedOffer;
