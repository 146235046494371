/* 
Partial for Appraisal Thumbnail Blocl
Used to Generate the KPI's on the Appraisal View
*/

import React from "react";
import TemplateOfferTags from "./template-appraisals-offer-tags"; 
import ModuleLazyBackground from "../modules/module-lazy-background"; 

const TemplateAppraisalsImageBlock = (child) => {

  if(child.val().thumbnail) {
    return (
      <div className="icon-wrap relative">
        <span className="flex justify-around pt-2 pb-2">
          
          <div className={`flex icon items-center icon-${child.val().flagBMV}`}>
            <em className="icon-down"></em>
            <span className="text-xs">BMV</span>
          </div>
          <div className={`flex icon items-center icon-${child.val().flagNoChain}`}>
            <em className="icon-link"></em>
            <span className="text-xs">No Chain</span>
          </div>
          <div className={`flex icon items-center icon-${child.val().flagHot}`}>
            <em className="icon-fire"></em>
            <span className="text-xs">HOT</span>
          </div>

        </span>
        <ModuleLazyBackground src={child.val().thumbnail} placeholder='path/to/placeholder.jpg' />
        {TemplateOfferTags(child)}
      </div>);
  }
  else {
    return (
      <div className="icon-wrap relative">
        <span className="flex justify-around pt-2 pb-2">
          
          <div className={`flex icon items-center icon-${child.val().flagBMV}`}>
            <em className="icon-down"></em>
            <span className="text-xs">BMV</span>
          </div>
          <div className={`flex icon items-center icon-${child.val().flagNoChain}`}>
            <em className="icon-link"></em>
            <span className="text-xs">No Chain</span>
          </div>
          <div className={`flex icon items-center icon-${child.val().flagHot}`}>
            <em className="icon-fire"></em>
            <span className="text-xs">HOT</span>
          </div>

        </span>
        <div className="bg-white appraisal-block-image items-center justify-center align-middle flex w-100 text-center h-40">
        </div>
        {TemplateOfferTags(child)}
      </div>);
  }

}

export default TemplateAppraisalsImageBlock;
