import React from "react";
import SvgReactLogo from '../src/logo.svg';

const LayoutShare = ({ children }) => {

  return (
    <div className="block">

      <link rel="preconnect" href="https://fonts.gstatic.com" /><link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap" rel="stylesheet" />

      <div className="w-full bg-pgNavy-500 p-2 flex items-center justify-between">
      <a className="block text-white text-3xl px-10 py-2" href="https://www.propertygambit.co.uk/" target="_blank" rel="noopener noreferrer"><img src={SvgReactLogo} className="h-10" alt="Property Gambit" /></a>
      <a className="block text-white text-sm pr-10" href="https://www.propertygambit.co.uk/" target="_blank" rel="noopener noreferrer">Find and secure the best property investments!</a>
      </div>

      {children}
    </div>
  ) 
};

export default LayoutShare;

