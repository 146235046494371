/* 
FSC FOR moduleFormatCurrency
Method used to format value into the currency we require for this app
*/
import moduleClenseData from "./module-clense-data";

const moduleFormatCurrency = (number) => {

	if(!number) {
      return '£0.00';
    }
    if(number === Number.POSITIVE_INFINITY || number === Number.NEGATIVE_INFINITY) {
      return '-';
    }
    const newNum = moduleClenseData(number);
    return '£' + Number(newNum).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

 };

 export default moduleFormatCurrency;