/* 
FSC FOR ModuleRenewCredits
Method used to define limit of credits and handle reset
userID: User ID
value: Credits to Credit 
*/
import firebase from "firebase";

const ModuleRenewCredits = (userID, value, user) => {

	// get existing user usage
	let userInfo = firebase.database().ref('/users/' + userID);
    userInfo.once('value').then(function(snapshot) {
    	let userDetails = snapshot.val();
        let updates = {};

        // if there IS a reset date and its elapsed... reset credits and reset date
        // wont ever be trial user as trial is 14 days
        let now = new Date();
        if(userDetails.usage && userDetails.usage.resetDate && (now.getTime() > userDetails.usage.resetDate) && (value > userDetails.usage.credits)) {
            
            //console.log('expiry elapsed');
            // if the users has less than the default reset them, otherwise roll purchased credits over for another month
            // user purchased credits wont currently expire like the default ones.
            if(userDetails.usage.credits < value) {
                updates.credits = value;
                user.setCredits(value);
            }
            var futureB = new Date();
            //futureB.setMinutes(futureB.getMinutes() + 1);
            futureB.setDate(futureB.getDate() + 30); // 30 days

            updates.resetDate = futureB.getTime();
            updates.limit = true;
        }

        let typeRef = firebase.database().ref('/users/' + userID + '/usage');
        typeRef.update(updates);
        //console.log('ModuleRenewCredits updates', updates);
        typeRef.off();

    });

 };

export default ModuleRenewCredits;
