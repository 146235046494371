import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import ModuleSubScribeButtonContent from '../modules/module-subscribe-now-button-content';

class TemplateSubscribeNowButton extends Component {
    render() {
        const {
            stripePublicKey,
            apiName,
            buttonText,
            apiEndpoint,
            className,

            successUrl,
            cancelUrl,
            customerId,
        } = this.props;
        return (
            <StripeProvider apiKey={stripePublicKey}>
                <Elements>
                    <ModuleSubScribeButtonContent
                        apiName={apiName}
                        apiEndpoint={apiEndpoint}
                        successUrl={successUrl}
                        cancelUrl={cancelUrl}
                        buttonText={buttonText}
                        className={className}
                        customerId={customerId}
                        stripePublicKey={stripePublicKey}
                    />
                </Elements>
            </StripeProvider>
        );
    }
}

TemplateSubscribeNowButton.propTypes = {
    stripePublicKey: PropTypes.string.isRequired,
    apiName: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    apiEndpoint: PropTypes.string.isRequired,

    successUrl: PropTypes.string.isRequired,
    cancelUrl: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
};

export default TemplateSubscribeNowButton;