import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { auth, signInWithGoogle, generateUserDocument } from "../firebase";
import SvgHand from '../src/hand.svg';
import SvgGoogle from '../src/google.svg';

const SignUp = () => {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [userTerms, setUserTerms] = useState(1);
  const [error, setError] = useState(null);
  const history = useHistory();

  const redirect = () => history.push('/');

  const createUserWithEmailAndPasswordHandler = async (event, email, password) => {

    event.preventDefault();

    if(!userTerms) {
     setError('You must agree to our Terms and Conditions to use this service');
     return;
    }

    try{
      const {user} = await auth.createUserWithEmailAndPassword(email, password);
      generateUserDocument(user, {userTerms, displayName});
      redirect();
    }
    catch(error){
      setError('Error Signing up with email and password');
    }
    
    setDisplayName("");
    setEmail("");
    setPassword("");
    setUserTerms(1);
  };

  const onChangeHandler = event => {
    let { name, value } = event.currentTarget;
    const target = event.target;
    value = target.type === 'checkbox' ? target.checked : target.value;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "userTerms") {
      setUserTerms(value);
    } else if (name === "displayName") {
      setDisplayName(value);
    }
  };

  return (
    <div className="w-full md:w-2/3 lg:w-2/3 xl:w-6/12 2xl:w-5/12 p-10 lg:p-20">

      <h1 className="text-3xl md:text-4xl font-bold mb-2 text-white">Try it for FREE</h1>
      <p className="mb-4 text-white">Sign up for your <strong className="font-bold">Free Trial</strong> with no credit card or payment required!</p>

      <button
        onClick={(e) => {
          e.preventDefault();
          try {
            signInWithGoogle();
          } catch (error) {
            console.error("Error signing in with Google", error);
          }
        }}
        className="bg-white rounded rounded-lg p-5 mt-10 md:text-lg items-center text-pgBlue-500 flex whitespace-nowrap hover:text-white hover:bg-pgNavy-500 track-google-signup"
      >
        <img src={SvgGoogle} className="pr-5" alt="" /> Start Free Trial with Google Login
      </button>

      <p className="mt-10 mb-10 flex w-full items-center"><span className="border-t w-2/5"></span> <span className="w-1/5 text-center text-white">OR</span> <span className="border-t w-2/5"></span></p>

      <div>
        {error !== null && (
          <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <form className="">
  
          <label htmlFor="displayName" className="block text-white">
            Display Name:
          </label>
          <input
            type="text"
            className="text-pgBlue-500 my-1 mb-4 p-3 pl-5 pr-5 w-full text-lg"
            name="displayName"
            value={displayName}
            placeholder="E.g: Ashley Smith"
            id="displayName"
            onChange={event => onChangeHandler(event)}
          />
 
          <label htmlFor="userEmail" className="block text-white">
            Email Address:
          </label>
          <input
            type="email"
            className="text-pgBlue-500 my-1 mb-4 p-3 pl-5 pr-5 w-full text-lg"
            name="userEmail"
            value={email}
            placeholder="Your Email"
            id="userEmail"
            onChange={event => onChangeHandler(event)}
          />

          <label htmlFor="userPassword" className="block text-white">
            Password:
          </label>
          <input
            type="password"
            className="text-pgBlue-500 my-1 mb-4 p-3 pl-5 pr-5 w-full text-lg"
            name="userPassword"
            value={password}
            placeholder="Choose Password"
            id="userPassword"
            onChange={event => onChangeHandler(event)}
          />

          <p className="flex mb-8 text-white text-sm items-center"><input
            required
            name="userTerms"
            type="checkbox"
            checked={userTerms}
            onChange={event => onChangeHandler(event)} /><span className="ml-3">I have read agree to the <a href="https://www.propertygambit.co.uk/terms.html" target="_blank" rel="noopener noreferrer" className="underline">Terms and Conditions</a> & <a href="https://www.propertygambit.co.uk/privacy.html" target="_blank" rel="noopener noreferrer" className="underline">Privacy Policy</a></span></p>

          <div className="block">

            <button
              className="mb-2 md:mb-0 text-left bg-pgGreen-500 hover:text-white hover:bg-pgNavy-500 rounded rounded-full p-2 pl-10 pr-10 md:text-lg text-pgNavy-500 track-default-signup"
              onClick={event => {
                createUserWithEmailAndPasswordHandler(event, email, password);
              }}
            >
              Start Free Trial
            </button>

            

          </div>

        </form>

        <p className="text-center text-white mt-20 md:absolute md:top-0 md:right-0 md:m-10">
          Already have an account?
          <Link to="/" className="rounded rounded-full ml-5 pl-5 pr-5 pt-2 text-lg pb-2 bg-pgAqua-500 text-pgBlue-500 hover:text-white hover:bg-pgNavy-500">
             Login here
          </Link>
        </p>
      </div>

      <img src={SvgHand} className="hidden md:block fixed bottom-0 right-0 max-w-screen-lg max-h-2/3 w-1/2" alt="Keys" />

    </div>
  );
};

export default SignUp;
