import React, { useState, useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import firebase from 'firebase';
import useGlobal from "../store";
import ReactTooltip from 'react-tooltip';

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import moduleScorecardCalculation from "./modules/module-scorecard-calculation";
import moduleFormatCurrency from "./modules/module-format-currency";
import moduleFormatPercentage from "./modules/module-format-percentage";
import moduleFormatFriendlyData from "./modules/module-format-friendly-data";
import moduleCalculateTotal from "./modules/module-calculate-total";

import TemplateAppraisalScoreCard from "./templates/template-appraisal-scorecard";
import TemplateAppraisalKPIBlock from "./templates/template-appraisal-kpi-block";
import TemplateAppraisalThumbnailBlock from "./templates/template-appraisal-thumbnail-block";

const ShareHMO = props => {

  //////////////////////////////////////////
  // Effects
  //////////////////////////////////////////
  React.useEffect(
    () => {
      setup();
    },
    []
  );

  //////////////////////////////////////////
  // Variables
  //////////////////////////////////////////
  const user = useContext(UserContext);
  const [preferenceMapping]                             = useGlobal( state => state.preferenceMapping );   
  const [toolTips]                                      = useGlobal( state => state.toolTips );
  const [userPrefs]                                     = useGlobal( state => state.preferences );
  const [scaffold]                                      = useGlobal( state => state.appraisalHMOScaffold );
  const windowWidth                                     = window.innerWidth;
  const mobile                                          = (windowWidth <= 768);

  let [appraisalData, setAppraisalData]                 = useState(scaffold);
  let [total, setTotal]                                 = useState(false);

  let [showInitialCostBlock, setShowInitialCostBlock]   = useState(!mobile);
  let [showInitialLendingBlock, setShowInitialLendingBlock]   = useState(!mobile);
  let [showRefinanceBlock, setShowRefinanceBlock]       = useState(!mobile);
  let [showIncomeBlock, setShowIncomeBlock]             = useState(!mobile);
  let [showReturnsBlock, setShowReturnsBlock]           = useState(!mobile);
  let [showDataBlock, setShowDataBlock]                 = useState(!mobile);

  //////////////////////////////////////////
  // Initial Setup
  //////////////////////////////////////////
  function setup() {
    let coreRef = firebase.database().ref('/public/hmo/' + props.appraisalId);
    coreRef.once('value').then(function(snapshot) {

      const processed = moduleFormatFriendlyData(snapshot.val());
      appraisalData = moduleFormatFriendlyData(appraisalData);
      appraisalData = Object.assign(appraisalData, processed);
      setAppraisalData(appraisalData);
      setTotal(moduleCalculateTotal(appraisalData));
      calculateScorecard();

      coreRef.off('value');
    });
  }


  //////////////////////////////////////////
  // Calulcate Scorecard
  //////////////////////////////////////////
  function calculateScorecard() {

    let scorecard = moduleScorecardCalculation('hmo', appraisalData, 'ROCE', 'roce', 'Money Recycled', 'percentageRecycled', 'Gross Yield', 'grossYield', 'Price Per Room', 'pricePerRoom');

    appraisalData.overallScore = scorecard.overallScore;

  }


  return (
    
  <div className="leading-normal tracking-normal bg-pgGrey-500 js-top-anchor pb-10">

    <ReactTooltip effect="solid" clickable={true} backgroundColor="#e7ff80" textColor="#00325b" multiline={true} />
    
    <nav id="scorecard" className="bg-white w-full z-10 ">

        <div className="w-full container mx-auto flex items-center mt-0 p-5 justify-around">

          <TemplateAppraisalScoreCard type='hmo' appraisalData={appraisalData} label1='ROCE' dataPoint1='roce' label2='Money Recycled' dataPoint2='percentageRecycled' label3='Gross Yield' dataPoint3='grossYield' label4='Price Per Room' dataPoint4='pricePerRoom' />

        </div>

    </nav>
    <nav id="header" className="bg-white md:sticky top-0 w-full z-10 border-b border-grey-800 sticky-bar">

        <div className="w-full container mx-auto flex flex-wrap items-center mt-0 p-5 justify-around">

            {preferenceMapping.map((item, index) => (
                 TemplateAppraisalKPIBlock(appraisalData, item, false, userPrefs)
            ))}

        </div>

    </nav>

    <div className={`bg-white md:mt-8 md:mb-8 md:mr-8 md:ml-8 p-5 border-pgGray-500 border rounded lg:p-10 archive-${appraisalData.archive}`}>

      <div className="flex flex-wrap pt-4">

        <div className="w-full md:w-100 xl:w-100 md:flex justify-between">

          <div className="block w-full md:w-3/12 md:pr-10 pb-5 md:pb-0 align-middle">

            <TemplateAppraisalThumbnailBlock appraisalData={appraisalData}></TemplateAppraisalThumbnailBlock>

          </div>

          <div className="block w-full md:w-9/12 p-5 border rounded">

            <label className="items-center block md:flex">
              <p className="text-sm sm:text-base md:w-1/5 pb-1 md:pb-0 block text-pgNavy-500">Property Name:</p>
              <p className="border rounded bg-gray-100 w-full w-4/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.name}</p>
            </label>

            {appraisalData.url && 
            <label className="items-center block pt-3 md:flex">
              <p className="text-sm sm:text-base md:w-1/5 pb-1 md:pb-0 block text-pgNavy-500">URL:</p>
              <p className="border rounded bg-gray-100 w-full w-4/5 py-2 px-3 text-gray-700 leading-tight underline"><a href={appraisalData.url} target="_blank">View on Portal</a></p>
            </label>
            }

            {appraisalData.rooms && 
            <label className="items-center block pt-3 md:flex">
              <p className="text-sm sm:text-base md:w-1/5 pb-1 md:pb-0 block text-pgNavy-500">HMO Bedroom Count:</p>
              <p className="border rounded bg-gray-100 w-full w-4/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.rooms}</p>
            </label>
            }

            {appraisalData.askingPrice && 
            <label className="items-center pt-3 block md:flex">
              <p className="text-sm sm:text-base md:w-1/5 pb-1 md:pb-0 block text-pgNavy-500">Asking Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.askingPrice}></em></p>
              <p className="border rounded bg-gray-100 w-full w-4/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.askingPrice}</p>
            </label>
            }

            {appraisalData.notes && 
            <label className="items-center block pt-3 md:flex">
              <p className="text-sm sm:text-base md:w-1/5 pb-1 md:pb-0 block text-pgNavy-500">Notes:</p>
              <p className="border rounded bg-gray-100 w-full w-4/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.notes}</p>
            </label>
            }

          </div>

        </div>
    </div>



    <div className="grid grid-cols-1 gap-0 md:grid-cols-2 md:gap-4 lg:grid-cols-3 lg:gap-6 md:mt-4">

      <div>

        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowInitialCostBlock(!showInitialCostBlock) : undefined}
        >
          <span>Initial Costs</span>
          <em className={(showInitialCostBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
        { showInitialCostBlock && 

          <div className="border">

            <div className="p-5">

              {appraisalData.price && 
              <label className="items-center block md:flex">
               <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Purchase Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.price}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.price}</p>
              </label>
              }

              {appraisalData.stampDuty && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Stamp Duty:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.stampDuty}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.stampDuty}</p>
              </label>
              }

              {appraisalData.legalsAndSurveys && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Legals and Surveys:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.legalsAndSurveys}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.legalsAndSurveys}</p>
              </label>
              }

              {appraisalData.refurb && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Refurb Costs:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refurb}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.refurb}</p>
              </label>
              }

              {appraisalData.hmoLicence && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">HMO Licence:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.hmoLicence}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.hmoLicence}</p>
              </label>
              }

              {appraisalData.otherFees && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Other Fees:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.otherFees}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.otherFees}</p>
              </label>
              }

              {total && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Total:</p>
                <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{total}</span>
              </label>
              }
            </div>
          </div>

        }
        </SlideDown>
      </div>







      <div>
        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowInitialLendingBlock(!showInitialLendingBlock) : undefined}
        >
          <span>Initial Lending</span>
          <em className={(showInitialLendingBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
        { showInitialLendingBlock && 

          <div className="border">
            
            <div className="p-5">

            {appraisalData.price && 
              <label className="items-center block md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Purchase Price:</p>
                <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.price)}</span>
              </label>
            }

            {appraisalData.initialMortgageRate && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Initial Mortgage Rate:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialMortgageRate}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.initialMortgageRate}</p>
              </label>
            }

            {appraisalData.initialLTV && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Initial LTV:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialLTV}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.initialLTV}%</p>
              </label>
            }

            {appraisalData.initialLoanValue && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Initial Loan Value:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.loanValue}></em></p>
                <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.initialLoanValue)}</span>
              </label>
            }

            {appraisalData.initialDeposit && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Initial Deposit:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialDeposit}></em></p>
                <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.initialDeposit)}</span>
              </label>
            }

            {appraisalData.initialAnnualInterest && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Initial Interest:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialAnnualInterest}></em></p>
                <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.initialAnnualInterest)}</span>
              </label>
            }

            {appraisalData.initialCapitalInvested && 
              <label className="items-center block md:flex pt-3">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Initial Capital Required:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialCapitalInvested}></em></p>
                <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.initialCapitalInvested)}</span>
              </label>
            }
            </div>
          </div>


          }
        </SlideDown>
      </div>







      <div>
        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowRefinanceBlock(!showRefinanceBlock) : undefined}
        >
          <span>Refinance</span>
          <em className={(showRefinanceBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
          { showRefinanceBlock && 

            <div className="border">

              <div className="p-5">

              {appraisalData.refinancePrice && 
                <label className="items-center block md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Refinance Valuation:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refinancePrice}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.refinancePrice}</p>
                </label>
              }

              {appraisalData.refinanceLTV && 
                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Refinance LTV:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refinanceLTV}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.refinanceLTV}%</p>
                </label>
              }

              {appraisalData.refinanceERC && 
                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">ERC / Retained Interest:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refinanceERC}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.refinanceERC}</p>
                </label>
              }

              {appraisalData.refinanceLoanValue && 
                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Refinance Loan Value:
                  <em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refinanceLoanValue}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.refinanceLoanValue)}</span>
                </label>
              }

              {appraisalData.refinanceEquityReleased && 
                <label className="items-center w-full block md:flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Equity Released:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refinanceEquityReleased}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.refinanceEquityReleased)}</span>
                </label>
              }

              {appraisalData.refinanceCapitalInvested && 
                <label className="items-center w-full block md:flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Equity Tied Up:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refinanceCapitalInvested}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.refinanceCapitalInvested)}</span>
                </label>
              }

              {appraisalData.percentageRecycled && 
                <label className="items-center w-full block md:flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">% of capital recycled:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.percentageRecycled}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatPercentage(appraisalData.percentageRecycled)}</span>
                </label>
              }

              </div>
            </div>
          
          }
        </SlideDown>
      </div>








      <div>
        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowIncomeBlock(!showIncomeBlock) : undefined}
        >
          <span>Income / Expenses</span>
         
          <em className={(showIncomeBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
          { showIncomeBlock && 

            <div className="border">

              <div className="p-5">

                <label className="items-center flex">
                  <p className="w-full block md:w-3/5 text-pgNavy-500 font-bold">Income</p>
                </label>

                {appraisalData.grossRent && 
                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Gross Monthly Rent:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.grossRent}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.grossRent}</p>
                </label>
                }

                {appraisalData.grossAnnualIncome && 
                <label className="items-center block md:flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Gross Annual Rent:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.grossAnnualIncome}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.grossAnnualIncome)}</span>
                </label>
                }

                {appraisalData.avgRoomRent && 
                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Average Room Rent:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.avgRoomRent}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.avgRoomRent}</p>
                </label>
                }
                
                <label className="items-center block flex pt-3 mt-3">
                  <p className="w-full block md:w-3/5 text-pgNavy-500 font-bold">Expenses</p>
                </label>

                {appraisalData.annualRepairsAndMaintenance && 
                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Annual Maintenance:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.annualRepairsAndMaintenance}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.annualRepairsAndMaintenance}</p>
                </label>
                }
                
                {appraisalData.monthlyBills && 
                <label className="items-center block md:flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Monthly Bills:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.monthlyBills}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.monthlyBills)}</span>
                </label>
                }

                {appraisalData.annualTentantFindFees && 
                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Annual Tenant Find Fee:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.annualTentantFindFees}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.annualTentantFindFees}</p>
                </label>
                }

                {appraisalData.monthlyManagementCost && 
                <label className="items-center block md:flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Monthly Management:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.monthlyManagementCost}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.monthlyManagementCost)}</span>
                </label>
                }

                {appraisalData.refinanceAnnualInterest && 
                <label className="items-center block md:block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Monthly Mortgage Interest:</p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.refinanceAnnualInterest/12)}</span>
                </label>
                }

              </div>

            </div>
      
          }
        </SlideDown>
      </div>







      <div>
        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowReturnsBlock(!showReturnsBlock) : undefined}
        >
          <span>Returns</span>
          <em className={(showReturnsBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
          { showReturnsBlock && 
            <div className="border">
              <div className="p-5">

                <label className="items-center block md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Net Annual Income:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.netAnnualIncome}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.netAnnualIncome)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Net Monthly Income:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.netMonthlyIncome}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.netMonthlyIncome)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Gross Yield:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.grossYield}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatPercentage(appraisalData.grossYield)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Net Yield:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.netYield}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatPercentage(appraisalData.netYield)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">ROCE:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.roce}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatPercentage(appraisalData.roce)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Growth Rate:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.growthRate}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.growthRate}</p>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Capital Appreciation:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.annualGrowth}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.annualGrowth)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Total Rent &<br />Capital Appreciation:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.rentAndGrowth}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.rentAndGrowth)}</span>
                </label>

                <label className="items-center block pt-3 flex hidden">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Combined Annual Capital Appreciation + Annual Rent:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.combinedRoce}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatPercentage(appraisalData.combinedRoce)}</span>
                </label>

                <label className="items-center block pt-3 flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Price Per Room:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.pricePerRoom}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.pricePerRoom)}</span>
                </label>

              </div>
            </div>
          
        }
        </SlideDown>
      </div>






      <div>
        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowDataBlock(!showDataBlock) : undefined}
        >
          <span>Property Data</span>
          <em className={(showDataBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
          { showDataBlock && 

            <div className="border">
              <div className="p-5">

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Postcode:</p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.postcode}</p>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Property SQM:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.sqm}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.sqm}</p>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Property SqFt:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.sqft}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.sqft}</p>
                </label>

                <label className="items-center flex pt-6">
                  <p className="w-full block md:w-3/5 text-pgNavy-500 font-bold">Property Values (per SqFt)</p>
                </label>

                <label className="items-center flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Area Average:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.avgPricePerSqft}></em></p>
                  <p className="border rounded bg-gray-100 w-6/12 md:w-3/12 py-2 px-3 text-gray-700 leading-tight">{appraisalData.avgPricePerSqft}</p>
                  <p className="hidden md:block ml-1 text-sm">(SqFt)</p>
                </label>

                <label className="items-center flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Property Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.pricePerSqft}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden  w-6/12 md:w-3/12 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.pricePerSqft)}</span>
                  <p className="hidden md:block text-sm ml-1">(SqFt)</p>
                </label>

                <label className="items-center flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Difference:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.sqftDifference}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden  w-6/12 md:w-3/12 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.sqftDifference)}</span>
                  <p className="hidden md:block text-sm ml-1">(SqFt)</p>
                </label>

                {(appraisalData.sqft > 0 || appraisalData.sqm > 0) && 

                  <div>
                  
                    <label className="items-center flex pt-3 mt-3">
                      <p className="w-full block md:w-3/5 text-pgNavy-500 font-bold">Estimated GDV</p>
                    </label>

                    <label className="items-center flex pt-3">
                      <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Estimated Property Value:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.areaPrice}></em></p>
                      <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.areaPrice)}</span>
                    </label>

                  </div>

                }
              </div>
            </div>
          
        }
        </SlideDown>
      </div>

      </div>

    </div>



    </div>
  ) 
};

export default ShareHMO;

