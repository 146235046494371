import React, { useContext, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import useGlobal from "../store";

import TemplateAppraisalListing from "./templates/template-appraisals-listing";
import UpgradePrompt from "./templates/template-upgrade-prompt";

const Dashboard = props => {

const user = useContext(UserContext);

const [userPrefs] = useGlobal(
  state => state.preferences
);

const [preferenceMapping] = useGlobal(
  state => state.preferenceMapping
);

let [hmoListingContent, setHMOListingContent] = useState([]);
let [btlListingContent, setBTLListingContent] = useState([]);
let [flipListingContent, setFlipListingContent] = useState([]);


React.useEffect(
  () => {

    let hmoListing = firebase.database().ref('/appraisals/' + user.uid + '/hmo').orderByChild('archive').equalTo('0').limitToLast(3);
    hmoListing.once('value').then(function(snapshot) {
      let hmoAppraisals = [];
      snapshot.forEach(child =>{
        hmoAppraisals.push(child);
      });
      hmoAppraisals.reverse();
      setHMOListingContent(hmoAppraisals);
      hmoListing.off('value');
    });

    let btlListing = firebase.database().ref('/appraisals/' + user.uid + '/btl').orderByChild('archive').equalTo('0').limitToLast(3);
    btlListing.once('value').then(function(snapshot) {
      let btlAppraisals = [];
      snapshot.forEach(child =>{
        btlAppraisals.push(child);
      });
      btlAppraisals.reverse();
      setBTLListingContent(btlAppraisals);
      btlListing.off('value');
    });

    let flipsListing = firebase.database().ref('/appraisals/' + user.uid + '/flips').orderByChild('archive').equalTo('0').limitToLast(3);
    flipsListing.once('value').then(function(snapshot) {
      let flipsAppraisals = [];
      snapshot.forEach(child =>{
        flipsAppraisals.push(child);
      });
      flipsAppraisals.reverse();
      setFlipListingContent(flipsAppraisals);
      flipsListing.off('value');
    });

  },
  [user.uid]
);



  return (


  <div className="leading-normal tracking-normal p-8">

    <UpgradePrompt user={user} />

    <div className="bg-white w-full box-border mx-auto p-5 border-pgGray-500 border rounded lg:p-10">

        <Link to="/hmo" className="flex mb-10 justify-between items-center">
          <h1 className="text-3xl leading-none text-pgNavy-500">HMO <span className="hidden md:inline">Appraisals</span></h1>
           <div className="float-right text-1xl text-center w-2/5 md:w-1/5 rounded rounded-full block p-1 bg-pgNavy-500 text-white hover:bg-pgNavy-300">
            View All
          </div>
        </Link>
        <TemplateAppraisalListing type={`hmo`} listingContent={hmoListingContent} archive={props.archive} userPrefs={userPrefs} preferenceMapping={preferenceMapping}></TemplateAppraisalListing>

        <Link to="/btl" className="flex mb-10 mt-16 justify-between items-center">
          <h1 className="text-3xl leading-none text-pgNavy-500">BTL <span className="hidden md:inline">Appraisals</span></h1>
           <div className="float-right text-1xl text-center w-2/5 md:w-1/5 rounded rounded-full block p-1 bg-pgNavy-500 text-white hover:bg-pgNavy-300">
            View All
          </div>
        </Link>
        <TemplateAppraisalListing type={`btl`} listingContent={btlListingContent} archive={props.archive} userPrefs={userPrefs} preferenceMapping={preferenceMapping}></TemplateAppraisalListing>

        <Link to="/flips" className="flex mb-10 mt-16 justify-between items-center">
          <h1 className="text-3xl leading-none text-pgNavy-500">Flip <span className="hidden md:inline">Appraisals</span></h1>
           <div className="float-right text-1xl text-center w-2/5 md:w-1/5 rounded rounded-full block p-1 bg-pgNavy-500 text-white hover:bg-pgNavy-300">
            View All
          </div>
        </Link>
        <TemplateAppraisalListing type={`flips`} listingContent={flipListingContent} archive={props.archive} userPrefs={userPrefs} preferenceMapping={preferenceMapping}></TemplateAppraisalListing>

    </div>

  </div>
  ) 
};

export default Dashboard;

