const creditMapping = {
	'1000' : 10,
	'1500' : 20,
	'2000' : 40,
}

const defaultCredits = 5;
const defaultProCredits = 25;

const standardPrice = 999;
const proPrice = 1699;
const historicalMonthly = 1000;
const historicalAnnual = 9500;

let priceSettings = {
	creditMapping : creditMapping,
	defaultCredits : defaultCredits,
	defaultProCredits : defaultProCredits,
	standardPrice : standardPrice,
	proPrice : proPrice,
	historicalMonthly : historicalMonthly,
	historicalAnnual : historicalAnnual,
}

export default priceSettings;