import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Amplify,  { API } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { injectStripe } from 'react-stripe-elements';
import {loadStripe} from '@stripe/stripe-js';

Amplify.configure(awsconfig);

class ModuleSubscribeNowButtonContent extends Component {

    state = {
        loading: false,
    }

    constructor() {
        super();
        this.handlePay = this.handlePay.bind(this)
    }

    async handlePay() {

        this.setState({ loading: true })
        const body = {
            successUrl: this.props.successUrl,
            cancelUrl: this.props.cancelUrl,
            customerId: this.props.customerId,
        }
        const response = await API.post(this.props.apiName, this.props.apiEndpoint, { body });
        if(!response || !response.session) {
            alert('Oops! an error occured! Please contact technical support');
            return false;
        }
        this.props.stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: response.session.id
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
        this.setState({ loading: false })
        
    }

    render() {
        const { loading } = this.state;
        const stripePromise = loadStripe(this.props.stripePublicKey);

        return (
            <div className="block w-full pl-4 pr-4" stripe={stripePromise}>
                <button className={`block cursor-pointer inline-block ${this.props.className} bg-gradient-to-r w-full sm:w-2/3 lg:w-full from-pgAqua-500 to-pgGreen-500 rounded rounded-full p-1 text-pgBlue-500`} onClick={this.handlePay} disabled={loading}>{
                    loading ?
                        <span>Loading...</span>
                        :
                        <span>{this.props.buttonText}</span>
                }</button>
                
            </div>
        );
    }
}

ModuleSubscribeNowButtonContent.propTypes = {
    apiName: PropTypes.string.isRequired,
    apiEndpoint: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,

    successUrl: PropTypes.string.isRequired,
    cancelUrl: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
};

export default injectStripe(ModuleSubscribeNowButtonContent);