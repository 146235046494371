/* 
Partial for Appraisal Thumbnail Blocl
Used to Generate the KPI's on the Appraisal View
*/

import React from "react";
import { Link } from 'react-router-dom';
import TemplateAppraisalsKPIBlock from "./template-appraisals-kpi-block";
import score0 from '../../src/score-0.svg';

const TemplateNewAppraisalsBlock = ({type, archive, preferenceMapping, userPrefs}) => {

  if(archive) {
    return('');
  }

  return (
    <div className="appraisal-item appraisal-new-item border hover:border-gray-400">
      <Link to={`/${type}/createAppraisal`}>
        
        <span className="bg-white items-center justify-center align-middle flex w-100 text-center pt-10 box-content h-40 new-block-image">
          
        </span>
        <div className="p-4">
          <p className="block flex h-16 text-pgNavy-500">
            <b className="w-4/6 text-sm">Create New Appraisal</b>
            <img src={score0} className="w-2/6 -mt-2" alt="" />
          </p>
        </div>
        <>
          {preferenceMapping.map((item, index) => (
               TemplateAppraisalsKPIBlock(false, item, true, userPrefs)
          ))}
        </>
      </Link>
    </div>
  );
    
}

export default TemplateNewAppraisalsBlock;
