/* 
Partial for Appraisal Suggested Offer Block
*/

import React, { useState, useEffect } from "react";
import moduleFormatCurrency from "../modules/module-format-currency";
import moduleFormatPercentage from "../modules/module-format-percentage";
import moduleClenseData from "../modules/module-clense-data";
import moduleCalculateSDLT from "../modules/module-stamp-duty";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ReactTooltip from 'react-tooltip';


const TemplateAppraisalSuggestedFlipOffer = ({showSuggestedOffer, appraisalData, toolTips, onChangeHandler}) => {

  let [suggestedOffer, setSuggestedOffer]     = useState('£0');
  let [moneyOut, setMoneyOut]     = useState('50%');

  useEffect(() => {
      ReactTooltip.rebuild();
  });

  //////////////////////////////////////////
  // Calc Suggested
  //////////////////////////////////////////
  const calcSuggested = (event) => {
    const {value} = event.currentTarget;

    // reduce price by 1k until it stacks
    for(let suggestedPrice = moduleClenseData(appraisalData.price); suggestedPrice > 0; suggestedPrice -= 500) {

      let initialLoanValue = moduleClenseData(suggestedPrice) / 100 * parseInt(appraisalData.initialLTV);
      let initialDeposit = moduleClenseData(suggestedPrice) - initialLoanValue;

      let initialAnnualInterest = 0;

      if('annually' === appraisalData.initialInterestPeriod) {
        initialAnnualInterest = (moduleClenseData(initialLoanValue) / 100 * moduleClenseData(appraisalData.initialMortgageRate) / 12) * moduleClenseData(appraisalData.initialTerm);
      } else {
        initialAnnualInterest = (moduleClenseData(initialLoanValue) / 100 * moduleClenseData(appraisalData.initialMortgageRate)) * moduleClenseData(appraisalData.initialTerm);     
      }

      let stampDuty = moduleCalculateSDLT(suggestedPrice);

      let initialCapitalInvested = moduleClenseData(initialDeposit) + moduleClenseData(appraisalData.legalsAndSurveys) + moduleClenseData(stampDuty) + moduleClenseData(appraisalData.refurb) + moduleClenseData(appraisalData.otherFees) + moduleClenseData(initialAnnualInterest);
    

      let profit = 
        moduleClenseData(appraisalData.salePrice) 
        - moduleClenseData(suggestedPrice) 
        - moduleClenseData(appraisalData.eaSellingCosts) 
        - moduleClenseData(appraisalData.saleERC) 
        - moduleClenseData(initialAnnualInterest) 
        - moduleClenseData(appraisalData.otherFees) 
        - moduleClenseData(stampDuty)
        - moduleClenseData(appraisalData.legalsAndSurveys)
        - moduleClenseData(appraisalData.refurb);
      let ROI = moduleClenseData(profit) / moduleClenseData(initialCapitalInvested) * 100;

      if(ROI >= value) {
        setSuggestedOffer(moduleFormatCurrency(suggestedPrice));
        setMoneyOut(moduleFormatPercentage(value));
        break;
      }

    }

    
  };

    return(

    <SlideDown>

      { showSuggestedOffer ? 
      <div>
      <label className="items-center block pt-3 flex">
        <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">% ROI:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.ROI}></em></p>
        <input
        type="text"
        className="shadow appearance-none border rounded w-2/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:border-orange-500"
        name="moneyOut"
        placeholder={moneyOut}
        id="moneyOut"
        value = {appraisalData.moneyOut}
        onChange= {(event) => calcSuggested(event)}
        />
      </label>


      <label className="items-center block pt-3 flex">
        <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Suggested Offer:</p>
        <span className="border rounded font-bold w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{suggestedOffer}</span>
      </label>

      <ReactTooltip effect="solid" clickable={true} backgroundColor="#f65312" textColor="#fff" multiline={true} />
      </div>
      : '' }

    </SlideDown>
    
  )


}

export default TemplateAppraisalSuggestedFlipOffer;
