/* 
FSC FOR modulePostcodeBTLRentLookup
Method used to perform a lookup on the given postcode to return an price per sqft value
*/

import lookups from '../../src/lookup-data.js';

const modulePostcodeBTLRentLookup = (appraisalData, setAppraisalData) => {

    let avgPostcodeLookup = lookups[1];
    let postcodeLookup = false;

    if(appraisalData.rooms <= 5) {
    	const index = (parseInt(appraisalData.rooms) + 1);
    	postcodeLookup = lookups[index];
    }

    appraisalData.postcode = appraisalData.postcode.replace(' ','').toUpperCase();

    let outcodeTests = [appraisalData.postcode.substring(0,2), appraisalData.postcode.substring(0,3), appraisalData.postcode.substring(0,4)];
    let grossRent = 800;

    outcodeTests.forEach(function(element) {

	    if(avgPostcodeLookup[element]) {
	        grossRent = avgPostcodeLookup[element];
	    }

      if(postcodeLookup) {
      	if(postcodeLookup[element]) {
          grossRent = postcodeLookup[element];
      	}
      }

		  const updates = {};
      appraisalData.grossRent = '£' + grossRent;
      updates['grossRent'] = '£' + grossRent;
      setAppraisalData({...appraisalData, ...updates});

    });

 };

 export default modulePostcodeBTLRentLookup;