/* 
Setting for Percentage Fields
*/

const settingsDefaultUserAppraisals = {
  "btl" : {
    "-M_2F49XSnw5wXPTsdgF" : {
      "address" : "Field Terrace Road, Newmarket",
      "annualGrowth" : 7800,
      "annualManagementCost" : 1378.272,
      "annualRepairsAndMaintenance" : 500,
      "annualTentantFindFees" : "",
      "archive" : "0",
      "areaPrice" : 208719.5,
      "askingPrice" : "£210,000",
      "avgPricePerSqft" : "£277",
      "billsPerRoom" : 60,
      "combinedRoce" : 17.354359461714246,
      "created" : 1620329262446,
      "flagBMV" : 0,
      "flagHot" : 1,
      "flagNoChain" : 0,
      "grossAnnualIncome" : 13782.720000000001,
      "grossRent" : "£1,172",
      "grossYield" : 7.0680615384615395,
      "growthRate" : 4,
      "hmoLicence" : 0,
      "initialAnnualInterest" : 4753.125,
      "initialCapitalInvested" : 86153.125,
      "initialDeposit" : 48750,
      "initialInterestPeriod" : "annually",
      "initialLTV" : 75,
      "initialLoanValue" : 146250,
      "initialMortgageRate" : "4.25%",
      "initialTerm" : 12,
      "legalsAndSurveys" : 1800,
      "managementFee" : 10,
      "monthlyManagementCost" : 114.856,
      "name" : "Example Deal #4",
      "netAnnualIncome" : 7151.323,
      "netMonthlyIncome" : 595.9435833333333,
      "netYield" : 3.667345128205128,
      "notes" : "Light Refurb and Dressing",
      "occupancyPercentage" : "98",
      "offer1" : "185000",
      "offer2" : "",
      "offer3" : "",
      "offerAccepted" : "",
      "offerLostSoldPrice" : "",
      "offerStatus" : "offered",
      "otherFees" : 0,
      "overallScore" : 4.8,
      "percentageRecycled" : 0,
      "postcode" : "CB8",
      "price" : "£195,000",
      "priceDifference" : 13719.5,
      "pricePerSqft" : 258.792302587923,
      "refinanceAnnualInterest" : 4753.125,
      "refinanceCapitalInvested" : 86153.125,
      "refinanceDeposit" : 48750,
      "refinanceERC" : 0,
      "refinanceEquityReleased" : 0,
      "refinanceLTV" : 75,
      "refinanceLoanValue" : 146250,
      "refinanceMortgageRate" : "4.25%",
      "refinancePrice" : "£235,000",
      "refinanceUplift" : 0,
      "refurb" : "£25,000",
      "rentAndGrowth" : 14951.323,
      "roce" : 8.30071224926548,
      "rooms" : 3,
      "sqft" : "753.5",
      "sqftDifference" : 18.207697412076982,
      "sqm" : "70.0",
      "stampDuty" : "£5,850",
      "thumbnail" : "https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/rQA1njsgnbac2WNWPYv9YdNgV732/1620486058946.jpg",
      "type" : "Semi detached",
      "url" : "https://www.rightmove.co.uk/properties/90520834#/"
    },
    "-M_2G73iJZZHG75lIlRd" : {
      "address" : "Park Avenue, Newmarket",
      "annualGrowth" : 7080,
      "annualManagementCost" : 1350.1440000000002,
      "annualRepairsAndMaintenance" : 500,
      "annualTentantFindFees" : "",
      "archive" : "0",
      "areaPrice" : 178886.59999999998,
      "askingPrice" : "£187,500",
      "avgPricePerSqft" : "£277",
      "billsPerRoom" : 60,
      "combinedRoce" : 18.94692883289379,
      "created" : 1620329262446,
      "flagBMV" : 0,
      "flagHot" : 0,
      "flagNoChain" : 0,
      "grossAnnualIncome" : 13501.440000000002,
      "grossRent" : "£1,172",
      "grossYield" : 7.627932203389831,
      "growthRate" : 4,
      "hmoLicence" : 0,
      "initialAnnualInterest" : 4380.75,
      "initialCapitalInvested" : 75740.75,
      "initialDeposit" : 44250,
      "initialInterestPeriod" : "annually",
      "initialLTV" : 75,
      "initialLoanValue" : 132750,
      "initialMortgageRate" : "4.30%",
      "initialTerm" : 12,
      "legalsAndSurveys" : 1800,
      "managementFee" : 10,
      "monthlyManagementCost" : 112.51200000000001,
      "name" : "Example Deal #5",
      "netAnnualIncome" : 7270.546000000002,
      "netMonthlyIncome" : 605.8788333333335,
      "netYield" : 4.107653107344634,
      "notes" : "",
      "occupancyPercentage" : 96,
      "offer1" : "",
      "offer2" : "",
      "offer3" : "",
      "offerAccepted" : "177000",
      "offerLostSoldPrice" : "£185000",
      "offerStatus" : "lost",
      "otherFees" : 0,
      "overallScore" : "4.0",
      "percentageRecycled" : 0,
      "postcode" : "CB8",
      "price" : "£177,000",
      "priceDifference" : 1886.5999999999767,
      "pricePerSqft" : 274.07866212449676,
      "refinanceAnnualInterest" : 4380.75,
      "refinanceCapitalInvested" : 75740.75,
      "refinanceDeposit" : 44250,
      "refinanceERC" : 0,
      "refinanceEquityReleased" : 0,
      "refinanceLTV" : 75,
      "refinanceLoanValue" : 132750,
      "refinanceMortgageRate" : "4.30%",
      "refinancePrice" : "£177,000",
      "refinanceUplift" : 0,
      "refurb" : "£20,000",
      "rentAndGrowth" : 14350.546000000002,
      "roce" : 9.599252714027788,
      "rooms" : 4,
      "sqft" : "645.8",
      "sqftDifference" : 2.921337875503241,
      "sqm" : "60.0",
      "stampDuty" : "£5,310",
      "thumbnail" : "https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/rQA1njsgnbac2WNWPYv9YdNgV732/1620486072021.jpg",
      "type" : "Terraced House",
      "url" : "https://www.rightmove.co.uk/properties/90876991#/"
    },
    "-M_2GcSYtgAQPR-tFW73" : {
      "address" : "Charles Close, Newmarket",
      "annualGrowth" : 10000,
      "annualManagementCost" : 1382.4,
      "annualRepairsAndMaintenance" : 500,
      "annualTentantFindFees" : "",
      "archive" : "0",
      "areaPrice" : 208719.5,
      "askingPrice" : "£170,000",
      "avgPricePerSqft" : "£277",
      "billsPerRoom" : 60,
      "combinedRoce" : 53.75594795539034,
      "created" : 1620329262446,
      "flagBMV" : 1,
      "flagHot" : 0,
      "flagNoChain" : 0,
      "grossAnnualIncome" : 13824,
      "grossRent" : "£1,200",
      "grossYield" : 5.529599999999999,
      "growthRate" : 4,
      "hmoLicence" : 0,
      "initialAnnualInterest" : 8100,
      "initialCapitalInvested" : 101900,
      "initialDeposit" : 37500,
      "initialInterestPeriod" : "monthly",
      "initialLTV" : 75,
      "initialLoanValue" : 112500,
      "initialMortgageRate" : "1.20%",
      "initialTerm" : "6",
      "legalsAndSurveys" : 1800,
      "managementFee" : 10,
      "monthlyManagementCost" : 115.2,
      "name" : "Example Deal #6",
      "netAnnualIncome" : 4460.35,
      "netMonthlyIncome" : 371.6958333333334,
      "netYield" : 1.78414,
      "notes" : "Extension and renovation - Bridging Loan",
      "occupancyPercentage" : 96,
      "offer1" : "",
      "offer2" : "",
      "offer3" : "",
      "offerAccepted" : "",
      "offerLostSoldPrice" : "",
      "offerStatus" : "appraisal",
      "otherFees" : 0,
      "overallScore" : 6.4,
      "percentageRecycled" : 73.60157016683023,
      "postcode" : "CB8",
      "price" : "£150,000",
      "priceDifference" : 58719.5,
      "pricePerSqft" : 199.07100199071002,
      "refinanceAnnualInterest" : 7481.25,
      "refinanceCapitalInvested" : 26900,
      "refinanceDeposit" : 62500,
      "refinanceERC" : 0,
      "refinanceEquityReleased" : 75000,
      "refinanceLTV" : 75,
      "refinanceLoanValue" : 187500,
      "refinanceMortgageRate" : "4.99",
      "refinancePrice" : "£250,000",
      "refinanceUplift" : 0,
      "refurb" : "£50,000",
      "rentAndGrowth" : 14460.35,
      "roce" : 16.581226765799258,
      "rooms" : "5",
      "sqft" : "753.5",
      "sqftDifference" : 77.92899800928998,
      "sqm" : "70.0",
      "stampDuty" : "£4,500",
      "thumbnail" : "https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/rQA1njsgnbac2WNWPYv9YdNgV732/1620486085771.jpg",
      "type" : "3 Bed Semi",
      "url" : "https://www.rightmove.co.uk/properties/79974498#/"
    }
  },
  "flips" : {
    "-MX7X7kNRIV6NIVZWGgM" : {
      "ROI" : 44.92392950197726,
      "address" : "10 Morris Lane, NR69NH",
      "archive" : "0",
      "areaPrice" : 271252.80000000005,
      "askingPrice" : "£250,000.00",
      "avgPricePerSqft" : "£252",
      "created" : 1617198280399,
      "eaSellingCosts" : 7000,
      "eaSellingFee" : 2,
      "flagBMV" : 0,
      "flagHot" : 1,
      "flagNoChain" : 1,
      "initialAnnualInterest" : 3257.3362500000003,
      "initialCapitalInvested" : 124013.33625,
      "initialDeposit" : 54425,
      "initialInterestPeriod" : "annually",
      "initialLTV" : 75,
      "initialLoanValue" : 163275,
      "initialMortgageRate" : "4.99",
      "initialTerm" : 6,
      "legalsAndSurveys" : 1800,
      "name" : "Example Deal #7",
      "notes" : "Full Refurb, New Kitchen",
      "offer1" : "",
      "offer2" : "",
      "offer3" : "",
      "offerAccepted" : "",
      "offerLostSoldPrice" : "",
      "offerStatus" : "appraisal",
      "otherFees" : 0,
      "overallScore" : "8.0",
      "postcode" : "nr79nh",
      "price" : "£217,700",
      "priceDifference" : 53552.80000000005,
      "pricePerSqft" : 202.24823485693048,
      "profit" : 55711.66375000001,
      "refurb" : "£58,000",
      "rooms" : 4,
      "saleERC" : "0000",
      "salePrice" : "£350,000",
      "saleUplift" : 132300,
      "sqft" : "1076.4",
      "sqftDifference" : 49.75176514306952,
      "sqm" : "100.0",
      "stampDuty" : "£6,531",
      "thumbnail" : "https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/rQA1njsgnbac2WNWPYv9YdNgV732/1620486016085.jpg",
      "type" : "Semi Detached",
      "url" : ""
    },
    "-M_2Hxz04jFWXaK6rj_V" : {
      "ROI" : 42.84464688125164,
      "address" : "Newmarket Close",
      "archive" : "0",
      "areaPrice" : 232497,
      "askingPrice" : "£220,000",
      "avgPricePerSqft" : "£270",
      "created" : 1620329262446,
      "eaSellingCosts" : 5880,
      "eaSellingFee" : 2,
      "flagBMV" : 0,
      "flagHot" : 1,
      "flagNoChain" : 0,
      "initialAnnualInterest" : 2917.6875,
      "initialCapitalInvested" : 99317.6875,
      "initialDeposit" : 48750,
      "initialInterestPeriod" : "annually",
      "initialLTV" : 75,
      "initialLoanValue" : 146250,
      "initialMortgageRate" : "4.99",
      "initialTerm" : 6,
      "legalsAndSurveys" : 1800,
      "name" : "Example Deal #8",
      "notes" : "Full Rennovation",
      "offer1" : "170000",
      "offer2" : "175000",
      "offer3" : "",
      "offerAccepted" : "",
      "offerLostSoldPrice" : "",
      "offerStatus" : "offered",
      "otherFees" : 0,
      "overallScore" : 7.2,
      "postcode" : "NR2",
      "price" : "£195,000",
      "priceDifference" : 37497,
      "pricePerSqft" : 226.45453489722448,
      "profit" : 42552.3125,
      "refurb" : "£40,000",
      "rooms" : 4,
      "saleERC" : 0,
      "salePrice" : "£294,000",
      "saleUplift" : 99000,
      "sqft" : "861.1",
      "sqftDifference" : 43.54546510277552,
      "sqm" : "80.0",
      "stampDuty" : "£5,850",
      "thumbnail" : "https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/rQA1njsgnbac2WNWPYv9YdNgV732/1620486095262.jpg",
      "type" : "Detached Bungalow",
      "url" : ""
    },
    "-M_2J5iG346xUqKZXwuC" : {
      "ROI" : 59.82496766315841,
      "address" : "Dalbier Close",
      "archive" : "0",
      "areaPrice" : 232497,
      "askingPrice" : "£180,000",
      "avgPricePerSqft" : "£270",
      "created" : 1620329262446,
      "eaSellingCosts" : 5560,
      "eaSellingFee" : 2,
      "flagBMV" : 1,
      "flagHot" : 1,
      "flagNoChain" : 1,
      "initialAnnualInterest" : 2468.8125,
      "initialCapitalInvested" : 91468.8125,
      "initialDeposit" : 41250,
      "initialInterestPeriod" : "annually",
      "initialLTV" : 75,
      "initialLoanValue" : 123750,
      "initialMortgageRate" : "4.99",
      "initialTerm" : 6,
      "legalsAndSurveys" : 1800,
      "name" : "Example Deal #9",
      "notes" : "Full refurb",
      "offer1" : "",
      "offer2" : "",
      "offer3" : "",
      "offerAccepted" : "",
      "offerLostSoldPrice" : "",
      "offerStatus" : "appraisal",
      "otherFees" : "£1000.00",
      "overallScore" : 3.2,
      "postcode" : "NR2",
      "price" : "£165,000",
      "priceDifference" : 67497,
      "pricePerSqft" : 191.61537568226686,
      "profit" : 54721.1875,
      "refurb" : "£40,000",
      "rooms" : 4,
      "saleERC" : "2500",
      "salePrice" : "£233,000",
      "saleUplift" : 113000,
      "sqft" : "861.1",
      "sqftDifference" : 78.38462431773314,
      "sqm" : "80.0",
      "stampDuty" : "£4,950",
      "thumbnail" : "https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/rQA1njsgnbac2WNWPYv9YdNgV732/1620486103136.jpg",
      "type" : "End Terrace",
      "url" : ""
    }
  },
  "hmo" : {
    "-M_2AFByhSdYmk1EdZqM" : {
      "address" : "Kelmarsh Close, Openshaw, Manchester",
      "annualBills" : 3600,
      "annualGrowth" : 9600,
      "annualManagementCost" : 2268,
      "annualRepairsAndMaintenance" : 500,
      "annualTentantFindFees" : "£500.00",
      "archive" : "0",
      "areaPrice" : 176321.6,
      "askingPrice" : "£170,000",
      "avgPricePerSqft" : "£182",
      "avgRoomRent" : 420,
      "billsPerRoom" : 60,
      "combinedRoce" : 44.693696178480586,
      "created" : 1620329262446,
      "flagBMV" : 1,
      "flagHot" : 0,
      "flagNoChain" : 1,
      "grossAnnualIncome" : 22680,
      "grossRent" : "£2,100",
      "grossYield" : 9.45,
      "growthRate" : 4,
      "hmoLicence" : 0,
      "initialAnnualInterest" : 4488.75,
      "initialCapitalInvested" : 108288.75,
      "initialDeposit" : 37500,
      "initialInterestPeriod" : "annually",
      "initialLTV" : 75,
      "initialLoanValue" : 112500,
      "initialMortgageRate" : "4.99",
      "initialTerm" : 12,
      "legalsAndSurveys" : 1800,
      "managementFee" : 10,
      "monthlyBills" : 300,
      "monthlyManagementCost" : 189,
      "name" : "Example Deal #1",
      "netAnnualIncome" : 8630,
      "netMonthlyIncome" : 719.1666666666666,
      "netYield" : 3.5958333333333337,
      "notes" : "Extension and reconfiguration into 5 bed HMO",
      "occupancyPercentage" : 90,
      "offer1" : "",
      "offer2" : "",
      "offer3" : "",
      "offerAccepted" : "",
      "offerLostSoldPrice" : "",
      "offerStatus" : "appraisal",
      "otherFees" : 0,
      "overallScore" : 6.4,
      "percentageRecycled" : 62.33334487654535,
      "postcode" : "SK5",
      "price" : "£150,000",
      "priceDifference" : 26321.600000000006,
      "pricePerSqft" : 154.83071841453346,
      "refinanceAnnualInterest" : 7182,
      "refinanceCapitalInvested" : 40788.75,
      "refinanceDeposit" : 60000,
      "refinanceERC" : 0,
      "refinanceEquityReleased" : 67500,
      "refinanceLTV" : 75,
      "refinanceLoanValue" : 180000,
      "refinanceMortgageRate" : "4.99",
      "refinancePrice" : "£240,000",
      "refinanceUplift" : 0,
      "refurb" : "£60,000",
      "rentAndGrowth" : 18230,
      "roce" : 21.15779473506788,
      "rooms" : "5",
      "sqft" : "968.8",
      "sqftDifference" : 27.169281585466535,
      "sqm" : "90.0",
      "stampDuty" : "£4,500",
      "thumbnail" : "https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/rQA1njsgnbac2WNWPYv9YdNgV732/1620486029137.jpg",
      "type" : "3 Bedroom Semi",
      "url" : "https://www.rightmove.co.uk/properties/106448132#/",
      "vatOnManagement" : "no"
    },
    "-M_2Bac56v8TxsgDH6R-" : {
      "address" : "Rathbourne Avenue, Manchester, M9",
      "annualBills" : 2880,
      "annualGrowth" : 8460,
      "annualManagementCost" : 2643.648,
      "annualRepairsAndMaintenance" : 500,
      "annualTentantFindFees" : "",
      "archive" : "0",
      "areaPrice" : 278476.8,
      "askingPrice" : "£170,000",
      "avgPricePerSqft" : "£294",
      "avgRoomRent" : 562,
      "billsPerRoom" : 60,
      "combinedRoce" : 46.50012079490005,
      "created" : 1620329262446,
      "flagBMV" : 1,
      "flagHot" : 1,
      "flagNoChain" : 0,
      "grossAnnualIncome" : 26436.48,
      "grossRent" : "£2,248",
      "grossYield" : 12.499517730496454,
      "growthRate" : 4,
      "hmoLicence" : 0,
      "initialAnnualInterest" : 4260.9375,
      "initialCapitalInvested" : 93480.9375,
      "initialDeposit" : 37875,
      "initialInterestPeriod" : "annually",
      "initialLTV" : 75,
      "initialLoanValue" : 113625,
      "initialMortgageRate" : "4.75%",
      "initialTerm" : 12,
      "legalsAndSurveys" : 1800,
      "managementFee" : 10,
      "monthlyBills" : 240,
      "monthlyManagementCost" : 220.304,
      "name" : "Example Deal #2",
      "netAnnualIncome" : 14083.694499999998,
      "netMonthlyIncome" : 1173.6412083333332,
      "netYield" : 6.658957210401891,
      "notes" : "Light refurb and reconfigure to get 4th room",
      "occupancyPercentage" : "98",
      "offer1" : "",
      "offer2" : "",
      "offer3" : "",
      "offerAccepted" : "151500",
      "offerLostSoldPrice" : "",
      "offerStatus" : "accepted",
      "otherFees" : 0,
      "overallScore" : 7.2,
      "percentageRecycled" : 48.13815650918135,
      "postcode" : "m1",
      "price" : "£151,500",
      "priceDifference" : 126976.79999999999,
      "pricePerSqft" : 159.94510135135135,
      "refinanceAnnualInterest" : 6329.137500000001,
      "refinanceCapitalInvested" : 48480.9375,
      "refinanceDeposit" : 52875,
      "refinanceERC" : 0,
      "refinanceEquityReleased" : 45000,
      "refinanceLTV" : 75,
      "refinanceLoanValue" : 158625,
      "refinanceMortgageRate" : "4.99",
      "refinancePrice" : "£211,500",
      "refinanceUplift" : 0,
      "refurb" : "£45,000",
      "rentAndGrowth" : 22543.694499999998,
      "roce" : 29.049963194296723,
      "rooms" : 4,
      "sqft" : "947.2",
      "sqftDifference" : 134.05489864864865,
      "sqm" : "88.0",
      "stampDuty" : "£4,545",
      "thumbnail" : "https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/rQA1njsgnbac2WNWPYv9YdNgV732/1620486038408.jpg",
      "type" : "3 Bed Semi",
      "url" : "https://www.rightmove.co.uk/properties/105525800#/",
      "vatOnManagement" : "no"
    },
    "-M_2EKnXvjY6MZ4bJ8IN" : {
      "address" : "Dernford Avenue, Manchester",
      "annualBills" : 3600,
      "annualGrowth" : 11600,
      "annualManagementCost" : 3237.1200000000003,
      "annualRepairsAndMaintenance" : 500,
      "annualTentantFindFees" : "",
      "archive" : "0",
      "areaPrice" : 316461.60000000003,
      "askingPrice" : "£210,000",
      "avgPricePerSqft" : "£294",
      "avgRoomRent" : 562,
      "billsPerRoom" : 60,
      "combinedRoce" : 42.55996163551456,
      "created" : 1620329262446,
      "flagBMV" : 1,
      "flagHot" : 0,
      "flagNoChain" : 1,
      "grossAnnualIncome" : 32371.200000000004,
      "grossRent" : "£2,810",
      "grossYield" : 11.16248275862069,
      "growthRate" : 4,
      "hmoLicence" : 0,
      "initialAnnualInterest" : 5685.75,
      "initialCapitalInvested" : 140685.75,
      "initialDeposit" : 47500,
      "initialInterestPeriod" : "annually",
      "initialLTV" : 75,
      "initialLoanValue" : 142500,
      "initialMortgageRate" : "4.99",
      "initialTerm" : 12,
      "legalsAndSurveys" : 1800,
      "managementFee" : 10,
      "monthlyBills" : 300,
      "monthlyManagementCost" : 269.76000000000005,
      "name" : "Example Deal #3",
      "netAnnualIncome" : 16355.830000000005,
      "netMonthlyIncome" : 1362.9858333333339,
      "netYield" : 5.639941379310347,
      "notes" : "Single Story Extension",
      "occupancyPercentage" : "96",
      "offer1" : "185000",
      "offer2" : "187000",
      "offer3" : "",
      "offerAccepted" : "",
      "offerLostSoldPrice" : "",
      "offerStatus" : "offered",
      "otherFees" : 0,
      "overallScore" : 7.2,
      "percentageRecycled" : 53.310303282315374,
      "postcode" : "m1",
      "price" : "£190,000",
      "priceDifference" : 126461.60000000003,
      "pricePerSqft" : 176.51430694908953,
      "refinanceAnnualInterest" : 8678.25,
      "refinanceCapitalInvested" : 65685.75,
      "refinanceDeposit" : 72500,
      "refinanceERC" : 0,
      "refinanceEquityReleased" : 75000,
      "refinanceLTV" : 75,
      "refinanceLoanValue" : 217500,
      "refinanceMortgageRate" : "4.99",
      "refinancePrice" : "£290,000",
      "refinanceUplift" : 0,
      "refurb" : "£80,000",
      "rentAndGrowth" : 27955.830000000005,
      "roce" : 24.900119127816925,
      "rooms" : "5",
      "sqft" : "1076.4",
      "sqftDifference" : 117.48569305091047,
      "sqm" : "100.0",
      "stampDuty" : "£5,700",
      "thumbnail" : "https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/rQA1njsgnbac2WNWPYv9YdNgV732/1620486049158.jpg",
      "type" : "3 bed Semi",
      "url" : "https://www.rightmove.co.uk/properties/79419975#/",
      "vatOnManagement" : "no"
    }
  }
}

 export default settingsDefaultUserAppraisals;