/* 
FSC FOR moduleUseCredit
Method used to define usage of users
userID: user ID
*/
import firebase from "firebase";

const ModuleUseCredit = (userID) => {

	// get existing user usage
	let userInfo = firebase.database().ref('/users/' + userID);
    userInfo.once('value').then(function(snapshot) {
    	let userDetails = snapshot.val();

      // limit flag ensures its only triggered on accounts with hard limit
    	if(userDetails.usage && userDetails.usage.credits && userDetails.usage.limit) {

        // Reduce token count
      	let newValue = userDetails.usage.credits-1;
        
        // prevent negative values
      	if(newValue <= 0) {
      		newValue = 0;
      	}

        // update user object in FB
    	  let usageRef = firebase.database().ref('/users/' + userID + '/usage');
        let updates = {
          credits: newValue
        };
        usageRef.update(updates);
        usageRef.off();

      }

    });

 };

export default ModuleUseCredit;
