import React, {useState} from "react";
import { Link } from 'react-router-dom';
import { signInWithGoogle } from "../firebase";
import { auth } from "../firebase";
import SvgHand from '../src/hand.svg';
import SvgGoogle from '../src/google.svg';


const SignIn = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler = (event, email, password) => {
        event.preventDefault();

        auth.signInWithEmailAndPassword(email, password).catch(error => {
        setError("Error signing in with password and email!");
          console.error("Error signing in with password and email", error);
        });
    };
      
      const onChangeHandler = (event) => {
          const {name, value} = event.currentTarget;
        
          if(name === 'userEmail') {
              setEmail(value);
          }
          else if(name === 'userPassword'){
            setPassword(value);
          }
      };
   

  return (
    <div className="w-full md:w-2/3 lg:w-2/3 xl:w-5/12 p-10 lg:p-20">

      <h1 className="text-4xl font-bold mb-2 text-white">Sign in</h1>
      <p className="mb-4 text-white">Need a Property Gambit account? <Link to="signUp" className="underline ml-1">
             Sign Up
          </Link></p>


      <button className="bg-white rounded rounded-lg p-5 mt-10 md:text-lg items-center text-pgBlue-500 flex whitespace-nowrap hover:text-white hover:bg-pgNavy-500" onClick={(e) => {
        e.preventDefault();
        signInWithGoogle();
      }} >
        <img src={SvgGoogle} className="pr-5" alt="" /> Sign in with Google
      </button>

      <p className="mt-10 mb-10 flex w-full items-center"><span className="border-t w-2/5"></span> <span className="w-1/5 text-center text-white">OR</span> <span className="border-t w-2/5"></span></p>

      <div>
        {error !== null && <div className = "py-4 bg-red-600 w-full text-white text-center mb-3">{error}</div>}
        <form className="">

          <label htmlFor="userEmail" className="block text-white">
            Email Address:
          </label>
          <input
            type="email"
            className="text-pgBlue-500 my-1 mb-4 p-3 pl-5 pr-5 w-full text-lg"
            name="userEmail"
            value = {email}
            placeholder="Email"
            id="userEmail"
            onChange = {(event) => onChangeHandler(event)}
          />

          <label htmlFor="userPassword" className="block text-white">
            Password:
          </label>
          <input
            type="password"
            className="text-pgBlue-500 mt-1 mb-3 p-3 pl-5 pr-5 w-full text-lg"
            name="userPassword"
            value = {password}
            placeholder="Password"
            id="userPassword"
            onChange = {(event) => onChangeHandler(event)}
          />

          <Link to="passwordReset" className="text-white text-right block mb-10 hover:underline">
            Forgot Password?
          </Link>

          <div className="flex justify-between">

            <button className="bg-pgGreen-500 hover:text-white hover:bg-pgNavy-500 rounded rounded-full pl-10 pr-10 pt-2 md:text-lg pb-2 text-pgNavy-500" onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
              Sign in
            </button>


          </div>
        </form>
        
        <p className="text-center text-white mt-20 md:absolute md:top-0 md:right-0 md:m-10">
          Don't have an account yet?
          <Link to="signUp" className="rounded rounded-full block mt-3 md:mt-0 md:inline ml-5 pl-5 pr-5 pt-2 text-lg pb-2 bg-pgAqua-500 text-pgBlue-500 hover:text-white hover:bg-pgNavy-500">
             Start Free Trial
          </Link>
        </p>
      </div>

      <img src={SvgHand} className="hidden md:block fixed bottom-0 right-0 max-w-screen-lg max-h-2/3 w-1/2" alt="Keys" />
    </div>
  );
};

export default SignIn;
