/* 
FSC FOR scorecardCalculation
Method used to calculate scorecard metrics
*/

import moduleClenseData from "./module-clense-data";

function calulateDataPointAsPercentage(appraisalData, dataPoint, type) {
  
  const averages = {
    hmo : {
      roce : 15,
      grossYield : 9,
      percentageRecycled : 35,
      pricePerRoom : 45000,
    },
    btl : {
      roce : 7.6,
      grossYield : 8,
      netYield : 5,
      percentageRecycled : 35,
    },
    flip : {
      percentageSaleUplift : 40,
      ROI : 25,
    }
  }

  const average = averages[type][dataPoint];
  const max = average * 2; // 0 = base, avg = middle, x2 = max;

  let percentage = (100 * moduleClenseData(appraisalData[dataPoint])) / max;
  if(dataPoint === 'pricePerRoom') {
    percentage = 100 - ((100 * moduleClenseData(appraisalData[dataPoint])) / max);
  }
  return percentage.toFixed(0);

}

function processScore (score) {
  let value = (score * 0.8) / 8;
  let scorecardValue = Math.round(value / 0.8)*0.8;

  if(scorecardValue > 9.4) scorecardValue = 9.4;
  if(scorecardValue < 0.8) scorecardValue = 0.8;

  return scorecardValue;
}

const moduleScorecardCalculation = (type, appraisalData, label1, dataPoint1, label2, dataPoint2, label3, dataPoint3, label4, dataPoint4) => {

    if((!appraisalData.roce) && type !== 'flip') {
      return false;
    }

    if(!appraisalData.ROI && type === 'flip') {
      return false;
    }

    let results = {
        bar1 : false,
        bar2 : false,
        bar3 : false,
        bar4 : false,
        overallScore : false
    };

    results.bar1 = processScore(calulateDataPointAsPercentage(appraisalData, dataPoint1, type)).toFixed(1);
    results.bar2 = processScore(calulateDataPointAsPercentage(appraisalData, dataPoint2, type)).toFixed(1);

    if(type === 'hmo') {
      results.bar3 = processScore(calulateDataPointAsPercentage(appraisalData, dataPoint3, type)).toFixed(1);
      results.bar4 = processScore(calulateDataPointAsPercentage(appraisalData, dataPoint4, type)).toFixed(1);
      let overall = (parseInt(results.bar1) + parseInt(results.bar2) + parseInt(results.bar3) + parseInt(results.bar4)) / 4;
      results.overallScore = processScore(overall * 10).toFixed(1);
    }
    if(type === 'btl') {
      // 2x of bar 1 (ROCE) as we want to increase weighting of this metric
      results.bar3 = processScore(calulateDataPointAsPercentage(appraisalData, dataPoint3, type)).toFixed(1);
      results.bar4 = processScore(calulateDataPointAsPercentage(appraisalData, dataPoint4, type)).toFixed(1);
      let overall = (parseInt(results.bar1) + parseInt(results.bar1) + parseInt(results.bar2) + parseInt(results.bar3) + parseInt(results.bar4)) / 5;
      results.overallScore = processScore(overall * 10).toFixed(1);
    }
    if(type === 'flip') {
      // 2x of bar 1 (profit/roi) as we want to increase weighting of this metric
      let overall = (parseInt(results.bar1) + parseInt(results.bar1) + parseInt(results.bar2)) / 3;
      results.overallScore = processScore(overall * 10).toFixed(1);
    }

    return results;

 };

 export default moduleScorecardCalculation;