import React from "react";
import useGlobal from "../../store";
import { UserContext } from "../../providers/UserProvider";
import S3 from "react-aws-s3";

const moduleUpload = (user, remotePath, setThumbnail) => {

  const path = "https://pacific-lake-82221.herokuapp.com/" + remotePath;
  let newFileName = Date.now();
  let extention = 'jpg';

  fetch(path)
  .then((response)=> {
    return response.blob();
  })
  .then(blob=> {
    let file = blob;

    const re = /(?:\.([^.]+))?$/;
    extention = re.exec(path.toLowerCase())[1];

    if(extention !== 'jpeg' && extention !== 'jpg' && extention !== 'png' && extention !== 'gif') {
      alert('Your upload has failed bacause you have not uploaded an image!' + path);
      return;
    }

    const config = {
	  bucketName: 'property-gambit-uploads',
	  dirName: '/photos/' + user.uid, /* optional */
	  region: 'eu-west-2',
	  accessKeyId: 'AKIAJBPLDSXKCSN3AV5Q',
	  secretAccessKey: '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'
    };
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName + '.' + extention).then((data) => {
      if (data.status === 204) {
        setThumbnail('https://property-gambit-uploads.s3-eu-west-2.amazonaws.com//photos/' + user.uid + '/' + newFileName + '.' + extention);
      } else {
        //console.log("failed");
      }
    });
  });

};

 export default moduleUpload;