import React, { useContext, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import firebase from 'firebase';
import useGlobal from "../store";

import TemplateAppraisalListing from "./templates/template-appraisals-listing";

const HMOAppraisals = props => {

const user = useContext(UserContext);

// Get User Preferences around Portfolio

const [userPrefs] = useGlobal(
  state => state.preferences
);

const [preferenceMapping] = useGlobal(
  state => state.preferenceMapping
);

const [showWelcomePanel, setShowWelcomePanel] = useState('hidden');
const [showNoResults, setShowNoResults] = useState('hidden');
const [showFilterBar, setShowFilterBar] = useState('hidden');
const [filterKey, setFilterKey] = useState("*");
let [listingContent, setListingContent] = useState([]);


React.useEffect(
  () => {
    if(filterKey === '*') {
      toggle('appraisal-item', 'block', false);
    }
    else {
      toggle('appraisal-item', 'none', false);
      toggle(filterKey, 'block', true);
    }
    if(listingContent.length <= 0) {
      let listing = firebase.database().ref('/appraisals/' + user.uid + '/hmo').orderByChild('created');
      listing.once('value').then(function(snapshot) {
        let appraisals = [];
        snapshot.forEach(child =>{
          appraisals.push(child);
        });
        appraisals.reverse();
        setListingContent(appraisals);
        toggle('appraisal-item', 'block', false);
        listing.off('value');
        if(appraisals.length === 0) {
          setShowWelcomePanel('block');
        } else {
          setShowFilterBar('block');
        }
      });
    }
  },
  [filterKey, user.uid, listingContent]
);


function toggle(className, displayState, activeSearch){
    var elements = document.getElementsByClassName(className);
    setShowNoResults('hidden');

    let total = 0;
    for (var i = 0; i < elements.length; i++){
        total ++;
        elements[i].style.display = displayState;
    }

    // all items have been hidden i.e. no results found
    if(total <= 0) {
      setShowNoResults('block');
    }
}

  return (


  <div className="leading-normal tracking-normal bg-pgGrey-500">
    
    <nav id="header" className={`bg-white sticky top-0 w-full z-10 border-t border-b border-grey-800 ${showFilterBar}`}>

        <div className="w-full container mx-auto flex flex-wrap items-center mt-0 p-5">

            <label className="flex w-1/2 md:w-1/6 mr-0 mb-2 md:mb-0">
              Filter Results:
            </label>
            <label className="flex w-1/2 md:w-1/6 mr-0 cursor-pointer" onClick={() => setFilterKey("*")}>
              <input type="radio" name="filter" value="*" className="appraisal-filter" />
              <span className="bg-pgRed-300 mb-2 md:mb-0 w-11/12 md:w-4/5 hover:text-white hover:bg-pgRed-500 text-xs text-pgNavy-500 font-bold py-1 px-2 rounded rounded-full block text-left">
                <em className="icon-plus mr-2"></em><span>Show All</span>
              </span>
            </label>
            <label className="flex w-1/2 md:w-1/6 mr-0 cursor-pointer" onClick={() => setFilterKey("offered")}>
              <input type="radio" name="filter" value="offered" className="appraisal-filter" />
              <span className="bg-pgRed-300 mb-2 md:mb-0 w-11/12 md:w-4/5 hover:text-white hover:bg-pgRed-500 text-xs text-pgNavy-500 font-bold py-1 px-2 rounded rounded-full block text-left">
                <em className="icon-star mr-2"></em><span className="hidden xl:inline">Offered/Accepted</span><span className="inline xl:hidden">Offered</span>
              </span>
            </label>
            <label className="flex w-1/2 md:w-1/6 mr-0 cursor-pointer" onClick={() => setFilterKey("hot")}>
              <input type="radio" name="filter" value="hot" className="appraisal-filter" />
              <span className="bg-pgRed-300 mb-2 md:mb-0 w-11/12 md:w-4/5 hover:text-white hover:bg-pgRed-500 text-xs text-pgNavy-500 font-bold py-1 px-2 rounded rounded-full block text-left">
                <em className="icon-fire mr-2"></em><span className="hidden xl:inline">Hot Property</span><span className="inline xl:hidden">Hot</span>
              </span>
            </label>
            <label className="flex w-1/2 md:w-1/6 mr-0 cursor-pointer" onClick={() => setFilterKey("noChain")}>
              <input type="radio" name="filter" value="noChain" className="appraisal-filter" />
              <span className="bg-pgRed-300 mb-2 md:mb-0 w-11/12 md:w-4/5 hover:text-white hover:bg-pgRed-500 text-xs text-pgNavy-500 font-bold py-1 px-2 rounded rounded-full block text-left">
                <em className="icon-link mr-2"></em><span>No Chain</span>
              </span>
            </label>
            <label className="flex w-1/2 md:w-1/6 mr-0 cursor-pointer" onClick={() => setFilterKey("bmv")}>
              <input type="radio" name="filter" value="bmv" className="appraisal-filter" />
              <span className="bg-pgRed-300 mb-2 md:mb-0 w-11/12 md:w-4/5 hover:text-white hover:bg-pgRed-500 text-xs text-pgNavy-500 font-bold py-1 px-2 rounded rounded-full block text-left">
                <em className="icon-down mr-2"></em><span className="hidden xl:inline">BMV</span><span className="inline xl:hidden">BMV</span>
              </span>
            </label>

        </div>

    </nav>

    <div className="bg-white mx-auto mt-8 ml-8 mr-8 p-5 box-border border-pgGray-500 border rounded lg:p-10">

      <h1 className="text-3xl mb-5 text-pgNavy-500">Your HMO Appraisals</h1>

      <div className={`container ${showWelcomePanel} w-full mx-auto mt-5 mb-10`}>
        <p className="mb-3 text-pgNavy-500">Looks like you're new here, you don't currently have any HMO appraisals to review. Create a new appraisal below to start getting an edge of other investors.</p>
      </div>

      <div className={`container ${showNoResults} border w-full mx-auto p-8 mt-12`}>
        <h1 className="text-2xl mb-3 text-pgRed-500">No Results Found</h1>
        <p className="mb-3">Based on your filter we could not match any results, If you are filtering a listing please try another filter or click on the "Show All" option.</p>
      </div>

      <div className="container w-full mx-auto">

          <TemplateAppraisalListing type={`hmo`} listingContent={listingContent} archive={props.archive} userPrefs={userPrefs} preferenceMapping={preferenceMapping}></TemplateAppraisalListing>

      </div>

    </div>

  </div>
  ) 
};

export default HMOAppraisals;

