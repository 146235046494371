/* 
Partial for Onboarding Block
*/

import React, { useState } from "react";
import { SlideDown } from 'react-slidedown'
import firebase from 'firebase';

const TemplateOnboarding = ({user}) => {

  // logic here
  const [showModal, setShowModal] = useState(user && !user.onboarded);
  const [modalState, setModalState] = useState(0);

  let modalContent = [
    {
      title: 'Let\'s Get Started!',
      summary: 'Using Property Gambit is easy, but to make sure you get the most out of it here are some pointers!',
      okButtonText: 'OK, lets Go!',
      cancelButtonText: 'No Thanks!',
      finishButtonText: '',
    },
    {
      title: 'Create a new appraisal',
      summary: 'Click on this panel from any of the listing screens to jump in and create a new appraisal',
      okButtonText: 'Got it... What\'s Next',
      cancelButtonText: '',
      finishButtonText: '',
    },{
      title: 'Start with basic data',
      summary: 'The first screen is where you will enter your basic property data. Don\'t worry too much this can easily be changed at a later date',
      okButtonText: 'Please Continue',
      cancelButtonText: '',
      finishButtonText: '',
    },{
      title: 'Editing data & content',
      summary: 'Fields that you can edit will have a white background and grey border, they will turn red when you mouse over them or select them.',
      okButtonText: 'Great... What\'s Next',
      cancelButtonText: '',
      finishButtonText: '',
    },{
      title: 'Track your offers',
      summary: 'At any time you can change the Status and enter offer information or accepted/lost price so you can keep tabs on your target properties',
      okButtonText: 'Got it... What\'s Next',
      cancelButtonText: '',
      finishButtonText: '',
    },{
      title: 'Tool tips to help explain the lingo',
      summary: 'Mouse over the tooltips on the forms to give you a summary of the data you need to input into each field',
      okButtonText: 'Nice... Show me more',
      cancelButtonText: '',
      finishButtonText: '',
    },{
      title: 'Recycle more cash with our offer calculator',
      summary: 'Once you have entered your core information click on the calculator icon to reveal a field where you can enter the percentage of capital you want to get out on refinance and our bot will calculate this so you know what you would need to offer!',
      okButtonText: 'Amazing... Keep Going',
      cancelButtonText: '',
      finishButtonText: '',
    },{
      title: 'Take control of your KPIs',
      summary: 'Use the settings cog to choose which KPI\'s you want to see, this will persist and is unique to you! These KPI\'s will appear at the top of every appraisal and be the headline stats in your listing',
      okButtonText: 'Incredible... What\'s Next',
      cancelButtonText: '',
      finishButtonText: '',
    },{
      title: 'Compare all your deals side-by-side ',
      summary: 'Use the listing pages compare your deals side by side, by selecting the KPIs that matter you can quickly compare your deals and focus energy on the strongest investments',
      okButtonText: 'Very Handy... Go on',
      cancelButtonText: '',
      finishButtonText: '',
    },{
      title: 'That\'s it!',
      summary: 'You\'re ready to go! Please share property gambit on social media and tell anyone you think would be interested! We\'re on a mission to help as many people as possible and need your support!',
      okButtonText: '',
      cancelButtonText: '',
      finishButtonText: 'I\'m Ready! Lets go!',
      lastSlide : true,
    },
  ];

  const modalItems = modalContent.map((content, index) =>
      <SlideDown key={index} className="w-full" transitionOnAppear={false}>
        {modalState === index &&
          <div className="block md:flex flex-row-reverse md:p-20 md:pr-5 items-center">
            <div className={`block w-100 md:w-1/2 h-64 md:h-5/6 onboard-${index}`}></div>

            <div className="block md:w-1/2 md:pr-10">
              <div className="text-2xl md:text-4xl text-pgBlue-500 font-bold mb-3 md:mb-5">{content.title}</div>
              <div className="text-pgBlue-500 font-thin md:text-lg">{content.summary}</div>

              <div className="flex flex-col mt-5 md:mt-10">
                {content.okButtonText && 
                <span className='cursor-pointer inline-block bg-gradient-to-r w-2/3 from-pgAqua-500 to-pgGreen-500 rounded rounded-full p-2 pl-5 pr-5 text-pgBlue-500 mb-2 hover:bg-gradient-to-r hover:from-pgGreen-500 hover:to-pgAqua-500' onClick = {() => {nextModalState()}}>{content.okButtonText}</span>
                }
                
                {content.cancelButtonText && 
                  <span className='cursor-pointer inline-block bg-pgRed-500 hover:bg-pgRed-300 w-2/3 rounded rounded-full p-2 pl-5 pr-5 text-pgNavy-500 mb-2' onClick = {() => {closeModal(user.uid)}}>{content.cancelButtonText}</span>
                }

                {content.finishButtonText && 
                  <span className='cursor-pointer inline-block bg-gradient-to-r w-2/3 from-pgAqua-500 to-pgGreen-500 rounded rounded-full p-2 pl-5 pr-5 mt-5 text-pgBlue-500 mb-2' onClick = {() => {closeModal(user.uid)}}>{content.finishButtonText}</span>
                }
              </div>
            </div>

          </div>
        }
      </SlideDown>
  );

  const closeModal = (uid) => {
    const userRef = firebase.database().ref('users/' + uid);
    let updates = {onboarded:1};
    userRef.update(updates);
    userRef.off();
    setShowModal(false);
  }

  const nextModalState = () => {
    if(modalState < (modalContent.length - 1)) {
      setModalState(modalState+1);
    }
  }

  const prevModalState = () => {
    if(modalState > 0) {
      setModalState(modalState-1);
    }
  }

  return (
    <div className={`${showModal?'flex':'hidden'} flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800 z-50 bg-opacity-50`}>
      <div className="bg-white rounded w-full m-10 block max-w-5xl">
        <div className="flex flex-col items-start p-4">
          <div className="flex items-center w-full">
            <svg onClick = {() => {closeModal(user.uid)}} className="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"/>
            </svg>
          </div>

          {modalItems}

          <div className="hidden md:flex flex mt-5 modal-arrows">
          <span className={`cursor-pointer p-2 bg-pgGreen-500 rounded rounded-full icon-left-dir ${modalState > 0?'':' opacity-50'}`} onClick = {() => {prevModalState()}}></span>

          <span className={`cursor-pointer p-2 bg-pgGreen-500 rounded rounded-full icon-right-dir ${modalState < (modalContent.length - 1)?'':' opacity-50'}`} onClick = {() => {nextModalState()}}></span>

          </div>

        </div>
      </div>
    </div>
  )

}

export default TemplateOnboarding;
