import React, { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { NavLink } from 'react-router-dom';
import apiGlobal from "../store/api";
import SvgHand from '../src/hand.svg';
import SVGBullet from '../src/pg-bullet.svg';

import TemplateSubscribeNowButton from "./templates/template-subscribe-now-button";

const SripeUpgrade = ({type, envMode}) => {

const user = useContext(UserContext);
const [apiSettings] = apiGlobal(state => state.apiSettings);
//console.log('UPGRADE', user);

  return (

  <div className="w-full p-10 lg:p-20 bg-pgBlue-500">

    <h1 className="text-4xl font-bold mb-2 text-white">
      {type === 'limit-reached' && 
        <>You have used all of your free credits</>
      } 
      {type !== 'limit-reached' && 
        <>Time to upgrade?</>
      }
      </h1>

    <p className="text-md mb-5 text-white">If you want to source deals quicker with more chance of selling them, you will need to buy credits or upgrade...</p>

    <div className="block md:flex md:items-stretch xl:w-full">

        <div className="mb-10 bg-white shadow-md">
          <h2 className="text-3xl text-pgBlue-500 p-3 md:p-6 font-bold md:text-3xl lg:text-3xl leading-tight text-center mb-2">Pay as you go</h2>
          <ul className="px-3 md:px-6">
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Access</b> to Property Gambit</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" />Pay for <b className="font-bold ml-1">What you use</b></li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Top up</b> Credits when you like</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">No Monthly</b> Subscription</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">3 Free</b> credits every 30 days</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">250MB</b> Storage</li>
          </ul>
          <h2 className="text-3xl text-pgBlue-500 p-3 md:p-6 font-bold md:text-3xl lg:text-3xl leading-tight text-center">FREE</h2>
          <div className="pb-5 items-center text-center">
            <div className="block w-full pl-4 pr-4">
              <NavLink to="/buy-credits" className="block cursor-pointer inline-block mb-2 ml-0 bg-gradient-to-r w-full sm:w-2/3 lg:w-full from-pgAqua-500 to-pgGreen-500 rounded rounded-full p-1 text-pgBlue-500"><span>Buy More Credits</span></NavLink>
            </div>
          </div>
        </div>

        <div className={`mb-10 bg-white shadow-md lg:mx-10 relative ${user.subscription.type === 'standard' ? 'opacity-75' : ''}`}>
          <h2 className="hidden text-xl bg-pgGreen-500 text-pgBlue-500 p-3 md:p-6 font-light lg:text-2xl leading-tight text-center absolute w-full">Most Popular</h2>
          <h2 className="text-3xl text-pgBlue-500 p-3 md:p-6 font-bold md:text-3xl lg:text-3xl leading-tight text-center mb-2">Standard</h2>
          <ul className="px-3 md:px-6">
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Access</b> to Property Gambit</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">25</b> Credits a month</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">1GB</b> Storage</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Email</b> Support</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Easy</b> Monthly Billing</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Export deals</b> to buyers</li>
          </ul>
          <h2 className="text-3xl text-pgBlue-500 p-3 md:p-6 font-bold md:text-3xl lg:text-3xl leading-tight text-center">£9.99 a month</h2>
          <div className="pb-5 items-center text-center">

            {user.subscription.type !== 'standard' &&
            <TemplateSubscribeNowButton
                stripePublicKey={apiSettings[envMode].stripePublicKey}
                apiName={apiSettings[envMode].apiName}
                apiEndpoint="/checkout"
                buttonText="Choose Plan"
                className="mb-2 ml-0"
                successUrl={`${apiSettings[envMode].redirect}/#/?subscription_session_id={CHECKOUT_SESSION_ID}`} // Where to redirect if success
                cancelUrl={`${apiSettings[envMode].redirect}/#/upgrade`} // Where to go if payment canceled
                customerId={user.subscription.customerId}
                />
            }
            {user.subscription.type === 'standard' &&
              <div className="block w-full pl-4 pr-4">
                <div className="block inline-block mb-2 ml-0 bg-gradient-to-r w-full sm:w-2/3 lg:w-full from-pgNavy-500 to-pgNavy-500 rounded rounded-full p-1 text-white">Current Plan</div>
              </div>
            }
            </div>
        </div>


        <div className={`mb-10 bg-white shadow-md ${user.subscription.type === 'pro' ? 'opacity-75' : ''}`}>
          <h2 className="text-3xl text-pgBlue-500 p-3 md:p-6 font-bold md:text-3xl lg:text-3xl leading-tight text-center mb-2">Pro</h2>
          <ul className="px-3 md:px-6">
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Full access</b> to Property Gambit</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Unlimited</b> Credits</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Unlimited</b> Storage</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Email</b> Support</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Easy</b> Monthly Billing</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Export deals</b> to buyers</li>
          </ul>
          <h2 className="text-3xl text-pgBlue-500 p-3 md:p-6 font-bold md:text-3xl lg:text-3xl leading-tight text-center">£15.99 a month</h2>
          <div className="pb-5 items-center text-center">

            {user.subscription.type !== 'pro' &&
              <TemplateSubscribeNowButton
                stripePublicKey={apiSettings[envMode].stripePublicKey}
                apiName={apiSettings[envMode].apiName}
                apiEndpoint="/checkout-pro"
                buttonText="Choose Plan"
                className="mb-2 ml-0"
                successUrl={`${apiSettings[envMode].redirect}/#/?subscription_session_id={CHECKOUT_SESSION_ID}`} // Where to redirect if success
                cancelUrl={`${apiSettings[envMode].redirect}/#/upgrade`} // Where to go if payment canceled
                customerId={user.subscription.customerId}
                />
            }
            {user.subscription.type === 'pro' &&
              <div className="block w-full pl-4 pr-4">
                <div className="block inline-block mb-2 ml-0 bg-gradient-to-r w-full sm:w-2/3 lg:w-full from-pgNavy-500 to-pgNavy-500 rounded rounded-full p-1 text-white">Current Plan</div>
              </div>
            }
            </div>
        </div>

        <div className="bg-white shadow-md hidden">
          <h2 className="text-3xl text-pgBlue-500 p-3 md:p-6 font-bold md:text-3xl lg:text-3xl leading-tight text-center mb-2">Annual Plan</h2>
          <ul className="px-3 md:px-6">
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Full access</b> to Property Gambit</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">14 Day</b> Free Trial</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Unlimited</b> Credits</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Unlimited</b> Storage</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Email</b> Support</li>
            <li className="mb-3 text-pgBlue-500 text-sm flex items-center"><img src={SVGBullet} alt="" className="w-1/12 mx-2" /><b className="font-bold mr-1">Save 28%</b> on Monthly Billing</li>
          </ul>
          <h2 className="text-3xl text-pgBlue-500 p-3 md:p-6 font-bold md:text-3xl lg:text-3xl leading-tight text-center">£95 a year</h2>
          <div className="pb-5 items-center text-center">
            <TemplateSubscribeNowButton
                stripePublicKey={apiSettings[envMode].stripePublicKey}
                apiName={apiSettings[envMode].apiName}
                buttonText="Choose Plan"
                className="mb-5 ml-0"
                apiEndpoint="/checkout-annual"
                successUrl={`${apiSettings[envMode].redirect}/#/?session_id=CHECKOUT_SESSION_ID`} // Where to redirect if success
                cancelUrl={`${apiSettings[envMode].redirect}/#/`} // Where to go if payment canceled
                customerId={user.subscription.customerId}
                />
          </div>
        </div>
        
    </div>

    <p className="text-md mt-10 mb-5 text-white font-bold">If you go back to using spreadsheets you will miss out on:</p>

    <ul className="mb-5 list-disc ml-5">
    <li className="text-md mb-1 text-white">Auto-generated property prices based on historical area sold prices.</li>
    <li className="text-md mb-1 text-white">Access to our "Offer Calculator" that will suggest an offer price based on the % of the capital you need to recycle.</li>
    <li className="text-md mb-1 text-white">Comparison of deals side-by-side to focus on the best deals and outperform other investors.</li>
    <li className="text-md mb-1 text-white">Customisable KPI's that surface the important data.</li>
    <li className="text-md mb-1 text-white">Professional PDF Exports of your deals at the click of a button.</li>
    <li className="text-md mb-1 text-white">Online sharing of deals to buyers.</li>
    </ul>

    <p className="text-md mb-5 text-white font-bold text-xl mt-10">Looking to Cancel?</p>
    <p className="text-md mb-5 text-white">If you want to cancel your plan please go to "Manage Billing" on the main navigation and cancel your subscription form here.</p>

    <img src={SvgHand} className="hidden lg:block fixed bottom-0 right-0 max-w-screen-lg w-1/3" alt="Keys" />
  </div>
  ) 
};

export default SripeUpgrade;

