import React from "react";
import useGlobalHook from "use-global-hook";
import * as actions from "../actions";

const initialState = {
  preferences: {
    roce:                         true,
    grossYield:                   true,
    grossRent:                    true,
    grossAnnualIncome:            true,
    netAnnualIncome:              true,
    netMonthlyIncome:             false,
    price:                        false,
    askingPrice:                  false,
    refurb:                       false,
    pricePerSqft:                 false,
    initialLoanValue:             false,
    initialDeposit:               false,
    initialCapitalInvested:       false,
    refinancePrice:               false,
    refinanceLoanValue:           false,
    refinanceDeposit:             false,
    refinanceCapitalInvested:     false,
    refinanceEquityReleased:      false,
    combinedRoce:                 false
  },    
  appraisalHMOScaffold: {
    created:                      Date.now(),
    name:                         '',
    address:                      '',
    type:                         '',
    url:                          '',
    grossRent:                    '',
    occupancyPercentage:          90,
    avgRoomRent:                  '',
    grossAnnualIncome:            0,
    netAnnualIncome:              0,
    netMonthlyIncome:             0,
    billsPerRoom:                 100,
    thumbnail:                    '',
    annualBills:                  0,
    monthlyBills:                 0,
    managementFee:                10,
    monthlyManagementCost:        0,
    annualManagementCost:         0,
    vatOnManagement:              'no',
    annualRepairsAndMaintenance:  500,
    annualTentantFindFees:        '',
    price:                        '',
    askingPrice:                  '',
    postcode:                     '',
    refurb:                       '',
    legalsAndSurveys:             1800,
    stampDuty:                    0,
    stampDutyLock:                false,
    hmoLicence:                   0,
    otherFees:                    0,
    avgPricePerSqft:              0,
    sqft:                         0,
    sqm:                          0,
    pricePerSqft:                 0,
    sqftDifference:               0,
    priceDifference:              0,
    areaPrice:                    0,

    initialMortgageRate:          '4.99',
    initialLTV:                   75,
    initialLoanValue:             0,
    initialDeposit:               0,
    initialAnnualInterest:        0,
    initialCapitalInvested:       0,
    initialTerm:                  12,
    initialInterestPeriod:        'annually',

    refinancePrice:               0,
    refinanceUplift:              0,
    refinanceMortgageRate:        '4.99',
    refinanceLTV:                 75,
    refinanceLoanValue:           0,
    refinanceDeposit:             0,
    refinanceAnnualInterest:      0,
    refinanceERC:                 0,
    refinanceCapitalInvested:     0,
    refinanceEquityReleased:      0,
    percentageRecycled:           0,


    grossYield:                   0,
    netYield:                     0,
    roce:                         0,
    growthRate:                   4,
    annualGrowth:                 0,
    rentAndGrowth:                0,
    combinedRoce:                 0,

    notes:                        '',

    flagHot:                      0,
    flagNoChain:                  0,
    flagBMV:                      0,

    offerStatus:                  'appraisal',
    offer1:                       '',
    offer2:                       '',
    offer3:                       '',
    offerAccepted:                '',
    offerLostSoldPrice:           '',

    archive:                      '0',

    rooms:                        4,
    pricePerRoom:                 0
  },
  appraisalBTLScaffold: {
    created:                      Date.now(),
    name:                         '',
    address:                      '',
    type:                         '',
    url:                          '',
    grossRent:                    '',
    occupancyPercentage:          96,
    grossAnnualIncome:            0,
    netAnnualIncome:              0,
    netMonthlyIncome:             0,
    billsPerRoom:                 0,
    thumbnail:                    '',
    managementFee:                10,
    monthlyManagementCost:        0,
    annualManagementCost:         0,
    annualRepairsAndMaintenance:  500,
    annualTentantFindFees:        '',
    price:                        '',
    askingPrice:                  '',
    postcode:                     '',
    refurb:                       '',
    legalsAndSurveys:             1800,
    stampDuty:                    0,
    stampDutyLock:                false,
    hmoLicence:                   0,
    otherFees:                    0,
    avgPricePerSqft:              0,
    sqft:                         0,
    sqm:                          0,
    pricePerSqft:                 0,
    sqftDifference:               0,
    priceDifference:              0,
    areaPrice:                    0,

    initialMortgageRate:          '4.69',
    initialLTV:                   75,
    initialLoanValue:             0,
    initialDeposit:               0,
    initialAnnualInterest:        0,
    initialCapitalInvested:       0,
    initialTerm:                  12,
    initialInterestPeriod:        'annually',

    refinancePrice:               0,
    refinanceUplift:              0,
    refinanceMortgageRate:        '4.69',
    refinanceLTV:                 75,
    refinanceLoanValue:           0,
    refinanceDeposit:             0,
    refinanceAnnualInterest:      0,
    refinanceERC:                 0,
    refinanceCapitalInvested:     0,
    refinanceEquityReleased:      0,

    grossYield:                   0,
    netYield:                     0,
    roce:                         0,
    growthRate:                   4,
    annualGrowth:                 0,
    rentAndGrowth:                0,
    combinedRoce:                 0,

    notes:                        '',

    flagHot:                      0,
    flagNoChain:                  0,
    flagBMV:                      0,

    offerStatus:                  'appraisal',
    offer1:                       '',
    offer2:                       '',
    offer3:                       '',
    offerAccepted:                '',
    offerLostSoldPrice:           '',

    archive:                      '0',

    rooms:                        3,
    pricePerRoom:                 0
  },    
  appraisalFlipScaffold: {
    created:                      Date.now(),
    name:                         '',
    address:                      '',
    type:                         '',
    url:                          '',
    thumbnail:                    '',
    price:                        '',
    askingPrice:                  '',
    postcode:                     '',
    refurb:                       '',
    legalsAndSurveys:             1800,
    stampDuty:                    0,
    stampDutyLock:                false,
    otherFees:                    0,
    eaSellingFee:                 2,
    eaSellingCosts:               0,
    avgPricePerSqft:              0,
    sqft:                         0,
    sqm:                          0,
    pricePerSqft:                 0,
    sqftDifference:               0,
    priceDifference:              0,
    areaPrice:                    0,

    initialMortgageRate:          '4.99',
    initialLTV:                   75,
    initialLoanValue:             0,
    initialDeposit:               0,
    initialAnnualInterest:        0,
    initialCapitalInvested:       0,
    initialTerm:                  6,
    initialInterestPeriod:        'annually',

    salePrice:                    '',
    percentageSaleUplift:         0,
    saleUplift:                   0,
    saleERC:                      0,
    
    percentageProfit:             0,
    profit:                       0,
    ROI:                          0,

    notes:                        '',

    flagHot:                      0,
    flagNoChain:                  0,
    flagBMV:                      0,

    offerStatus:                  'appraisal',
    offer1:                       '',
    offer2:                       '',
    offer3:                       '',
    offerAccepted:                '',
    offerLostSoldPrice:           '',

    archive:                      '0',

    rooms:                         4
  },
  uploads:                        {
    location:                     ''
  },
  preferenceMapping: [
    { 'option': true, 'format' : 'currency', 'key' : 'askingPrice', 'label' : 'Asking Price'},
    { 'option': true, 'format' : 'currency', 'key' : 'price', 'label' : 'Purchase Price'},
    { 'option': true, 'format' : 'currency', 'key' : 'refurb', 'label' : 'Refurb Cost'},
    { 'option': true, 'format' : 'currency', 'key' : 'initialDeposit', 'label' : 'Initial Deposit'},
    { 'option': true, 'format' : 'currency', 'key' : 'initialCapitalInvested', 'label' : 'Initial Capital Required'},
    { 'option': true, 'format' : 'currency', 'key' : 'refinancePrice', 'label' : 'Refinance Price'},
    { 'option': false, 'format' : 'currency', 'key' : 'refinanceLoanValue', 'label' : 'Final Loan Value'},
    { 'option': true, 'format' : 'currency', 'key' : 'refinanceDeposit', 'label' : 'Final Deposit'},
    { 'option': true, 'format' : 'currency', 'key' : 'refinanceCapitalInvested', 'label' : 'Capital Employed'},
    { 'option': true, 'format' : 'currency', 'key' : 'refinanceEquityReleased', 'label' : 'Final Equity Released'},
    { 'option': true, 'format' : 'percentage', 'key' : 'percentageRecycled', 'label' : '% of capital recycled'},
    { 'option': true, 'format' : 'percentage', 'key' : 'roce', 'label' : 'ROCE'},
    { 'option': true, 'format' : 'percentage', 'key' : 'combinedRoce', 'label' : 'Combined ROCE'},
    { 'option': true, 'format' : 'percentage', 'key' : 'grossYield', 'label' : 'Gross Yield'},
    { 'option': true, 'format' : 'percentage', 'key' : 'netYield', 'label' : 'Net Yield'},
    { 'option': true, 'format' : 'currency', 'key' : 'grossRent', 'label' : 'Gross Monthly Rent'},
    { 'option': true, 'format' : 'currency', 'key' : 'netMonthlyIncome', 'label' : 'Net Monthly Rent'},
    { 'option': true, 'format' : 'currency', 'key' : 'grossAnnualIncome', 'label' : 'Gross Annual Rent'},
    { 'option': true, 'format' : 'currency', 'key' : 'netAnnualIncome', 'label' : 'Net Annual Rent'},
    { 'option': true, 'format' : 'currency', 'key' : 'pricePerSqft', 'label' : 'Price per SQFT'},
    { 'option': false, 'format' : 'currency', 'key' : 'salePrice', 'label' : 'Sale Price'},
    { 'option': false, 'format' : 'currency', 'key' : 'saleUplift', 'label' : 'Uplift'},
    { 'option': false, 'format' : 'currency', 'key' : 'profit', 'label' : 'Profit'},
    { 'option': true, 'format' : 'currency', 'key' : 'pricePerRoom', 'label' : 'Price Per Room'},
    { 'option': false, 'format' : 'percentage', 'key' : 'ROI', 'label' : 'Return on Investment'},
  ],
  toolTips: {
    grossRent:'The total monthly rent before<br />any costs are deducted',
    occupancyPercentage:'The percentage of the year you<br /> expect to receive rental income',
    avgRoomRent:'Your average rent based on your<br /> total income and number of rooms',
    grossAnnualIncome:'The total annual rent BEFORE<br /> any costs are deducted',
    grossMonthlyIncome: 'The total monthly rent BEFORE<br /> any costs are deducted',
    netAnnualIncome:'The total annual income AFTER<br /> all costs are deducted',
    netMonthlyIncome: 'The total monthly income AFTER<br /> all costs are deducted',
    billsPerRoom: 'How much are you going to allow per room<br /> monthly for utilities, broadband etc...',
    annualBills:'Your total annual bill costs based<br /> on your bill allowance per room i.e. <br />bill allowance per room * rooms * 12',
    monthlyBills: 'Your total monthly bill costs based<br /> on your bill allowance per room i.e. <br />bill allowance per room * rooms',
    managementFee:'What percentage is your management<br /> agent charging you, set this to 0%<br />if you are self managing',
    monthlyManagementCost:'The cost of your monthly management agent<br /> based on your gross rental income',
    annualTentantFindFees:'The cost your letting agent will charge you to<br /> find and secure your tenants. This is typically<br /> 50% +/-VAT (per tenancy) of the first month\'s rent',
    annualManagementCost: 'The cost of your annual management agent<br /> based on your gross rental income',
    annualRepairsAndMaintenance:'How much are you going to allow every year<br /> for Maintenence and Repairs',
    price:'Your purchase price for the property',
    askingPrice:'The price the property is listed for',
    refurb: 'The amount of money you are planning<br /> to spend on your refurb',
    legalsAndSurveys: 'The cost paid to solicitors and<br /> legal professionals e.g. Surveys, Conveyencing,<br /> Planning Submissions...',
    stampDuty:'The cost of Stamp Duty Land Tax<br /> based on the purchase price, payable<br /> to HMRC on completion - Currently 3% <br />(Overwrite if required, changing price will reset)',
    hmoLicence: 'The cost of your HMO licence<br /> (if required)',
    otherFees:'Other costs such as sourcing<br /> fees, third party fees etc...',
    avgPricePerSqft:'The average price paid for a property<br /> based on per SQFT in the given postcode',
    sqft: 'How big the property is in SQFT',
    sqm:'How big the property is in SQM',
    pricePerSqft: 'Price per SQFT for this property<br /> based on size and Purchase Price',
    areaPrice:'The value of this property based<br /> on its size and the area average <br />price per SQFT',
    initialMortgageRate:'The mortgage rate for<br /> the initial borrowing',
    initialTerm:'This is the duration of the initial borrowing<br /> if you are not planning to refinance set to 0 years',
    initialInterestPeriod:'This is if the interest on the initial term <br />is charged  annually or monthly',
    initialLTV: 'The mortgage loan to value<br /> for the initial borrowing',
    loanValue:         'The mortgage loan for the<br /> initial borrowing',
    initialDeposit: 'The mortgage deposit required<br /> for the initial borrowing',
    initialAnnualInterest:'The annual mortgage cost for<br /> the initial borrowing',
    initialCapitalInvested: 'The total amount of money<br /> required initially to buy<br /> and refurbish the property',
    refinancePrice: 'The price you expect the property<br /> to be worth once refurbished',
    refinanceMortgageRate:'The mortgage rate for the<br /> new mortgage based on the new<br /> price once refurbished',
    refinanceLTV: 'The mortgage loan to value<br /> for the new mortgage based on<br /> the new price once refurbished',
    refinanceLoanValue: 'The mortgage loan for the new<br /> mortgage based on the new<br /> price once refurbished',
    refinanceDeposit: 'The mortgage deposit required<br /> for the new mortgage based<br /> on the new price once refurbished',
    refinanceAnnualInterest:'The annual mortgage cost for the<br /> new mortgage based on<br /> the new price once refurbished',
    refinanceERC: 'The early repayment charge from<br /> the initial mortgage to terminate early<br />or the retained interest from a bridging loan',
    saleERC:      'The early repayment charge from<br /> the initial mortgage to terminate early<br />or the retained interest from a bridging loan',
    refinanceCapitalInvested: 'The amount of money you will be<br /> leaving in the property one<br /> refinanced',
    refinanceEquityReleased:'The amount of money you will be<br /> releasing back into your bank<br /> account after refinance',
    percentageRecycled:     'The percentage of your initial capital that will be released on refinance',
    grossYield: 'Gross yield is everything before<br /> expenses. It is calculated as a percentage based<br /> on the property’s cost divided by the income generated<br /> by the property refinance price',
    netYield:   'Net yield is your return after expenses. It is calculated as<br /> (Annual Rent – Operational Costs) / Property Value (Your Refinance Price)',
    roce: 'Return on Capital Employed is<br /> the percentage return of the properties<br /> income vs the capital tied up',
    growthRate: 'Capital Appreciation is the expected annual property<br /> inflation rate for your area (assumption is<br /> 4% per annum based on historic data)',
    annualGrowth: 'This is the value you can<br /> expect your properties value<br /> to increase by annually',
    rentAndGrowth:'The annual profit of the<br /> property based on capital appreciation<br /> and net rental income',
    combinedRoce: 'This is the ROCE of your property<br /> based on rental income AND<br /> capital appreciation',
    suggestedOffer: 'Based on how much you want to get<br /> back out this is how much need to offer<br /> based on refinance, refurb and legals',
    moneyOut:     'This is % of your initial captial you want to get back out',
    appraisalName:'This is a name you will use to refer to this appraisal in the future',
    websiteUrl:   'If this property is on a portal such as Rightmove or Zoopla<br />Enter the URL of the property in question',
    sqftDifference: 'This is the difference in price per SQFT between the<br /> asking price and the area average price',
    priceDifference:'This is the difference in overall price between the<br /> asking price and the area average price',
    saleUplift: 'This is the increase in value between the original<br /> purchase price and your asking price',
    percentageSaleUplift:  'This is the increase in value between the original<br /> purchase price and your asking price as a percentage',
    profit: 'This is the amount of profit you stand to make<br /> on sale of the property at the sale price',
    pricePerRoom: 'This is the cost per room<br /> Gross Purchase Price + Refurb + SDLT + Legal Fees / Room Count',
    ROI:'This is your return on investment percentage on the initial capital required',
    salePrice:'This is the amount you intend to sell the property for<br /> Default is set to 20% over inital costs',
    eaSellingFee: 'This is the percentage fee an estate agent will<br /> charge you for selling the property',
    eaSellingCosts: 'This is the monetary amount the estate agent will<br /> charge you based on the fee percentage and sale price',
   },
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
