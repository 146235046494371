import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import ModuleManageBillingButtonContent from '../modules/module-manage-billing-button-content';

class TemplateManageBillingButton extends Component {
    render() {
        const {
            stripePublicKey,
            apiName,
            apiEndpoint,

            returnUrl,
            customerId,
        } = this.props;
        return (
            <StripeProvider apiKey={stripePublicKey}>
                <Elements>
                    <ModuleManageBillingButtonContent
                        apiName={apiName}
                        apiEndpoint={apiEndpoint}
                        returnUrl={returnUrl}
                        customerId={customerId}
                        stripePublicKey={stripePublicKey}
                    />
                </Elements>
            </StripeProvider>
        );
    }
}

TemplateManageBillingButton.propTypes = {
    stripePublicKey: PropTypes.string.isRequired,
    apiName: PropTypes.string.isRequired,
    apiEndpoint: PropTypes.string.isRequired,
    returnUrl: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
};


export default TemplateManageBillingButton;