import React, { Component } from 'react';
import { Page, Text, View, Image, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import moduleFormatCurrency from "../modules/module-format-currency";
import moduleFormatPercentage from "../modules/module-format-percentage";
import moduleClenseData from "../modules/module-clense-data";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff'
  },
  tableCol: { 
    color: '#00325B',
    display: 'block',
    padding: '7px',
    fontSize: 10
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
  },
  tableRowOdd: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: '#efefef'
  },
  tableParent: {
    width: '100%',
    paddingTop:'10px',
    paddingRight: '80px',
    paddingLeft: '80px'
  },
  heading: {
    color: '#00325B',
    fontWeight: 800,
    display: 'block',
    fontSize: 20
  },
  editorial: {
    color: '#00325B',
    fontWeight: 800,
    display: 'block',
    fontSize: 8,
    textAlign: 'center'
  },
  trial: {
    display: 'block',
    position: 'absolute',
    color: '#cc0000',
    opacity: '0.6',
    fontSize: 50,
    transform: 'rotate(45deg)',
    bottom: '48%',
    left: '40%'
  },
  trialc: {
    display: 'block',
    position: 'absolute',
    color: '#cc0000',
    opacity: '0.6',
    fontSize: 30,
    transform: 'rotate(45deg)',
    bottom: '48%',
    left: '40%'
  }
});

//////////////////////////////////////////
// Export PDF
//////////////////////////////////////////
const exportPDF = (blob, filename) => {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
};

//////////////////////////////////////////
// Save PDF
//////////////////////////////////////////
const savePdf = async (document, filename) => {
    exportPDF(await pdf(document).toBlob(), filename);
};

//////////////////////////////////////////
// Show Date
//////////////////////////////////////////
const showDate = function() {
  const dateObj = new Date();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();
  const output = day + '/' + month + '/' + year;
  return output;
}

//////////////////////////////////////////
// Handle Cover Image
//////////////////////////////////////////
function PDFCoverImage(props) {
  if(props.thumbnail !== '') {
    return <Image style={{ objectFit: 'cover'}} source={{
            uri: props.thumbnail,
            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
          }} />;
  } else {
    return(<Text></Text>);
  }
}

//////////////////////////////////////////
// Handle Thumbnail
//////////////////////////////////////////
function PDFThumbnail(props) {
  if(props.thumbnail !== '') {
    return <Image style={{ objectFit: 'cover'}} source={{
            uri: props.thumbnail,
            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
          }} />;
  } else {
    return(<Image style={{ objectFit: 'cover'}} source={{
            uri: '/static/media/bg-existing-appraisal.29910c7a.png',
            headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
          }} />);
  }
}

//////////////////////////////////////////
// Handle Watermark
//////////////////////////////////////////
function Watermark(props) {
  if(props.subscriptionType === 'free') {
    return <Text style={props.className}>Upgade to remove</Text>;
  } else if(props.subscriptionType === 'trial') {
    return <Text style={props.className}>Trial Mode</Text>;
  } else {
    return <Text />;
  }
}

//////////////////////////////////////////
// PDF Template
//////////////////////////////////////////
const PDFTemplate = ({appraisalData, subscriber}) => (

  <Document>
    <Page size="A4" style={{ backgroundColor: '#00325b' }}>

        <View style={{color: '#ffffff', marginTop: '20%', marginLeft: '20%', marginRight: '20%', marginBottom: '15%'}}>
          <Text style={{color: '#ffffff', marginBottom: '20px', display: 'block', fontSize: 30}}>Investment Opportunity</Text>
          <Text style={{color: '#ffffff', marginBottom: '10px', display: 'block', fontSize: 20}}>{appraisalData.name}</Text>
          <Text style={{color: '#ffffff', marginBottom: '20px', display: 'block'}}>Created: {showDate()}</Text>
        </View>
        <View style={{overflow: 'hidden', width: '100%', height: '400px', display: 'block', padding: 0}}>
          <PDFCoverImage thumbnail={appraisalData.thumbnail} />
          <Watermark subscriptionType={subscriber} className={styles.trial} />
        </View>
        <Image style={{display: 'block', position: 'absolute', bottom: 0, right: 0, marginRight: '20px', marginBottom: '20px', width: '10%'}} src="https://property-gambit-uploads.s3.eu-west-2.amazonaws.com//branding/logo-white-text.png" />
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={{marginTop: '40px', marginBottom: '30px'}}>
        <Text style={{color: '#00325B', textAlign: 'center', fontWeight: 800, display: 'block', fontSize: 20}}>{appraisalData.name}</Text>
        <Text style={{color: '#00325B', textAlign: 'center', fontWeight: 800, marginBottom: '20px', display: 'block', fontSize: 20}}>Investment Opportunity</Text>
      </View>

      <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0}}>
        
        <View style={{width: '60%', height: '150px', display: 'block', paddingLeft: '80px', paddingRight: '20px', paddingTop: 0, paddingBottom: 0}}>
        <PDFThumbnail thumbnail={appraisalData.thumbnail} />
        </View>
        
        <View style={{overflow: 'hidden', width: '40%', paddingRight: '80px', paddingTop: 0, display: 'block', alignItems: 'top'}}>
        </View>
      </View>

      <View style={{paddingTop: '40px', paddingLeft: '80px'}}>
        <Text style={styles.heading}>Initial Costs</Text>
      </View>

      <View style={styles.tableParent}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>Purchase Price</Text>
            <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.price))}</Text>
          </View>
          <View style={styles.tableRowOdd}>
            <Text style={styles.tableCol}>Initial Deposit</Text>
            <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.initialDeposit))}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>Stamp Duty</Text>
            <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.stampDuty))}</Text>
          </View>
          <View style={styles.tableRowOdd}>
            <Text style={styles.tableCol}>Legals and Surveys</Text>
            <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.legalsAndSurveys))}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>Refurb Cost</Text>
            <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.refurb))}</Text>
          </View>
          <View style={styles.tableRowOdd}>
            <Text style={styles.tableCol}>Other Fees</Text>
            <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.otherFees))}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCol}>Lending Costs</Text>
            <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.initialAnnualInterest))}</Text>
          </View>
          <View style={styles.tableRowOdd}>
            <Text style={styles.tableCol}>Total Capital Required</Text>
            <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.initialCapitalInvested))}</Text>
          </View>
          <Watermark subscriptionType={subscriber} className={styles.trialc} />
      </View>


      <View style={{paddingTop: '20px', paddingLeft: '80px'}}>
        <Text style={styles.heading}>Predicted Returns</Text>
      </View>

      <View style={styles.tableParent}>
        <View style={styles.tableRow}>
          <Text style={styles.tableCol}>Resale Price</Text>
          <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.salePrice))}</Text>
        </View>
        <View style={styles.tableRowOdd}>
          <Text style={styles.tableCol}>Uplift</Text>
          <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.saleUplift))}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCol}>Estate Agency Cost</Text>
          <Text style={styles.tableCol}>({moduleFormatCurrency(moduleClenseData(appraisalData.eaSellingCosts))})</Text>
        </View>
        <View style={styles.tableRowOdd}>
          <Text style={styles.tableCol}>ERC</Text>
          <Text style={styles.tableCol}>({moduleFormatCurrency(moduleClenseData(appraisalData.saleERC))})</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableCol}>Profit</Text>
          <Text style={styles.tableCol}>{moduleFormatCurrency(moduleClenseData(appraisalData.profit))}</Text>
        </View>
        <View style={styles.tableRowOdd}>
          <Text style={styles.tableCol}>ROI</Text>
          <Text style={styles.tableCol}>{moduleFormatPercentage(moduleClenseData(appraisalData.ROI))}</Text>
        </View>
        <Watermark subscriptionType={subscriber} className={styles.trialc} />
      </View>

      <View style={{paddingTop: '20px', paddingLeft: '80px', paddingRight: '80px'}}>
        <Text style={styles.editorial}>This investment has been appraised using professional software, these numbers are a guide and are subject to discussion and market conditions. This opportunity is not affiliated with Property Gambit.</Text>
      </View>

    </Page>
  </Document>
);

class TemplateExportPDFFlips extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }
    render() {
        return (
            <>  
                <span
                    onClick={() => savePdf(<PDFTemplate appraisalData={this.props.appraisalData} subscriber={this.props.subscriber}/>, "investment-opportunity.pdf")}
                    className="cursor-pointer text-1xl text-center mb-2 md:mb-0 text-white rounded rounded-full p-2 sm:p-1 sm:pl-4 block ml-4 bg-pgNavy-500 hover:bg-pgNavy-800"><span className="hidden sm:inline-block">Export</span> <em className="icon-floppy sm:mr-2"></em>
                </span>
            </>
        );
    }
}

TemplateExportPDFFlips.propTypes = {
    appraisalData: PropTypes.object.isRequired,
    subscriber: PropTypes.string.isRequired
};

export default TemplateExportPDFFlips;