/* 
Partial for Appraisal Thumbnail Blocl
Used to Generate the KPI's on the Appraisal View
*/

import React from "react";
import TemplateNewAppraisalsBlock from "./template-appraisals-new-block";
import TemplateAppraisalBlock from "./template-appraisals-block";

const TemplateAppraisalListing = ({type, listingContent, archive, userPrefs, preferenceMapping}) => {

	return(

  	<div className="grid grid-cols-1 justify-around sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-4">
      <TemplateNewAppraisalsBlock type={type} archive={archive} preferenceMapping={preferenceMapping} userPrefs={userPrefs}></TemplateNewAppraisalsBlock>
      {listingContent.map((child, index) => (
         TemplateAppraisalBlock(child, archive, userPrefs, preferenceMapping, type)
      ))}

    </div>

    )

}

export default TemplateAppraisalListing;
