import React, { Component } from 'react';

class ModuleLazyBackground extends Component {
  state = { src: null };

  componentDidMount() {
    const { src } = this.props;

    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      this.setState({ src });
    };
  }

  render() {
    return <div className="bg-cover bg-no-repeat bg-center block flex w-100 text-center h-40" {...this.props} style={{ backgroundImage: `url(${this.state.src})` }} />;
  }
}

export default ModuleLazyBackground;
