/* 
FSC FOR moduleFormatPercentage
Method used to format value into the percentage we require for this app
*/
import moduleClenseData from "./module-clense-data";

const moduleFormatPercentage = (number) => {

	 if(!number) {
      return '0%';
    }
    const newNum = moduleClenseData(number);
    return Number(newNum).toFixed(2) + '%';

 };

 export default moduleFormatPercentage;