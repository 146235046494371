import React, { useState, useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import firebase from 'firebase';
import useGlobal from "../store";
import ReactTooltip from 'react-tooltip';

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import moduleScorecardCalculation from "./modules/module-scorecard-calculation";
import moduleFormatCurrency from "./modules/module-format-currency";
import moduleFormatPercentage from "./modules/module-format-percentage";
import moduleFormatFriendlyData from "./modules/module-format-friendly-data";
import moduleCalculateTotal from "./modules/module-calculate-total";

import TemplateAppraisalScoreCard from "./templates/template-appraisal-scorecard";
import TemplateAppraisalThumbnailBlock from "./templates/template-appraisal-thumbnail-block";

const ShareFlip = props => {

  //////////////////////////////////////////
  // Effects
  //////////////////////////////////////////
  React.useEffect(
    () => {
      setup();
    },
    []
  );


  //////////////////////////////////////////
  // Variables
  //////////////////////////////////////////
  const user = useContext(UserContext);
  const [toolTips]                                      = useGlobal( state => state.toolTips );
  const [scaffold]                                      = useGlobal( state => state.appraisalHMOScaffold );
  const windowWidth                                     = window.innerWidth;
  const mobile                                          = (windowWidth <= 768);

  let [appraisalData, setAppraisalData]                 = useState(scaffold);
  let [total, setTotal]                                 = useState(false);

  let [showInitialCostBlock, setShowInitialCostBlock]   = useState(!mobile);
  let [showReturnsBlock, setShowReturnsBlock]           = useState(!mobile);
  let [showDataBlock, setShowDataBlock]                 = useState(!mobile);

  //////////////////////////////////////////
  // Initial Setup
  //////////////////////////////////////////
  function setup() {
    let coreRef = firebase.database().ref('/public/flips/' + props.appraisalId);
    coreRef.once('value').then(function(snapshot) {

      const processed = moduleFormatFriendlyData(snapshot.val());
      appraisalData = moduleFormatFriendlyData(appraisalData);
      appraisalData = Object.assign(appraisalData, processed);
      setAppraisalData(appraisalData);
      setTotal(moduleCalculateTotal(appraisalData));
      calculateScorecard();

      coreRef.off('value');
    });
  }

  //////////////////////////////////////////
  // Calulcate Scorecard
  //////////////////////////////////////////
  function calculateScorecard() {

    let scorecard = moduleScorecardCalculation('flip', appraisalData, 'ROI', 'ROI', 'Uplift', 'percentageSaleUplift');

    appraisalData.overallScore = scorecard.overallScore;

  }


  return (
    
  <div className="leading-normal tracking-normal bg-pgGrey-500 js-top-anchor pb-10">

    <ReactTooltip effect="solid" clickable={true} backgroundColor="#e7ff80" textColor="#00325b" multiline={true} />
    
    <nav id="scorecard" className="bg-white w-full z-10 ">

        <div className="w-full container mx-auto flex items-center mt-0 p-5 justify-around">

          <TemplateAppraisalScoreCard type='flip' appraisalData={appraisalData} label1='ROI' dataPoint1='ROI' label2='Uplift' dataPoint2='percentageSaleUplift' />


        </div>

    </nav>

    <div className={`bg-white md:mt-8 md:mb-8 md:mr-8 md:ml-8 p-5 border-pgGray-500 border rounded lg:p-10 archive-${appraisalData.archive}`}>

      <div className="flex flex-wrap pt-4">

        <div className="w-full md:w-100 xl:w-100 md:flex justify-between">

          <div className="block w-full md:w-3/12 md:pr-10 pb-5 md:pb-0 align-middle">

            <TemplateAppraisalThumbnailBlock appraisalData={appraisalData}></TemplateAppraisalThumbnailBlock>

          </div>

          <div className="block w-full md:w-9/12 p-5 border rounded">

            <label className="items-center block md:flex">
              <p className="text-sm sm:text-base md:w-1/5 pb-1 md:pb-0 block text-pgNavy-500">Property Name:</p>
              <p className="border rounded bg-gray-100 w-full w-4/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.name}</p>
            </label>

            {appraisalData.url && 
            <label className="items-center block pt-3 md:flex">
              <p className="text-sm sm:text-base md:w-1/5 pb-1 md:pb-0 block text-pgNavy-500">URL:</p>
              <p className="border rounded bg-gray-100 w-full w-4/5 py-2 px-3 text-gray-700 leading-tight underline"><a href={appraisalData.url} target="_blank">View on Portal</a></p>
            </label>
            }

            {appraisalData.askingPrice && 
            <label className="items-center pt-3 block md:flex">
              <p className="text-sm sm:text-base md:w-1/5 pb-1 md:pb-0 block text-pgNavy-500">Asking Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.askingPrice}></em></p>
              <p className="border rounded bg-gray-100 w-full w-4/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.askingPrice}</p>
            </label>
            }

            {appraisalData.notes && 
            <label className="items-center block pt-3 md:flex">
              <p className="text-sm sm:text-base md:w-1/5 pb-1 md:pb-0 block text-pgNavy-500">Notes:</p>
              <p className="border rounded bg-gray-100 w-full w-4/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.notes}</p>
            </label>
            }

          </div>

        </div>
    </div>



    <div className="grid grid-cols-1 gap-0 md:grid-cols-2 md:gap-4 lg:grid-cols-3 lg:gap-6 md:mt-4">

      <div>

        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowInitialCostBlock(!showInitialCostBlock) : undefined}
        >
          <span>Initial Costs</span>
          <em className={(showInitialCostBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
        { showInitialCostBlock && 

          <div className="border">

            <div className="p-5">

              {appraisalData.price && 
              <label className="items-center block md:flex">
               <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Purchase Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.price}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.price}</p>
              </label>
              }

              {appraisalData.stampDuty && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Stamp Duty:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.stampDuty}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.stampDuty}</p>
              </label>
              }

              {appraisalData.legalsAndSurveys && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Legals and Surveys:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.legalsAndSurveys}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.legalsAndSurveys}</p>
              </label>
              }

              {appraisalData.refurb && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Refurb Costs:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.refurb}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.refurb}</p>
              </label>
              }

              {appraisalData.otherFees && 
              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Other Fees:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.otherFees}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.otherFees}</p>
              </label>
              }

              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Initial Deposit:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialDeposit}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{moduleFormatCurrency(appraisalData.initialDeposit)}</p>
              </label>

              <label className="items-center block pt-3 md:flex">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Initial Interest:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialAnnualInterest}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{moduleFormatCurrency(appraisalData.initialAnnualInterest)}</p>
              </label>

              <label className="items-center block md:flex pt-3">
                <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Initial Capital Required:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.initialCapitalInvested}></em></p>
                <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{moduleFormatCurrency(appraisalData.initialCapitalInvested)}</p>
              </label>

            </div>
          </div>

        }
        </SlideDown>
      </div>



      <div>
        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowReturnsBlock(!showReturnsBlock) : undefined}
        >
          <span>Returns</span>
          <em className={(showReturnsBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
          { showReturnsBlock && 
            <div className="border">
              <div className="p-5">

                <label className="items-center block md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Resale Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.salePrice}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.salePrice)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Uplift:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.saleUplift}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.saleUplift)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">% Uplift:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.percentageSaleUplift}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatPercentage(appraisalData.percentageSaleUplift)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Estate Agency Fee:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.eaSellingFee}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatPercentage(appraisalData.eaSellingFee)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Estate Agency Cost:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.eaSellingCosts}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.eaSellingCosts)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Sale ERC:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.saleERC}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.saleERC}</p>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Profit:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.profit}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.profit)}</span>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Return on investment:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.ROI}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatPercentage(appraisalData.ROI)}</span>
                </label>

              </div>
            </div>
          
        }
        </SlideDown>
      </div>






      <div>
        <h1
          className={(mobile ? 'cursor-pointer border-t' : '') + " text-xl p-3 text-pgNavy-500 flex justify-between"}
          onClick={mobile ? (event) => setShowDataBlock(!showDataBlock) : undefined}
        >
          <span>Property Data</span>
          <em className={(showDataBlock ? 'transform rotate-45' : '') + " icon-plus md:hidden transition duration-300"}></em>
        </h1>

        <SlideDown transitionOnAppear={false}>
          { showDataBlock && 

            <div className="border">
              <div className="p-5">

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Postcode:</p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.postcode}</p>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Property SQM:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.sqm}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.sqm}</p>
                </label>

                <label className="items-center block pt-3 md:flex">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Property SqFt:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.sqft}></em></p>
                  <p className="border rounded bg-gray-100 w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight">{appraisalData.sqft}</p>
                </label>

                <label className="items-center flex pt-6">
                  <p className="w-full block md:w-3/5 text-pgNavy-500 font-bold">Property Values (per SqFt)</p>
                </label>

                <label className="items-center flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Area Average:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.avgPricePerSqft}></em></p>
                  <p className="border rounded bg-gray-100 w-6/12 md:w-3/12 py-2 px-3 text-gray-700 leading-tight">{appraisalData.avgPricePerSqft}</p>
                  <p className="hidden md:block ml-1 text-sm">(SqFt)</p>
                </label>

                <label className="items-center flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Property Price:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.pricePerSqft}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden  w-6/12 md:w-3/12 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.pricePerSqft)}</span>
                  <p className="hidden md:block text-sm ml-1">(SqFt)</p>
                </label>

                <label className="items-center flex pt-3">
                  <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Difference:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.sqftDifference}></em></p>
                  <span className="border rounded bg-gray-100 overflow-hidden  w-6/12 md:w-3/12 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.sqftDifference)}</span>
                  <p className="hidden md:block text-sm ml-1">(SqFt)</p>
                </label>

                {(appraisalData.sqft > 0 || appraisalData.sqm > 0) && 

                  <div>
                  
                    <label className="items-center flex pt-3 mt-3">
                      <p className="w-full block md:w-3/5 text-pgNavy-500 font-bold">Estimated GDV</p>
                    </label>

                    <label className="items-center flex pt-3">
                      <p className="text-sm sm:text-base w-full block md:w-3/5 text-pgNavy-500">Estimated Property Value:<em className="icon-info-circled-1 text-gray-600 text-xs ml-1" data-tip={toolTips.areaPrice}></em></p>
                      <span className="border rounded bg-gray-100 overflow-hidden w-full md:w-2/5 py-2 px-3 text-gray-700 leading-tight block bg-gray-100">{moduleFormatCurrency(appraisalData.areaPrice)}</span>
                    </label>

                  </div>

                }
              </div>
            </div>
          
        }
        </SlideDown>
      </div>

      </div>

    </div>



    </div>
  ) 
};

export default ShareFlip;

