import React, { Component, createContext } from "react";
import firebase from "firebase";
import { auth, generateUserDocument } from "../firebase";

import ModuleUseCredit from "../Components/modules/module-use-credit";
import ModuleSetUserCreditLimit from "../Components/modules/module-set-user-credit-limit";
import ModuleRenewCredits from "../Components/modules/module-renew-credits";

export const UserContext = createContext({ user: null });
class UserProvider extends Component {

  state = {
    user: null
  };
  
  componentDidMount = async () => {
    auth.onAuthStateChanged(async userAuth => {
      if(userAuth) {
        let user = await generateUserDocument(userAuth);

        let that = this;

        // Method to set user type
        const setUserType = function(value) {
          let typeRef = firebase.database().ref('/users/' + user.uid + '/subscription');
          let updates = {
            type: value
          };
          typeRef.update(updates);
          typeRef.off();
          user.subscriptionType = value;
        };

        // Method to set user type
        const incrementAppraisalCount = function() {
          let appraisalCount = user.appraisalCount ? user.appraisalCount : 0;
          let newCount = appraisalCount + 1;
          let typeRef = firebase.database().ref('/users/' + user.uid);
          let updates = {
            appraisalCount: newCount
          };
          typeRef.update(updates);
          typeRef.off();
          user.appraisalCount = newCount;
        };


        // Method to set user usage token limit AND number of credits, every time its called it will see
        // User ID
        // Credits to be Set
        // Flag if its a purchase (used to ignore resetDate)
        const setUserCreditLimit = function(uid, value, purchase = false) {
          ModuleSetUserCreditLimit(uid, value, purchase);
          if(value) {
            user.limit = true;
          } else {
            user.limit = false;
          }
          user.credits = value;
        };


        // Method to set user usage token limit AND number of credits, every time its called it will see
        // User ID
        // Credits to be Set
        // Flag if its a purchase (used to ignore resetDate)
        const renewCredits = function(uid, value, user) {
          ModuleRenewCredits(uid, value, user);
        };

        // Method to increment usage
        const useCredit = function(credits) {
          ModuleUseCredit(user.uid);
          user.credits = credits -1;
          if(user.credits <= 0) {
            user.credits = 0;
          }
        };

        // Method to increment usage
        const setCredits = function(credits) {
          user.credits = credits;
        };

        let userInfo = firebase.database().ref('/users/' + user.uid);
        userInfo.once('value').then(function(snapshot) {
        
          let userDetails = snapshot.val();

          if(userDetails && userDetails.subscription) {
            user.subscription = userDetails.subscription;
          }

          user.subscriptionType = 'trial';
          if(userDetails && userDetails.subscription && userDetails.subscription.type) {
            user.subscriptionType = userDetails.subscription.type;
          }

          // USAGE DATA
          // Limit is a Bool / True if Limit is active on account (PAYG)
          if(userDetails && userDetails.usage && userDetails.usage.limit) {
            user.limit = userDetails.usage.limit;
          }

          // Number of Credits
          if(userDetails && userDetails.usage && userDetails.usage.credits) {
            user.credits = userDetails.usage.credits;
          }

          // Reset date when credits return to default
          if(userDetails && userDetails.usage && userDetails.usage.resetDate) {
            user.resetDate = userDetails.resetDate;
          }
          // END USAGE DATA


          if(userDetails && userDetails.onboarded) {
            user.onboarded = userDetails.onboarded;
          }

          if(userDetails && userDetails.upgradePrompt) {
            user.upgradePrompt = userDetails.upgradePrompt;
          }

          if(userDetails && userDetails.appraisalCount) {
            user.appraisalCount = userDetails.appraisalCount;
          }

          if(userDetails && userDetails.userPrefs) {
            user.userPrefs = userDetails.userPrefs;
          }

          user.setSubscription = function(subscriptionId, type) {
            user.subscription = {
              customerId: subscriptionId,
              type: type
            }
          };

          user.auth = true;
          user.setUserType = setUserType;
          user.setUserCreditLimit = setUserCreditLimit;
          user.useCredit = useCredit;
          user.setCredits = setCredits;
          user.renewCredits = renewCredits;
          user.incrementAppraisalCount = incrementAppraisalCount;

          that.setState({ user });

          userInfo.off('value');
        });

      } else {
        let user = {auth:false};
        this.setState({ user });
      }
    });
  };

  

  render() {

    let { user } = this.state;

    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;
