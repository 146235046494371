/* 
FSC FOR modulePostcodePriceLookup
Method used to perform a lookup on the given postcode to return an price per sqft value
*/

import lookups from '../../src/lookup-data.js';

const modulePostcodePriceLookup = (appraisalData, setAppraisalData) => {

    const postcodeLookup = lookups[0];

    appraisalData.postcode = appraisalData.postcode.replace(' ','').toUpperCase();

    let outcodeTests = [appraisalData.postcode.substring(0,2), appraisalData.postcode.substring(0,3), appraisalData.postcode.substring(0,4)];

    outcodeTests.forEach(function(element) {
      if(postcodeLookup[element]) {
        const updates = {};
        appraisalData.avgPricePerSqft = '£' + postcodeLookup[element];
        updates['avgPricePerSqft'] = '£' + postcodeLookup[element];
        setAppraisalData({...appraisalData, ...updates});
      }
    });

 };

 export default modulePostcodePriceLookup;