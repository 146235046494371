import React from "react";
import { HashRouter } from 'react-router-dom';
import Application from "./Components/Application";
import UserProvider from "./providers/UserProvider";
import { ToastProvider } from 'react-toast-notifications';

const App = props => {
  return (
  	<HashRouter>
	    <UserProvider>
	    	<ToastProvider
			    autoDismiss
			    autoDismissTimeout={1000}
			    placement="bottom-right"
			 >
		    	<Application envMode={process.env.REACT_APP_ENV_MODE} />
		    </ToastProvider>
		</UserProvider>
	</HashRouter>
  );
}

export default App;
