/* 
FSC FOR ModuleShare
Method used to clone appraisal data into public table for sharing
Appraisal Data
*/
import firebase from "firebase";

const ModuleShare = (type, appraisalData, setShareLink) => {

	// get existing user usage
	let shareTable = firebase.database().ref('/public/' + type);
	let instance = appraisalData;

	for(var key in instance){
        if(instance.hasOwnProperty(key) && instance[key] == Number.POSITIVE_INFINITY) instance[key] = '';
        if(instance.hasOwnProperty(key) && instance[key] == Number.NEGATIVE_INFINITY) instance[key] = '';
    }

    shareTable.push(instance).then((snapshot) => {
        const key = snapshot.key;
        setShareLink('/deals/' + type + '/' + key);
    })
    shareTable.off();


 };

export default ModuleShare;
