/* 
Partial for Appraisal KPI Block
Used to Generate the KPI's on the Appraisal View
*/

import React from "react";
import moduleFormatCurrency from "../modules/module-format-currency";
import moduleFormatPercentage from "../modules/module-format-percentage";

const TemplateAppraisalKPIBlock = (child, item, blank, userPrefs) => {

  let value = '---';
  if(!blank) {
    // if this field is one the user has selected as a KPI
    if(userPrefs[item.key]) {
      value = child[item.key]; // need to determine format e.g. moduleFormatCurrency()
      if(item.format === 'currency') {
        value = moduleFormatCurrency(value);
      }
      if(item.format === 'percentage') {
        value = moduleFormatPercentage(value);
      }
    } else {
      return ('');
    }
  }


  if(userPrefs[item.key]) {
    return (
      <div key={`p_${item.key}${child.key}}`} className="w-1/2 md:w-auto pl-2 pb-3 md:pb-0 md:pl-0">
          <span className="text-center text-xs md:text-sm font-bold text-pgNavy-500 block">{item.label}:</span><span className="text-center text-pgNavy-500 block text-xs md:text-sm">{value}</span>
      </div>
    )
  } else {
    return ('');
  }

}

export default TemplateAppraisalKPIBlock;
