/* 
FSC FOR moduleFormatFriendlyData
Method used to perform a lookup on the given postcode to return an price per sqft value
*/
import moduleFormatCurrency from "./module-format-currency";
import moduleFormatPercentage from "./module-format-percentage";

import settingsCurrencyFields from "../settings-currency-fields";
import settingsPercentageFields from "../settings-percentage-fields";

const moduleFormatFriendlyData = (snapshot) => {

    let processedSnapshot = {};
    for (const key in snapshot) {
      if (snapshot.hasOwnProperty(key)) {
        processedSnapshot[key] = snapshot[key];
        if(settingsCurrencyFields.includes(key)) {
          processedSnapshot[key] = moduleFormatCurrency(snapshot[key]);
        }
        if(settingsPercentageFields.includes(key)) {
          processedSnapshot[key] = moduleFormatPercentage(snapshot[key]);
        }
      }
    }
    return processedSnapshot;

 };

 export default moduleFormatFriendlyData;