/* 
FSC FOR moduleClenseData
Method used to clense all invalid characters out of a data set
*/

const moduleClenseData = (input) => {

    if(input) {
      const cleanInput = input.toString().replace('%','').replace('£','').replace(',','').replace(',','').replace(',','');
      return Number(cleanInput);
    }
    return 0;

 };

 export default moduleClenseData;