/* 
FSC FOR Key Press Detection - 13 = Next Field
*/

const moduleKeyPress = (event) => {

    if (event.which === 13) {

      //Isolate the node that we're after
      const currentNode = event.target;

      //console.log(currentNode.type);

      if (currentNode.type !== 'textarea') {
        event.preventDefault();
      }
      
      //find all tab-able elements
      const allElements = document.querySelectorAll('input, select, [contenteditable]');

      //Find the current tab index.
      const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el))

      //focus the following element
      const targetIndex = (currentIndex + 1) % allElements.length;
      allElements[targetIndex].focus();
    }

 };

 export default moduleKeyPress;