import React, { useRef, useContext } from "react";
import useGlobal from "../../store";
import { UserContext } from "../../providers/UserProvider";
import S3 from "react-aws-s3";

function TemplateUpload() {

  const user = useContext(UserContext);
  const [uploads, setUploads] = useGlobal(
    state => state.uploads,
    actions => actions.addToUploads
  );


  const fileInput = useRef();
  const uploader = (event, uploads) => {
    setUploads({loading: true});
    event.preventDefault();
    let file = fileInput.current.files[0];
    //console.log(file);
    let newFileName = Date.now();

    const re = /(?:\.([^.]+))?$/;
    let extention = re.exec(fileInput.current.files[0].name.toLowerCase())[1];

    if(extention !== 'jpeg' && extention !== 'jpg' && extention !== 'png' && extention !== 'gif') {
      alert('Your upload has failed bacause you have not uploaded an image!');
      setUploads({loading: false});
      return;
    }

    let FileSize = fileInput.current.files[0].size / 1024 / 1024; // in MiB
    if (FileSize > 4) {
      alert('Your upload is too large, please upload a smaller version of this image! Our max filesize is 4MB');
      setUploads({loading: false});
      return;
    }

    const config = {
	  bucketName: 'property-gambit-uploads',
	  dirName: '/photos/' + user.uid, /* optional */
	  region: 'eu-west-2',
	  accessKeyId: 'AKIAJBPLDSXKCSN3AV5Q',
	  secretAccessKey: '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'
    };
    const ReactS3Client = new S3(config);
    ReactS3Client.uploadFile(file, newFileName + '.' + extention).then((data) => {
      if (data.status === 204) {
       data.loading = false;
    	 setUploads(data);
      } else {
        //console.log("failed");
      }
    });
  };
  return (
    <>
    	<input type='file' accept="image/*" ref={fileInput} onChange = {(event) => uploader(event, uploads)} />
    </>
  );
}

export default TemplateUpload;