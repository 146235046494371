/* 
Partial for Appraisal Thumbnail Blocl
Used to Generate the KPI's on the Appraisal View
*/

import React from "react";
import { Link } from 'react-router-dom';

import TemplateAppraisalMiniScoreCard from "./template-appraisal-mini-scorecard";
import TemplateAppraisalsImageBlock from "./template-appraisals-image-block";
import TemplateAppraisalsKPIBlock from "./template-appraisals-kpi-block";

const TemplateAppraisalBlock = (child, archive, userPrefs, preferenceMapping, type) => {

  if(!archive && child.val().archive === '1') {
    return;
  }

  else if (archive && child.val().archive !== '1') {
    return;
  }

  if(((!child.val().price || !child.val().grossRent) && type !== 'flips') || (type !== 'flips' && !child.val().price)) {
    return;
  }

  let url = `/` + type + `/appraisal/` + child.key;
  let bmv = (child.val().flagBMV) ? 'bmv' : '';
  let noChain = (child.val().flagNoChain) ? 'noChain' : '';
  let hot = (child.val().flagHot) ? 'hot' : '';
  let offered = (child.val().offerStatus === 'offered' || child.val().offerStatus === 'accepted') ? 'offered' : '';

  return (

  <Link key={child.key} className={`appraisal-item ${offered} ${hot} ${noChain} ${bmv} border  hover:border-gray-400`} to={url}>
      {TemplateAppraisalsImageBlock(child)}
      <div className="p-4">
        <p className="block flex h-16 text-pgNavy-500 items-top">
          <b className="w-4/6 text-sm">{child.val().name}</b>
          {child.val().overallScore && 
          <TemplateAppraisalMiniScoreCard overallScore={child.val().overallScore} />
          }
        </p>
      </div>
      <>
        {preferenceMapping.map((item, index) => (
             TemplateAppraisalsKPIBlock(child, item, false, userPrefs)
        ))}
      </>
  </Link>);

}

export default TemplateAppraisalBlock;
