import React, { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { NavLink } from 'react-router-dom';
import apiGlobal from "../store/api";
import SvgHand from '../src/hand.svg';

import TemplateBuyCreditsButton from "./templates/template-buy-credits-button";

const SripeCredits = ({type, envMode}) => {

const user = useContext(UserContext);
const [apiSettings] = apiGlobal(state => state.apiSettings);

  return (

  <div className="w-full p-10 lg:p-20 bg-pgBlue-500 min-h-full">

    <h1 className="text-4xl font-bold mb-2 text-white">Buy More Credits</h1>

    <p className="text-md mb-5 text-white">We like to keep things simple... 1 Credit = 1 Appraisal</p>

    <div className="block bg-white shadow-md xl:flex items-center mb-1 mt-10">
        
      <span className="block text-center bg-pgGreen-500 text-pgBlue-500 p-3 font-bold xl:w-3/12">Starter Bundle</span>
      <span className="block text-center p-2 xl:w-2/12">10 Credits</span>
      <span className="block text-center p-2 xl:w-2/12">£10</span>
      <span className="hidden xl:block p-2 w-2/12">£1 per Appraisal</span>
      <span className="block text-center p-2 w-8/12 md:w-5/12 m-auto xl:w-3/12">
      <TemplateBuyCreditsButton
        stripePublicKey={apiSettings[envMode].stripePublicKey}
        apiName={apiSettings[envMode].apiName}
        apiEndpoint="/buy-credits"
        buttonText="Buy Starter Bundle"
        price={(envMode === 'dev')?'price_1J0mMwE87ZV7kg7y6sih0MG6':'price_1JhES8E87ZV7kg7yhwKcNfct'}
        className="ml-0 whitespace-nowrap"
        successUrl={`${apiSettings[envMode].redirect}/#/?credit_session_id={CHECKOUT_SESSION_ID}`} // Where to redirect if success
        cancelUrl={`${apiSettings[envMode].redirect}/#/upgrade`} // Where to go if payment canceled
        customerId={user.subscription.customerId}
      /></span>

    </div>

    <div className="block bg-white shadow-md xl:flex items-center mb-1">
        
      <span className="block text-center bg-pgGreen-500 text-pgBlue-500 p-3 font-bold xl:w-3/12">Standard Bundle</span>
      <span className="block text-center p-2 xl:w-2/12">20 Credits</span>
      <span className="block text-center p-2 xl:w-2/12">£15</span>
      <span className="hidden xl:block p-2 xl:w-2/12">75p per Appraisal</span>
      <span className="block text-center p-2 w-8/12 md:w-5/12 m-auto xl:w-3/12 items-center">
      <TemplateBuyCreditsButton
        stripePublicKey={apiSettings[envMode].stripePublicKey}
        apiName={apiSettings[envMode].apiName}
        apiEndpoint="/buy-credits"
        buttonText="Buy Standard Bundle"
        price={(envMode === 'dev')?'price_1J0mQGE87ZV7kg7yN7tBrY2o':'price_1JhESQE87ZV7kg7yXs69yDuG'}
        className="ml-0 whitespace-nowrap"
        successUrl={`${apiSettings[envMode].redirect}/#/?credit_session_id={CHECKOUT_SESSION_ID}`} // Where to redirect if success
        cancelUrl={`${apiSettings[envMode].redirect}/#/upgrade`} // Where to go if payment canceled
        customerId={user.subscription.customerId}
      />
      </span>

    </div>

    <div className="block bg-white shadow-md xl:flex items-center mb-20">
        
      <span className="block text-center bg-pgGreen-500 text-pgBlue-500 p-3 font-bold xl:w-3/12">Advanced Bundle</span>
      <span className="block text-center p-2 xl:w-2/12">40 Credits</span>
      <span className="block text-center p-2 xl:w-2/12">£20</span>
      <span className="hidden xl:block p-2 w-2/12">50p per Appraisal</span>
      <span className="block text-center p-2 w-8/12 md:w-5/12 m-auto xl:w-3/12">
      <TemplateBuyCreditsButton
        stripePublicKey={apiSettings[envMode].stripePublicKey}
        apiName={apiSettings[envMode].apiName}
        apiEndpoint="/buy-credits"
        buttonText="Buy Advanced Bundle"
        price={(envMode === 'dev')?'price_1J0mquE87ZV7kg7y9bzU0Nut':'price_1JhESZE87ZV7kg7y81ChgPHg'}
        className="ml-0 whitespace-nowrap"
        successUrl={`${apiSettings[envMode].redirect}/#/?credit_session_id={CHECKOUT_SESSION_ID}`} // Where to redirect if success
        cancelUrl={`${apiSettings[envMode].redirect}/#/upgrade`} // Where to go if payment canceled
        customerId={user.subscription.customerId}
      />
      </span>

    </div>

    <h1 className="text-4xl font-bold mb-2 text-white">Come here often?</h1>

    <p className="text-md mb-5 text-white">If so, you may find it cheaper to sign up to one of our monthly plans...</p>

    <NavLink to="/upgrade" className="block cursor-pointer bg-gradient-to-r sm:w-1/6 mb-5 text-center from-pgAqua-500 to-pgGreen-500 rounded rounded-full p-2 text-pgBlue-500"><span>View Pricing</span></NavLink>

    <img src={SvgHand} className="hidden lg:block fixed bottom-0 right-0 max-w-screen-lg w-1/3" alt="Keys" />
  </div>
  ) 
};

export default SripeCredits;

