export const addToPreferences = (store, value) => {
  const preferences = value;
  store.setState({ preferences });
};

export const addToUploads = (store, value) => {
  const uploads = value;
  store.setState({ uploads });
};

