import React from "react";
import SvgReactLogo from '../src/logo.svg';

const LayoutSecondary = ({ children }) => {

  return (
    <div className="h-full min-h-screen w-screen items-stretch grad">

      <link rel="preconnect" href="https://fonts.gstatic.com" /><link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap" rel="stylesheet" />

      <a className="block text-white text-3xl p-10" href="https://www.propertygambit.co.uk/"><img src={SvgReactLogo} className="w-2/6 sm:w-3/12 md:w-2/12 xl:w-1/12" alt="Property Gambit" /></a>

      {children}
    </div>
  ) 
};

export default LayoutSecondary;

